import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {DRUG_BATCH_LIST_STATS_STORE} from "../actions/DrugBatchListStatsActionTypes";
import {StatisticsItem} from "../../../helpers/statisticsHelpers";
import {showErrorToast} from "../../../../../utils/toastUtils";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";

const defaultState = createDefaultStoreState<StatisticsItem>({
    needleAngle: 0,
    compliancePercentage: 0,
    totalValue: 0,
    outerLabel: "Medicine Management",
    tooltipText: "Number of drugs expiring in the next 30 days (0/0)"
});

const drugBatchListStatsReducer = (
    state: StoreServiceData<StatisticsItem> = defaultState,
    action: ServiceActionTypes<StatisticsItem>
): StoreServiceData<StatisticsItem> =>
    createReducer(state, action, DRUG_BATCH_LIST_STATS_STORE, () => showErrorToast(action.error));

export default drugBatchListStatsReducer;
