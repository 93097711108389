import React from "react";
import Card from "../../Cards/Card";

function QrCodeCard({base64QrCode}: Props) {
    return (
        <div className="totp-qr-code-card-container">
            <Card className="totp-qr-code-card" wrapperClassName="w-100">
                <div className="totp-image-container">
                    <div className="dot bottom-left" />
                    <div className="dot top-right" />
                    <div className="qr-code-image-wrapper">
                        <img alt="totp-qr-code" src={base64QrCode} />
                    </div>
                </div>

                <p className="totp-qr-code-cta-text">Scan me!</p>
            </Card>
        </div>
    );
}

export default QrCodeCard;

interface Props {
    base64QrCode: string;
}
