/* tslint:disable */
/* eslint-disable */
/**
 * Medicare PRF
 * API for Medicare Patient Report Form Audits
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Answer that can be given to a question
 * @export
 * @enum {string}
 */

export enum Answer {
    Yes = 'yes',
    No = 'no',
    NotApplicable = 'notApplicable'
}

/**
 * Feedback for Audit
 * @export
 * @interface AuditFeedback
 */
export interface AuditFeedback {
    /**
     * comments made for feedback
     * @type {string}
     * @memberof AuditFeedback
     */
    'comments': string;
    /**
     * ID of the audit
     * @type {number}
     * @memberof AuditFeedback
     */
    'auditId': number;
}
/**
 * The Container with all the information for the audits
 * @export
 * @interface AuditForm
 */
export interface AuditForm {
    /**
     * 
     * @type {BaseAudit}
     * @memberof AuditForm
     */
    'baseQuestions': BaseAudit;
    /**
     * 
     * @type {GeneralAudit}
     * @memberof AuditForm
     */
    'generalAudit'?: GeneralAudit;
    /**
     * 
     * @type {NotConveyedAudit}
     * @memberof AuditForm
     */
    'notConveyedAudit'?: NotConveyedAudit;
    /**
     * 
     * @type {CardiacChestPainAudit}
     * @memberof AuditForm
     */
    'cardiacChestPainAudit'?: CardiacChestPainAudit;
    /**
     * 
     * @type {SepsisAudit}
     * @memberof AuditForm
     */
    'sepsisAudit'?: SepsisAudit;
    /**
     * 
     * @type {StrokeAudit}
     * @memberof AuditForm
     */
    'strokeAudit'?: StrokeAudit;
    /**
     * 
     * @type {CardiacArrestAndROSCAudit}
     * @memberof AuditForm
     */
    'cardiacArrestAndROSCAudit'?: CardiacArrestAndROSCAudit;
    /**
     * 
     * @type {RecognitionOfLifeExtinctAudit}
     * @memberof AuditForm
     */
    'recognitionOfLifeExtinctAudit'?: RecognitionOfLifeExtinctAudit;
    /**
     * 
     * @type {MentalHealthAndRestraintAudit}
     * @memberof AuditForm
     */
    'mentalHealthAndRestraintAudit'?: MentalHealthAndRestraintAudit;
    /**
     * 
     * @type {OutcomeType}
     * @memberof AuditForm
     */
    'outcome': OutcomeType;
    /**
     * 
     * @type {OutcomeQuestions}
     * @memberof AuditForm
     */
    'outcomeData': OutcomeQuestions;
    /**
     * General comments made for this audit
     * @type {string}
     * @memberof AuditForm
     */
    'comments'?: string;
    /**
     * Sends feedback to clinician if this option is selected
     * @type {boolean}
     * @memberof AuditForm
     */
    'sendFeedback': boolean;
}
/**
 * Summary info for a historic audit record, old entries that were saved / modified in the past
 * @export
 * @interface AuditHistoryInfo
 */
export interface AuditHistoryInfo {
    /**
     * ID of the audit
     * @type {number}
     * @memberof AuditHistoryInfo
     */
    'id': number;
    /**
     * Unique ID of this specific historic version of the audit
     * @type {number}
     * @memberof AuditHistoryInfo
     */
    'hid': number;
    /**
     * 
     * @type {StaffLink}
     * @memberof AuditHistoryInfo
     */
    'modifiedAuthor': StaffLink;
    /**
     * Datestamp for when this audit was saved (aka modified date)
     * @type {number}
     * @memberof AuditHistoryInfo
     */
    'date': number;
    /**
     * Score of the audit at this time
     * @type {number}
     * @memberof AuditHistoryInfo
     */
    'score': number;
}
/**
 * History of the audit
 * @export
 * @interface AuditHistoryOverview
 */
export interface AuditHistoryOverview {
    /**
     * 
     * @type {Array<AuditHistoryInfo>}
     * @memberof AuditHistoryOverview
     */
    'historicEntries': Array<AuditHistoryInfo>;
}
/**
 * Get audit trail logs that meet the requirements of this object.
 * @export
 * @interface AuditListRequest
 */
export interface AuditListRequest {
    /**
     * Page number to fetch, starting from 0
     * @type {number}
     * @memberof AuditListRequest
     */
    'pageNum'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditListRequest
     */
    'numPerPage'?: number;
    /**
     * ID of staff member, used to filter audits made for that staff member.
     * @type {string}
     * @memberof AuditListRequest
     */
    'clinicianId'?: string;
    /**
     * ID of calendar, used to filter audits made for that calendar
     * @type {number}
     * @memberof AuditListRequest
     */
    'calendarId'?: number;
    /**
     * Types of audit to restrict the response to. Empty array for any audit.
     * @type {Array<AuditType>}
     * @memberof AuditListRequest
     */
    'auditTypes': Array<AuditType>;
    /**
     * 
     * @type {DateRange}
     * @memberof AuditListRequest
     */
    'creationDate': DateRange;
}
/**
 * Results of the Audit list request
 * @export
 * @interface AuditListResponse
 */
export interface AuditListResponse {
    /**
     * Page number to fetch, starting from 0
     * @type {number}
     * @memberof AuditListResponse
     */
    'pageNum': number;
    /**
     * 
     * @type {number}
     * @memberof AuditListResponse
     */
    'numPerPage': number;
    /**
     * Total number of results across all pages
     * @type {number}
     * @memberof AuditListResponse
     */
    'totalResults': number;
    /**
     * ID of staff member, used to filter audits made for that staff member.
     * @type {string}
     * @memberof AuditListResponse
     */
    'clinicianId'?: string;
    /**
     * ID of calendar, used to filter audits made for that calendar
     * @type {number}
     * @memberof AuditListResponse
     */
    'calendarId'?: number;
    /**
     * Types of audit to restrict the response to. Empty array for any audit.
     * @type {Array<AuditType>}
     * @memberof AuditListResponse
     */
    'auditTypes': Array<AuditType>;
    /**
     * 
     * @type {DateRange}
     * @memberof AuditListResponse
     */
    'creationDate': DateRange;
    /**
     * 
     * @type {Array<AuditListResult>}
     * @memberof AuditListResponse
     */
    'results': Array<AuditListResult>;
}
/**
 * Summarised data for an audit
 * @export
 * @interface AuditListResult
 */
export interface AuditListResult {
    /**
     * 
     * @type {StaffLink}
     * @memberof AuditListResult
     */
    'clinician': StaffLink;
    /**
     * Name of the venue the audit is attached to
     * @type {string}
     * @memberof AuditListResult
     */
    'eventName'?: string;
    /**
     * 
     * @type {GrsLocation}
     * @memberof AuditListResult
     */
    'grsLocation'?: GrsLocation;
    /**
     * Date of when the audit was created
     * @type {number}
     * @memberof AuditListResult
     */
    'creationDate': number;
    /**
     * 
     * @type {AuditType}
     * @memberof AuditListResult
     */
    'auditType': AuditType;
    /**
     * ID of the audit
     * @type {number}
     * @memberof AuditListResult
     */
    'auditId': number;
    /**
     * Score for the audit
     * @type {number}
     * @memberof AuditListResult
     */
    'score': number;
}
/**
 * Types of PRF audit reports
 * @export
 * @enum {string}
 */

export enum AuditType {
    All = 'all',
    General = 'general',
    NotConveyed = 'notConveyed',
    CardiacChestPain = 'cardiacChestPain',
    Sepsis = 'sepsis',
    Stroke = 'stroke',
    CardiacArrestAndRosc = 'cardiacArrestAndRosc',
    RecognitionOfLifeExtinct = 'recognitionOfLifeExtinct',
    MentalHealthAndOrRestraint = 'mentalHealthAndOrRestraint',
    None = 'none'
}

/**
 * Object containing stats for audits. Contains the total number of audits as well as the number of audits which are 100%, 90%-99% and <90% compliant. Contains the name of the audit the stats are attached to
 * @export
 * @interface AuditTypeStats
 */
export interface AuditTypeStats {
    /**
     * 
     * @type {AuditType}
     * @memberof AuditTypeStats
     */
    'auditType': AuditType;
    /**
     * Total number of audits created for this stat
     * @type {number}
     * @memberof AuditTypeStats
     */
    'totalNumberOfAudits': number;
    /**
     * Number of audits for this stat that were 100% compliant
     * @type {number}
     * @memberof AuditTypeStats
     */
    'numberOfAudits100PercentCompliant': number;
    /**
     * Number of audits for this stat that were 90% - 99% compliant
     * @type {number}
     * @memberof AuditTypeStats
     */
    'numberOfAudits90to99PercentCompliant': number;
    /**
     * Number of audits for this stat that were less than 90% compliant
     * @type {number}
     * @memberof AuditTypeStats
     */
    'numberOfAuditsBelow90PercentCompliant': number;
}
/**
 * Fields for an audit type stats request
 * @export
 * @interface AuditTypeStatsRequest
 */
export interface AuditTypeStatsRequest {
    /**
     * id of the calendar of the request
     * @type {number}
     * @memberof AuditTypeStatsRequest
     */
    'calendarId'?: number;
    /**
     * 
     * @type {DateRange}
     * @memberof AuditTypeStatsRequest
     */
    'creationDate': DateRange;
}
/**
 * Base Question asked at the start of each audit
 * @export
 * @interface BaseAudit
 */
export interface BaseAudit {
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'locationOfCall': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'activationAndResponseTimes': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'crewDetails': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'patientsName': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'dob': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'patientsHomeAddress': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'gp': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'nextOfKin': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'ethnicity': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'nhsNumber': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'paediatricPatient': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'nameOfSchool'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'accompanyingAdult'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'medicalHistory': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'drugHistory': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof BaseAudit
     */
    'allergies': QuestionWithAnswer;
}
/**
 * Core required fields for a stats request
 * @export
 * @interface BasicStatsRequest
 */
export interface BasicStatsRequest {
    /**
     * 
     * @type {DateRange}
     * @memberof BasicStatsRequest
     */
    'creationDate': DateRange;
}
/**
 * Object containing stats for audits. Contains the total number of audits as well as the number of audits which are 100%, 90%-99% and <90% compliant. Contains the calendar information that the stat is attached to
 * @export
 * @interface CalendarAuditStats
 */
export interface CalendarAuditStats {
    /**
     * The unique ID of the calendar
     * @type {number}
     * @memberof CalendarAuditStats
     */
    'calendarId': number;
    /**
     * Name of the calendar, available if the calendar is not fetchable by the id.
     * @type {string}
     * @memberof CalendarAuditStats
     */
    'calendarName': string;
    /**
     * Total number of audits created for this stat
     * @type {number}
     * @memberof CalendarAuditStats
     */
    'totalNumberOfAudits': number;
    /**
     * Number of audits for this stat that were 100% compliant
     * @type {number}
     * @memberof CalendarAuditStats
     */
    'numberOfAudits100PercentCompliant': number;
    /**
     * Number of audits for this stat that were 90% - 99% compliant
     * @type {number}
     * @memberof CalendarAuditStats
     */
    'numberOfAudits90to99PercentCompliant': number;
    /**
     * Number of audits for this stat that were less than 90% compliant
     * @type {number}
     * @memberof CalendarAuditStats
     */
    'numberOfAuditsBelow90PercentCompliant': number;
}
/**
 * Questions Asked for the Cardiac Arrest And ROSC Audit
 * @export
 * @interface CardiacArrestAndROSCAudit
 */
export interface CardiacArrestAndROSCAudit {
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'historyOfPresentingComplaintOrCollapse': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'primarySurvey': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'documentIfWitnessed': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'timeOfBystanderCpr': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'bystanderDefibUsed': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'timeOfMedicareCrewCpr': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'medicareDefibUsed': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'initialRhythmRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'numberOfShockDelivered': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'airwayCorrectlyManaged': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'advancedAirwayPlaced': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'endTidalCo2Recorded'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'endTidalCo2Printed'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'vascularAccessGained': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'oxygenGivenAppropriately': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'medicationGiven': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'adrenalineGivenAppropriately'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'amidaroneGivenAppropriately'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'sodiumChlorideGivenAppropriately'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'otherMedicinesGivenAppropriately'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'roscAchieved': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'leadEcgAchieved'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'bmRecorded'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'endTidalCO2Recorded'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'oxygenDeliveredAndTirated'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'systolicBpRecorded'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'bolusScpGiven'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithConveyance}
     * @memberof CardiacArrestAndROSCAudit
     */
    'destination': QuestionWithConveyance;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'appropriateDestination'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'handoverTimes'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'timeVerified'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'guidelinesFollowed'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacArrestAndROSCAudit
     */
    'callClosedCorrectly': QuestionWithAnswer;
}
/**
 * Questions Asked for the Cardiac Chest Pain Audit
 * @export
 * @interface CardiacChestPainAudit
 */
export interface CardiacChestPainAudit {
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'historyOfPresentingComplaint': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'primarySurvey': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'consentObtained': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'capacityAssessed': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'assessmentDocumented'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'observationRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'twoPainScoreDocumented': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'leadEcg': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'interpretationCorrect'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'managementCorrect'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'additionalChecksRequired': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'v4rRecorded'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'posteriorCheckRecorded'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'gtnAdministeredCorrectly': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'aspirinAdministeredCorrectly': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'appropriatePainReliefGivenCorrectly': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'additionalMedicationGiven': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'appropriateMedicationAdministered'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'correctRouteOfAdministration'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'correctDosage'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'documentedCorrectly'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'correctClinicianAdministering'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'clinicAssessmentAppropriate': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'differentialDiagnosticReasonable': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithConveyance}
     * @memberof CardiacChestPainAudit
     */
    'destination': QuestionWithConveyance;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'appropriateDestination'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'handoverTimes'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'patientOutcomeReasonable'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'conveyanceRefused'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'pathfinderOutcome'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'calContacted'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'news2ScoreRecorded'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'worseningCareAdviceGiven'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof CardiacChestPainAudit
     */
    'callClosedCorrectly': QuestionWithAnswer;
}
/**
 * Object containing stats for staff audits. Contains the total number of audits as well as the number of audits which are 100%, 90%-99% and <90% compliant. Contains the name of the audit the stats are attached to
 * @export
 * @interface ClinicianAuditStats
 */
export interface ClinicianAuditStats {
    /**
     * 
     * @type {StaffLink}
     * @memberof ClinicianAuditStats
     */
    'clinician': StaffLink;
    /**
     * Total number of audits created for this stat
     * @type {number}
     * @memberof ClinicianAuditStats
     */
    'totalNumberOfAudits': number;
    /**
     * Number of audits for this stat that were 100% compliant
     * @type {number}
     * @memberof ClinicianAuditStats
     */
    'numberOfAudits100PercentCompliant': number;
    /**
     * Number of audits for this stat that were 90% - 99% compliant
     * @type {number}
     * @memberof ClinicianAuditStats
     */
    'numberOfAudits90to99PercentCompliant': number;
    /**
     * Number of audits for this stat that were less than 90% compliant
     * @type {number}
     * @memberof ClinicianAuditStats
     */
    'numberOfAuditsBelow90PercentCompliant': number;
}
/**
 * Object containing the number of audits completed
 * @export
 * @interface CompletedAuditStat
 */
export interface CompletedAuditStat {
    /**
     * number of audits completed
     * @type {number}
     * @memberof CompletedAuditStat
     */
    'count': number;
}
/**
 * Type of Conveyance
 * @export
 * @enum {string}
 */

export enum Conveyance {
    Conveyed = 'conveyed',
    NotConveyed = 'notConveyed',
    RecognitionOfLifeExtinct = 'recognitionOfLifeExtinct',
    Refused = 'refused',
    Referred = 'referred',
    NotApplicable = 'notApplicable'
}

/**
 * Basic range of Linux epoch dates
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * Start date, inclusive
     * @type {number}
     * @memberof DateRange
     */
    'startDate': number;
    /**
     * End date, exclusive
     * @type {number}
     * @memberof DateRange
     */
    'endDate': number;
}
/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
}
/**
 * Questions Asked for the General Audit
 * @export
 * @interface GeneralAudit
 */
export interface GeneralAudit {
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'historyOfPresentingComplaint': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'primarySurvey': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'consentObtained': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'capacityAssessed': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'assessmentDocumented'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'observationRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'leadEcg': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'interpretationCorrect'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'managementCorrect'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'clinicAssessmentAppropriate': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'differentialDiagnosticReasonable': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'medicationGiven': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'appropriateMedicationAdministered'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'correctRouteOfAdministration'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'correctDosage'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'documentedCorrectly'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'correctClinicianAdministering'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'patientOutcomeReasonable': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'destination': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'nameOfHospital'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'handoverTimes'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof GeneralAudit
     */
    'callClosedCorrectly': QuestionWithAnswer;
}
/**
 * location of the report being audited
 * @export
 * @interface GrsLocation
 */
export interface GrsLocation {
    /**
     * Unique ID of the GRS event
     * @type {number}
     * @memberof GrsLocation
     */
    'entryId': number;
    /**
     * Name of the event for reference should the event be deleted and can\'t be fetched
     * @type {string}
     * @memberof GrsLocation
     */
    'entryName': string;
    /**
     * ID of the venue the report is being audited at
     * @type {number}
     * @memberof GrsLocation
     */
    'venueId': number;
    /**
     * Name of the venue, should it not be fetchable from the DB
     * @type {string}
     * @memberof GrsLocation
     */
    'venueName': string;
    /**
     * Type of calendar, useful reference
     * @type {string}
     * @memberof GrsLocation
     */
    'calendarType'?: string;
    /**
     * ID of the calendar the report is being audited at
     * @type {number}
     * @memberof GrsLocation
     */
    'calendarId': number;
    /**
     * Name of the calendar, should it not be fetchable from the DB
     * @type {string}
     * @memberof GrsLocation
     */
    'calendarName': string;
}
/**
 * Questions Asked for the Mental Health Audit
 * @export
 * @interface MentalHealthAndRestraintAudit
 */
export interface MentalHealthAndRestraintAudit {
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'primarySurvey': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'observationRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'bmRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'consentObtained': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'capacityAssessed': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'assessmentDocumented'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'currentMhCareFacilityRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'psychiatricHistoryRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'mhRiskAssessmentUndertaken': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'suicideRiskAssessmentUndertaken': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'patientsBehaviourDocumented': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'needForRestraint': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'restraintInProportionOrBestInterests'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'restraintNecessary'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithConveyance}
     * @memberof MentalHealthAndRestraintAudit
     */
    'destination': QuestionWithConveyance;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'appropriateDestination'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'handoverTimes'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'patientOutcomeReasonable'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'pathFinderOutcome'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'calContacted'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'news2ScoreRecorded'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'worseningCareAdviceDocumented'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof MentalHealthAndRestraintAudit
     */
    'callClosedCorrectly': QuestionWithAnswer;
}
/**
 * Questions Asked for the Not Conveyed Audit
 * @export
 * @interface NotConveyedAudit
 */
export interface NotConveyedAudit {
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'historyOfPresentingComplaint': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'primarySurvey': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'consentObtained': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'capacityAssessed': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'assessmentDocumented'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'atLeast2Observations': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'leadEcg': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'interpretationCorrect'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'managementCorrect'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'clinicAssessmentAppropriate': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'differentialDiagnosticReasonable': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'medicationGiven': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'appropriateMedicationAdministered'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'correctRouteOfAdministration'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'correctDosage'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'documentedCorrectly'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'correctClinicianAdministering'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'patientOutcomeReasonable': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'pathfinderOutcome': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'calContacted': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'news2ScoreRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'worseningCareAdviceGiven': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof NotConveyedAudit
     */
    'callClosedCorrectly': QuestionWithAnswer;
}
/**
 * Outcome questions based on results from Prf Audit
 * @export
 * @interface OutcomeQuestions
 */
export interface OutcomeQuestions {
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'generalDistrictHospital': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'preAlert': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'ppci': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'hac': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'arrhythmiaCentre': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'hasu': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'mtc': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'discharged': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'referredToGp': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'referredToEolc': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'referredToMh': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'referredToHcp': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'madeOwnWay': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'taxiConveyance': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'refused': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'role': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'noTrace': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof OutcomeQuestions
     */
    'conveyedByNhs': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswerAndComments}
     * @memberof OutcomeQuestions
     */
    'safeguardingReferral': QuestionWithAnswerAndComments;
    /**
     * 
     * @type {QuestionWithAnswerAndComments}
     * @memberof OutcomeQuestions
     */
    'fallsReferral': QuestionWithAnswerAndComments;
    /**
     * 
     * @type {QuestionWithAnswerAndComments}
     * @memberof OutcomeQuestions
     */
    'diabeticReferral': QuestionWithAnswerAndComments;
}
/**
 * Types of Outcomes Achievable in the PrfAudits
 * @export
 * @enum {string}
 */

export enum OutcomeType {
    Conveyed = 'conveyed',
    NotConveyed = 'notConveyed',
    PatientReferral = 'patientReferral',
    None = 'none'
}

/**
 * A very flexible container for a reported issue. Admins can create and update a report, staff members can see the outcome of the report. The payload is a raw JSON string that can contain any content relevant to the report.
 * @export
 * @interface PrfAudit
 */
export interface PrfAudit {
    /**
     * ID of the PRF audit.
     * @type {number}
     * @memberof PrfAudit
     */
    'id': number;
    /**
     * 
     * @type {StaffLink}
     * @memberof PrfAudit
     */
    'originalAuthor': StaffLink;
    /**
     * 
     * @type {StaffLink}
     * @memberof PrfAudit
     */
    'modifiedAuthor': StaffLink;
    /**
     * The date on the PRF form
     * @type {number}
     * @memberof PrfAudit
     */
    'auditDate': number;
    /**
     * The date the report was created
     * @type {number}
     * @memberof PrfAudit
     */
    'creationDate': number;
    /**
     * The date the report was modified
     * @type {number}
     * @memberof PrfAudit
     */
    'modifiedDate': number;
    /**
     * 
     * @type {StaffLink}
     * @memberof PrfAudit
     */
    'clinician': StaffLink;
    /**
     * 
     * @type {GrsLocation}
     * @memberof PrfAudit
     */
    'grsLocation'?: GrsLocation;
    /**
     * 
     * @type {AuditType}
     * @memberof PrfAudit
     */
    'auditType': AuditType;
    /**
     * Name of the event where the report was made
     * @type {string}
     * @memberof PrfAudit
     */
    'eventName'?: string;
    /**
     * Cad number of the ambulance
     * @type {string}
     * @memberof PrfAudit
     */
    'cadNumber'?: string;
    /**
     * Score of the audit. Max = 100 Min = 0
     * @type {number}
     * @memberof PrfAudit
     */
    'score': number;
    /**
     * Raw JSON content for the report, any valid JSON is allowed
     * @type {string}
     * @memberof PrfAudit
     */
    'payload': string;
}
/**
 * Object containing stats for audits. Contains the total number of audits as well as the number of audits which are 100%, 90%-99% and <90% compliant.
 * @export
 * @interface PrfStatsBase
 */
export interface PrfStatsBase {
    /**
     * Total number of audits created for this stat
     * @type {number}
     * @memberof PrfStatsBase
     */
    'totalNumberOfAudits': number;
    /**
     * Number of audits for this stat that were 100% compliant
     * @type {number}
     * @memberof PrfStatsBase
     */
    'numberOfAudits100PercentCompliant': number;
    /**
     * Number of audits for this stat that were 90% - 99% compliant
     * @type {number}
     * @memberof PrfStatsBase
     */
    'numberOfAudits90to99PercentCompliant': number;
    /**
     * Number of audits for this stat that were less than 90% compliant
     * @type {number}
     * @memberof PrfStatsBase
     */
    'numberOfAuditsBelow90PercentCompliant': number;
}
/**
 * Base Object for a Question. Can be extended based on type of question being asked
 * @export
 * @interface QuestionBase
 */
export interface QuestionBase {
    /**
     * The question being asked
     * @type {string}
     * @memberof QuestionBase
     */
    'question': string;
    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionBase
     */
    'questionType': QuestionType;
    /**
     * The number of points the question increments by on a correct answer
     * @type {number}
     * @memberof QuestionBase
     */
    'scoreChange': number;
}
/**
 * Types of Questions being asked. This can be used to track question types for stats
 * @export
 * @enum {string}
 */

export enum QuestionType {
    Normal = 'normal',
    Outcome = 'outcome',
    Quality = 'quality'
}

/**
 * Given Answer based on the question
 * @export
 * @interface QuestionWithAnswer
 */
export interface QuestionWithAnswer {
    /**
     * 
     * @type {Answer}
     * @memberof QuestionWithAnswer
     */
    'answer': Answer;
    /**
     * The question being asked
     * @type {string}
     * @memberof QuestionWithAnswer
     */
    'question': string;
    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionWithAnswer
     */
    'questionType': QuestionType;
    /**
     * The number of points the question increments by on a correct answer
     * @type {number}
     * @memberof QuestionWithAnswer
     */
    'scoreChange': number;
}
/**
 * Adds comments field to Question for feedback
 * @export
 * @interface QuestionWithAnswerAndComments
 */
export interface QuestionWithAnswerAndComments {
    /**
     * 
     * @type {Answer}
     * @memberof QuestionWithAnswerAndComments
     */
    'answer': Answer;
    /**
     * Comments made
     * @type {string}
     * @memberof QuestionWithAnswerAndComments
     */
    'comments'?: string;
    /**
     * The question being asked
     * @type {string}
     * @memberof QuestionWithAnswerAndComments
     */
    'question': string;
    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionWithAnswerAndComments
     */
    'questionType': QuestionType;
    /**
     * The number of points the question increments by on a correct answer
     * @type {number}
     * @memberof QuestionWithAnswerAndComments
     */
    'scoreChange': number;
}
/**
 * Given Conveyance based on the question
 * @export
 * @interface QuestionWithConveyance
 */
export interface QuestionWithConveyance {
    /**
     * 
     * @type {Conveyance}
     * @memberof QuestionWithConveyance
     */
    'conveyance': Conveyance;
    /**
     * The question being asked
     * @type {string}
     * @memberof QuestionWithConveyance
     */
    'question': string;
    /**
     * 
     * @type {QuestionType}
     * @memberof QuestionWithConveyance
     */
    'questionType': QuestionType;
    /**
     * The number of points the question increments by on a correct answer
     * @type {number}
     * @memberof QuestionWithConveyance
     */
    'scoreChange': number;
}
/**
 * Questions Asked in Recognition Of Life Extinct Audit
 * @export
 * @interface RecognitionOfLifeExtinctAudit
 */
export interface RecognitionOfLifeExtinctAudit {
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'historyOfPresentingComplaintOrCollapse': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'primarySurvey': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'cprNotPerformedInLast15Minutes': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'asystoleRhythmStrip': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'rigorMortisPresent': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'hypostasisPresent': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'factorsIncompatibleWithLifePresent': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'timeOfVerificationRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'guidelinesFollowed': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'policeRequested': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof RecognitionOfLifeExtinctAudit
     */
    'callClosedCorrectly': QuestionWithAnswer;
}
/**
 * Base request used for all reports
 * @export
 * @interface ReportRequest
 */
export interface ReportRequest {
    /**
     * Filter to audits of any of these types. If empty, any audit will match.
     * @type {Array<AuditType>}
     * @memberof ReportRequest
     */
    'auditTypes': Array<AuditType>;
    /**
     * 
     * @type {DateRange}
     * @memberof ReportRequest
     */
    'date': DateRange;
    /**
     * The id of the calendar for the request
     * @type {number}
     * @memberof ReportRequest
     */
    'calendarId'?: number;
    /**
     * The id of the venue for the request
     * @type {number}
     * @memberof ReportRequest
     */
    'venueId'?: number;
    /**
     * The id of the staff member for the request
     * @type {string}
     * @memberof ReportRequest
     */
    'staffId'?: string;
}
/**
 * Generic response of the report request containing results
 * @export
 * @interface ReportResponse
 */
export interface ReportResponse {
    /**
     * Filter to audits of any of these types. If empty, any audit will match.
     * @type {Array<AuditType>}
     * @memberof ReportResponse
     */
    'auditTypes': Array<AuditType>;
    /**
     * 
     * @type {DateRange}
     * @memberof ReportResponse
     */
    'date': DateRange;
    /**
     * The id of the calendar for the request
     * @type {number}
     * @memberof ReportResponse
     */
    'calendarId'?: number;
    /**
     * The id of the venue for the request
     * @type {number}
     * @memberof ReportResponse
     */
    'venueId'?: number;
    /**
     * The id of the staff member for the request
     * @type {string}
     * @memberof ReportResponse
     */
    'staffId'?: string;
    /**
     * 
     * @type {Array<ReportResult>}
     * @memberof ReportResponse
     */
    'results': Array<ReportResult>;
}
/**
 * Generic result of the report request
 * @export
 * @interface ReportResult
 */
export interface ReportResult {
    /**
     * 
     * @type {number}
     * @memberof ReportResult
     */
    'auditId': number;
    /**
     * 
     * @type {StaffLink}
     * @memberof ReportResult
     */
    'staffMember'?: StaffLink;
    /**
     * 
     * @type {AuditType}
     * @memberof ReportResult
     */
    'auditType': AuditType;
    /**
     * 
     * @type {GrsLocation}
     * @memberof ReportResult
     */
    'grsLocation': GrsLocation;
    /**
     * The linux epoch time of the report
     * @type {number}
     * @memberof ReportResult
     */
    'date': number;
    /**
     * The compliance score for this specific audit
     * @type {number}
     * @memberof ReportResult
     */
    'score': number;
}
/**
 * Questions Asked for the Sepsis Audit
 * @export
 * @interface SepsisAudit
 */
export interface SepsisAudit {
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'historyOfPresentingComplaint': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'primarySurvey': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'consentObtained': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'capacityAssessed': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'assessmentDocumented'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'observationRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'gcsRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'bpRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'sp02Recorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'respiratoryRateRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'news2Recorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'leadEcg': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'interpretationCorrect'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'managementCorrect'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'clinicAssessmentAppropriate': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'differentialDiagnosticReasonable': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'highFlowOxygenAdministered': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'sodiumChlorideAdministered': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'additionalMedicationGiven': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'otherMedicationAdministered'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'correctRouteOfAdministration'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'correctDosage'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'documentedCorrectly'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'correctClinicianAdministering'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'preAlertPlaced': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'appropriateDestination': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'handoverTimes': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof SepsisAudit
     */
    'callClosedCorrectly': QuestionWithAnswer;
}
/**
 * Direct Reference to a staff member
 * @export
 * @interface StaffLink
 */
export interface StaffLink {
    /**
     * Unique reference to the staff member
     * @type {string}
     * @memberof StaffLink
     */
    'staffId': string;
    /**
     * Real name of the staff member at moment of report relation
     * @type {string}
     * @memberof StaffLink
     */
    'staffName': string;
}
/**
 * Questions Asked for the Stroke Audit
 * @export
 * @interface StrokeAudit
 */
export interface StrokeAudit {
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'historyOfPresentingComplaint': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'primarySurvey': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'consentObtained': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'capacityAssessed': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'assessmentDocumented'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'observationRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'leadEcg': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'interpretationCorrect'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'managementCorrect'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'fastRecordedCorrectly': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'bmRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'bpRecorded': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'clinicAssessmentAppropriate': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'differentialDiagnosticReasonable': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'medicationGiven': QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'appropriateMedicationAdministered'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'correctRouteOfAdministration'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'correctDosage'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'documentedCorrectly'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'correctClinicianAdministering'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithConveyance}
     * @memberof StrokeAudit
     */
    'destination': QuestionWithConveyance;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'hasu'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'stoke60Achieved'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'handoverTimes'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'patientOutcomeReasonable'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'pathfinderOutcome'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'calContacted'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'news2ScoreRecorded'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'worseningCareAdviceGiven'?: QuestionWithAnswer;
    /**
     * 
     * @type {QuestionWithAnswer}
     * @memberof StrokeAudit
     */
    'callClosedCorrectly': QuestionWithAnswer;
}
/**
 * Object containing stats for audits. Contains the total number of audits as well as the number of audits which are 100%, 90%-99% and <90% compliant. Contains the venue information that the stat is attached to
 * @export
 * @interface VenueAuditStats
 */
export interface VenueAuditStats {
    /**
     * The unique ID of the venue
     * @type {number}
     * @memberof VenueAuditStats
     */
    'venueId': number;
    /**
     * Name of the venue, available if the venue is not fetchable by the id.
     * @type {string}
     * @memberof VenueAuditStats
     */
    'venueName': string;
    /**
     * Total number of audits created for this stat
     * @type {number}
     * @memberof VenueAuditStats
     */
    'totalNumberOfAudits': number;
    /**
     * Number of audits for this stat that were 100% compliant
     * @type {number}
     * @memberof VenueAuditStats
     */
    'numberOfAudits100PercentCompliant': number;
    /**
     * Number of audits for this stat that were 90% - 99% compliant
     * @type {number}
     * @memberof VenueAuditStats
     */
    'numberOfAudits90to99PercentCompliant': number;
    /**
     * Number of audits for this stat that were less than 90% compliant
     * @type {number}
     * @memberof VenueAuditStats
     */
    'numberOfAuditsBelow90PercentCompliant': number;
}
/**
 * Questions within the audit type
 * @export
 * @interface YesAnswerMonthlyAuditTypeResult
 */
export interface YesAnswerMonthlyAuditTypeResult {
    /**
     * 
     * @type {AuditType}
     * @memberof YesAnswerMonthlyAuditTypeResult
     */
    'auditType': AuditType;
    /**
     * The question data with months scores based on search criteria
     * @type {Array<YesAnswerMonthlyQuestionResult>}
     * @memberof YesAnswerMonthlyAuditTypeResult
     */
    'questions': Array<YesAnswerMonthlyQuestionResult>;
}
/**
 * How many yes answers for the question, per month
 * @export
 * @interface YesAnswerMonthlyQuestionResult
 */
export interface YesAnswerMonthlyQuestionResult {
    /**
     * The question string
     * @type {string}
     * @memberof YesAnswerMonthlyQuestionResult
     */
    'question': string;
    /**
     * Number of reports that answered \'yes\' to this question per month. Array length should be 12 to represent months
     * @type {Array<number>}
     * @memberof YesAnswerMonthlyQuestionResult
     */
    'monthCounts': Array<number>;
}
/**
 * Number of questions answered yes, based on provided filters, broken down by audit type
 * @export
 * @interface YesAnswerMonthlyReportResult
 */
export interface YesAnswerMonthlyReportResult {
    /**
     * 
     * @type {QuestionType}
     * @memberof YesAnswerMonthlyReportResult
     */
    'questionType': QuestionType;
    /**
     * Filter to audits of any of these types. If empty, any audit will match.
     * @type {Array<AuditType>}
     * @memberof YesAnswerMonthlyReportResult
     */
    'auditTypes': Array<AuditType>;
    /**
     * ID of the calendar that the quality report is being generated for.
     * @type {number}
     * @memberof YesAnswerMonthlyReportResult
     */
    'calendarId'?: number;
    /**
     * ID of the staff member for the Outcomes report.
     * @type {string}
     * @memberof YesAnswerMonthlyReportResult
     */
    'staffId'?: string;
    /**
     * The id of the venue for the request
     * @type {number}
     * @memberof YesAnswerMonthlyReportResult
     */
    'venueId'?: number;
    /**
     * 
     * @type {DateRange}
     * @memberof YesAnswerMonthlyReportResult
     */
    'date': DateRange;
    /**
     * The distinct audit types found
     * @type {Array<YesAnswerMonthlyAuditTypeResult>}
     * @memberof YesAnswerMonthlyReportResult
     */
    'auditTypeResults': Array<YesAnswerMonthlyAuditTypeResult>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a PrfAudit from the DB
         * @param {number} id Unique ID for the Prf audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrfAudit: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePrfAudit', 'id', id)
            const localVarPath = `/audits/audit/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get stats for Prf Audit types
         * @param {AuditTypeStatsRequest} [auditTypeStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuditTypeStats: async (auditTypeStatsRequest?: AuditTypeStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/stats/audittype/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditTypeStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get calendar stats for Prf Audit
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCalendarAuditStats: async (basicStatsRequest?: BasicStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/stats/calendar/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(basicStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get venue stats for Prf Audit
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVenueAuditStats: async (basicStatsRequest?: BasicStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/stats/venue/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(basicStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the audit type report based on the request parameters
         * @param {ReportRequest} [reportRequest] Audit Type Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTypeReport: async (reportRequest?: ReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/reports/audittype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get stats for Prf Audit types
         * @param {AuditType} id Type of audit
         * @param {AuditTypeStatsRequest} [auditTypeStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTypeStats: async (id: AuditType, auditTypeStatsRequest?: AuditTypeStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAuditTypeStats', 'id', id)
            const localVarPath = `/audits/stats/audittype/bytype/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditTypeStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets matching audits based on the provided search criteria
         * @param {AuditListRequest} [auditListRequest] Audit Trail Log Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditsListPaged: async (auditListRequest?: AuditListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/list/paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get calendar stats for a specific calendar
         * @param {number} id Unique ID of the Calendar
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarAuditStats: async (id: number, basicStatsRequest?: BasicStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCalendarAuditStats', 'id', id)
            const localVarPath = `/audits/stats/calendar/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(basicStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get stats for a specific clinician
         * @param {string} id Unique staff ID of the clinician
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicianAuditStats: async (id: string, basicStatsRequest?: BasicStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getClinicianAuditStats', 'id', id)
            const localVarPath = `/audits/stats/clinician/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(basicStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the compliance report based on the request parameters
         * @param {ReportRequest} [reportRequest] Compliance Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceReport: async (reportRequest?: ReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/reports/compliance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific historic audit version
         * @param {number} id Unique ID the prf audit
         * @param {number} hid Unique ID of the historic version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricAudit: async (id: number, hid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getHistoricAudit', 'id', id)
            // verify required parameter 'hid' is not null or undefined
            assertParamExists('getHistoricAudit', 'hid', hid)
            const localVarPath = `/audits/audit/byid/{id}/historic/byid/{hid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"hid"}}`, encodeURIComponent(String(hid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the outcome report based on the request parameters
         * @param {ReportRequest} [reportRequest] Outcome Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutcomeReport: async (reportRequest?: ReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/reports/outcome`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific PrfAudit
         * @param {number} id Unique ID for the Prf Audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrfAudit: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPrfAudit', 'id', id)
            const localVarPath = `/audits/audit/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the quality report based on the request parameters
         * @param {ReportRequest} [reportRequest] Quality Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQualityReport: async (reportRequest?: ReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/reports/quality`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the total number of audits completed within a date range
         * @param {DateRange} [dateRange] Date range to get number of audits completed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalNumberOfCompletedAudits: async (dateRange?: DateRange, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/stats/completed/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateRange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get venue stats for a specific venue
         * @param {number} id Unique ID of the Venue
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueAuditStats: async (id: number, basicStatsRequest?: BasicStatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVenueAuditStats', 'id', id)
            const localVarPath = `/audits/stats/venue/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(basicStatsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the venue audit report based on the request parameters
         * @param {ReportRequest} [reportRequest] Venue Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueReport: async (reportRequest?: ReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/reports/venue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Give staff feedback for audit
         * @param {AuditFeedback} [auditFeedback] Feedback for audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveFeedbackForAudit: async (auditFeedback?: AuditFeedback, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/actions/feedback/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auditFeedback, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List historic versions for a specific audit
         * @param {number} id Unique ID the prf audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuditHistoricVersions: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('listAuditHistoricVersions', 'id', id)
            const localVarPath = `/audits/audit/byid/{id}/historic/list`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a PrfAudit to the DB. ID 0 is an insert, greater than 0 will update
         * @param {PrfAudit} [prfAudit] Prf Audit to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePrfAudit: async (prfAudit?: PrfAudit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/audits/audit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(prfAudit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a PrfAudit from the DB
         * @param {number} id Unique ID for the Prf audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePrfAudit(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePrfAudit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get stats for Prf Audit types
         * @param {AuditTypeStatsRequest} [auditTypeStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAuditTypeStats(auditTypeStatsRequest?: AuditTypeStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditTypeStats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAuditTypeStats(auditTypeStatsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get calendar stats for Prf Audit
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCalendarAuditStats(basicStatsRequest?: BasicStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarAuditStats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCalendarAuditStats(basicStatsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get venue stats for Prf Audit
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllVenueAuditStats(basicStatsRequest?: BasicStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VenueAuditStats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllVenueAuditStats(basicStatsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the audit type report based on the request parameters
         * @param {ReportRequest} [reportRequest] Audit Type Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditTypeReport(reportRequest?: ReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditTypeReport(reportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get stats for Prf Audit types
         * @param {AuditType} id Type of audit
         * @param {AuditTypeStatsRequest} [auditTypeStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditTypeStats(id: AuditType, auditTypeStatsRequest?: AuditTypeStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditTypeStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditTypeStats(id, auditTypeStatsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets matching audits based on the provided search criteria
         * @param {AuditListRequest} [auditListRequest] Audit Trail Log Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditsListPaged(auditListRequest?: AuditListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditsListPaged(auditListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get calendar stats for a specific calendar
         * @param {number} id Unique ID of the Calendar
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarAuditStats(id: number, basicStatsRequest?: BasicStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarAuditStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarAuditStats(id, basicStatsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get stats for a specific clinician
         * @param {string} id Unique staff ID of the clinician
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClinicianAuditStats(id: string, basicStatsRequest?: BasicStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClinicianAuditStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClinicianAuditStats(id, basicStatsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the compliance report based on the request parameters
         * @param {ReportRequest} [reportRequest] Compliance Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComplianceReport(reportRequest?: ReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComplianceReport(reportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific historic audit version
         * @param {number} id Unique ID the prf audit
         * @param {number} hid Unique ID of the historic version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoricAudit(id: number, hid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrfAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoricAudit(id, hid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the outcome report based on the request parameters
         * @param {ReportRequest} [reportRequest] Outcome Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOutcomeReport(reportRequest?: ReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YesAnswerMonthlyReportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOutcomeReport(reportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a specific PrfAudit
         * @param {number} id Unique ID for the Prf Audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrfAudit(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrfAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrfAudit(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the quality report based on the request parameters
         * @param {ReportRequest} [reportRequest] Quality Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQualityReport(reportRequest?: ReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YesAnswerMonthlyReportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQualityReport(reportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the total number of audits completed within a date range
         * @param {DateRange} [dateRange] Date range to get number of audits completed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalNumberOfCompletedAudits(dateRange?: DateRange, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompletedAuditStat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalNumberOfCompletedAudits(dateRange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get venue stats for a specific venue
         * @param {number} id Unique ID of the Venue
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVenueAuditStats(id: number, basicStatsRequest?: BasicStatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VenueAuditStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVenueAuditStats(id, basicStatsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the venue audit report based on the request parameters
         * @param {ReportRequest} [reportRequest] Venue Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVenueReport(reportRequest?: ReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVenueReport(reportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Give staff feedback for audit
         * @param {AuditFeedback} [auditFeedback] Feedback for audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giveFeedbackForAudit(auditFeedback?: AuditFeedback, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giveFeedbackForAudit(auditFeedback, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List historic versions for a specific audit
         * @param {number} id Unique ID the prf audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAuditHistoricVersions(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditHistoryOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAuditHistoricVersions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a PrfAudit to the DB. ID 0 is an insert, greater than 0 will update
         * @param {PrfAudit} [prfAudit] Prf Audit to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePrfAudit(prfAudit?: PrfAudit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrfAudit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePrfAudit(prfAudit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a PrfAudit from the DB
         * @param {number} id Unique ID for the Prf audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePrfAudit(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePrfAudit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get stats for Prf Audit types
         * @param {AuditTypeStatsRequest} [auditTypeStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuditTypeStats(auditTypeStatsRequest?: AuditTypeStatsRequest, options?: any): AxiosPromise<Array<AuditTypeStats>> {
            return localVarFp.getAllAuditTypeStats(auditTypeStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get calendar stats for Prf Audit
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCalendarAuditStats(basicStatsRequest?: BasicStatsRequest, options?: any): AxiosPromise<Array<CalendarAuditStats>> {
            return localVarFp.getAllCalendarAuditStats(basicStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get venue stats for Prf Audit
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVenueAuditStats(basicStatsRequest?: BasicStatsRequest, options?: any): AxiosPromise<Array<VenueAuditStats>> {
            return localVarFp.getAllVenueAuditStats(basicStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the audit type report based on the request parameters
         * @param {ReportRequest} [reportRequest] Audit Type Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTypeReport(reportRequest?: ReportRequest, options?: any): AxiosPromise<ReportResponse> {
            return localVarFp.getAuditTypeReport(reportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get stats for Prf Audit types
         * @param {AuditType} id Type of audit
         * @param {AuditTypeStatsRequest} [auditTypeStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTypeStats(id: AuditType, auditTypeStatsRequest?: AuditTypeStatsRequest, options?: any): AxiosPromise<AuditTypeStats> {
            return localVarFp.getAuditTypeStats(id, auditTypeStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets matching audits based on the provided search criteria
         * @param {AuditListRequest} [auditListRequest] Audit Trail Log Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditsListPaged(auditListRequest?: AuditListRequest, options?: any): AxiosPromise<AuditListResponse> {
            return localVarFp.getAuditsListPaged(auditListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get calendar stats for a specific calendar
         * @param {number} id Unique ID of the Calendar
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarAuditStats(id: number, basicStatsRequest?: BasicStatsRequest, options?: any): AxiosPromise<CalendarAuditStats> {
            return localVarFp.getCalendarAuditStats(id, basicStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get stats for a specific clinician
         * @param {string} id Unique staff ID of the clinician
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClinicianAuditStats(id: string, basicStatsRequest?: BasicStatsRequest, options?: any): AxiosPromise<ClinicianAuditStats> {
            return localVarFp.getClinicianAuditStats(id, basicStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the compliance report based on the request parameters
         * @param {ReportRequest} [reportRequest] Compliance Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComplianceReport(reportRequest?: ReportRequest, options?: any): AxiosPromise<ReportResponse> {
            return localVarFp.getComplianceReport(reportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific historic audit version
         * @param {number} id Unique ID the prf audit
         * @param {number} hid Unique ID of the historic version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricAudit(id: number, hid: number, options?: any): AxiosPromise<PrfAudit> {
            return localVarFp.getHistoricAudit(id, hid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the outcome report based on the request parameters
         * @param {ReportRequest} [reportRequest] Outcome Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOutcomeReport(reportRequest?: ReportRequest, options?: any): AxiosPromise<YesAnswerMonthlyReportResult> {
            return localVarFp.getOutcomeReport(reportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific PrfAudit
         * @param {number} id Unique ID for the Prf Audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrfAudit(id: number, options?: any): AxiosPromise<PrfAudit> {
            return localVarFp.getPrfAudit(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the quality report based on the request parameters
         * @param {ReportRequest} [reportRequest] Quality Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQualityReport(reportRequest?: ReportRequest, options?: any): AxiosPromise<YesAnswerMonthlyReportResult> {
            return localVarFp.getQualityReport(reportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the total number of audits completed within a date range
         * @param {DateRange} [dateRange] Date range to get number of audits completed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalNumberOfCompletedAudits(dateRange?: DateRange, options?: any): AxiosPromise<CompletedAuditStat> {
            return localVarFp.getTotalNumberOfCompletedAudits(dateRange, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get venue stats for a specific venue
         * @param {number} id Unique ID of the Venue
         * @param {BasicStatsRequest} [basicStatsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueAuditStats(id: number, basicStatsRequest?: BasicStatsRequest, options?: any): AxiosPromise<VenueAuditStats> {
            return localVarFp.getVenueAuditStats(id, basicStatsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the venue audit report based on the request parameters
         * @param {ReportRequest} [reportRequest] Venue Report Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueReport(reportRequest?: ReportRequest, options?: any): AxiosPromise<ReportResponse> {
            return localVarFp.getVenueReport(reportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Give staff feedback for audit
         * @param {AuditFeedback} [auditFeedback] Feedback for audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveFeedbackForAudit(auditFeedback?: AuditFeedback, options?: any): AxiosPromise<void> {
            return localVarFp.giveFeedbackForAudit(auditFeedback, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List historic versions for a specific audit
         * @param {number} id Unique ID the prf audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAuditHistoricVersions(id: number, options?: any): AxiosPromise<AuditHistoryOverview> {
            return localVarFp.listAuditHistoricVersions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a PrfAudit to the DB. ID 0 is an insert, greater than 0 will update
         * @param {PrfAudit} [prfAudit] Prf Audit to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePrfAudit(prfAudit?: PrfAudit, options?: any): AxiosPromise<PrfAudit> {
            return localVarFp.savePrfAudit(prfAudit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Delete a PrfAudit from the DB
     * @param {number} id Unique ID for the Prf audit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deletePrfAudit(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deletePrfAudit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get stats for Prf Audit types
     * @param {AuditTypeStatsRequest} [auditTypeStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllAuditTypeStats(auditTypeStatsRequest?: AuditTypeStatsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllAuditTypeStats(auditTypeStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get calendar stats for Prf Audit
     * @param {BasicStatsRequest} [basicStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllCalendarAuditStats(basicStatsRequest?: BasicStatsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllCalendarAuditStats(basicStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get venue stats for Prf Audit
     * @param {BasicStatsRequest} [basicStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllVenueAuditStats(basicStatsRequest?: BasicStatsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllVenueAuditStats(basicStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the audit type report based on the request parameters
     * @param {ReportRequest} [reportRequest] Audit Type Report Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuditTypeReport(reportRequest?: ReportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuditTypeReport(reportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get stats for Prf Audit types
     * @param {AuditType} id Type of audit
     * @param {AuditTypeStatsRequest} [auditTypeStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuditTypeStats(id: AuditType, auditTypeStatsRequest?: AuditTypeStatsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuditTypeStats(id, auditTypeStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets matching audits based on the provided search criteria
     * @param {AuditListRequest} [auditListRequest] Audit Trail Log Report Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuditsListPaged(auditListRequest?: AuditListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAuditsListPaged(auditListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get calendar stats for a specific calendar
     * @param {number} id Unique ID of the Calendar
     * @param {BasicStatsRequest} [basicStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCalendarAuditStats(id: number, basicStatsRequest?: BasicStatsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCalendarAuditStats(id, basicStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get stats for a specific clinician
     * @param {string} id Unique staff ID of the clinician
     * @param {BasicStatsRequest} [basicStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClinicianAuditStats(id: string, basicStatsRequest?: BasicStatsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getClinicianAuditStats(id, basicStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the compliance report based on the request parameters
     * @param {ReportRequest} [reportRequest] Compliance Report Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getComplianceReport(reportRequest?: ReportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getComplianceReport(reportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific historic audit version
     * @param {number} id Unique ID the prf audit
     * @param {number} hid Unique ID of the historic version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHistoricAudit(id: number, hid: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getHistoricAudit(id, hid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the outcome report based on the request parameters
     * @param {ReportRequest} [reportRequest] Outcome Report Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getOutcomeReport(reportRequest?: ReportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getOutcomeReport(reportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific PrfAudit
     * @param {number} id Unique ID for the Prf Audit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPrfAudit(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPrfAudit(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the quality report based on the request parameters
     * @param {ReportRequest} [reportRequest] Quality Report Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getQualityReport(reportRequest?: ReportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getQualityReport(reportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the total number of audits completed within a date range
     * @param {DateRange} [dateRange] Date range to get number of audits completed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTotalNumberOfCompletedAudits(dateRange?: DateRange, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTotalNumberOfCompletedAudits(dateRange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get venue stats for a specific venue
     * @param {number} id Unique ID of the Venue
     * @param {BasicStatsRequest} [basicStatsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVenueAuditStats(id: number, basicStatsRequest?: BasicStatsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVenueAuditStats(id, basicStatsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the venue audit report based on the request parameters
     * @param {ReportRequest} [reportRequest] Venue Report Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVenueReport(reportRequest?: ReportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVenueReport(reportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Give staff feedback for audit
     * @param {AuditFeedback} [auditFeedback] Feedback for audit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public giveFeedbackForAudit(auditFeedback?: AuditFeedback, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).giveFeedbackForAudit(auditFeedback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List historic versions for a specific audit
     * @param {number} id Unique ID the prf audit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listAuditHistoricVersions(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listAuditHistoricVersions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a PrfAudit to the DB. ID 0 is an insert, greater than 0 will update
     * @param {PrfAudit} [prfAudit] Prf Audit to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public savePrfAudit(prfAudit?: PrfAudit, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).savePrfAudit(prfAudit, options).then((request) => request(this.axios, this.basePath));
    }
}


