import React from "react";
import {ComponentChildrenProps} from "../../utils/componentUtils";

/** Wrapper Component which contains the boxes the stats sit in */
const SectionContainer = ({children}: ComponentChildrenProps) => {
    return (
        <React.Fragment>
            <div className="pt-4 pb-4">{children}</div>
        </React.Fragment>
    );
};

export default SectionContainer;
