import {Enum} from "../../../utils/enumUtils";
import {
    decapitalizeFirstLetter,
    removeWhitespaceFromString,
    splitStringByCapitalLetter
} from "../../../utils/textUtils";
import {StaffAccessLevel, UserData} from "../../../api/staff";

/** Props used for the generic type dropdown */
export interface GenericTypeDropDownProps<E extends Enum<E>> extends ExtendedDropdownProps {
    enumOptions: E;
    splitByCapitalLetter: boolean;
    getOptionsFrom: OptionsFromType;
    searchWithDecapitalisedFirstLetter: boolean;
    appendToBody: boolean;
}

export interface StaffLink {
    /**
     * Unique reference to the staff member
     * @type {string}
     * @memberof StaffLink
     */
    staffId: string;
    /**
     * Real name of the staff member at moment of report relation
     * @type {string}
     * @memberof StaffLink
     */
    staffName: string;
}

/** Props used for the staff dropdown */
export interface StaffDropdownProps extends BasicDropdownProps {
    searchable: boolean;
    accessLevelFilter?: StaffAccessLevel;
    changeOption: (staffMember: StaffLink) => void;
    clearable: boolean;
}

/** Props used for the extended props for dropdown props */
export interface ExtendedDropdownProps extends DropDownProps {
    clearable: boolean;
    searchable: boolean;
}

/** Props used for the extended props for basic dropdown props */
export interface DropDownProps extends BasicDropdownProps {
    changeOption: (id: number | string) => void;
}

/** limited access (id = undefined) and basic needed for dropdown props */
export interface LimitedDropdownProps {
    id?: number | string;
    disabled: boolean;
    changeOption: (id?: number | string) => void;
}

/** bare minimum needed for dropdown props */
export interface BasicDropdownProps {
    id: number | string;
    disabled: boolean;
}

/** Dropdown component takes these as arguments for its object. */
export interface DDProps {
    value: number | string;
    label: string;
}

// eslint-disable-next-line no-shadow
export enum OptionsFromType {
    Key = "Key",
    Value = "Value"
}

export function getSelectedOptionFromOptionsFromType(
    id: string | number,
    options: DDProps[],
    optionType: OptionsFromType
): DDProps {
    switch (optionType) {
        case OptionsFromType.Value:
            return getSelectedDropdownOptionByValue(id, options);
        case OptionsFromType.Key:
            return getSelectedDropdownOptionByLabel(id, options);
    }
}
/** Get the selected item of the dropdown props from the id */
export function getSelectedDropdownOptionByValue(id: string | number, options: DDProps[]): DDProps {
    if (options.length === 0) {
        return {
            value: -1,
            label: "No available options"
        };
    }

    const index = options.findIndex((el: DDProps) => id === el.value);

    if (index < 0) {
        return options[0];
    }

    return options[index];
}

/** Get the selected item of the dropdown props from the id */
export function getSelectedDropdownOptionByLabel(id: string | number, options: DDProps[]): DDProps {
    const standardisedEnums = convertDropdownLabelBackToStandardisedEnums(options);
    const index = standardisedEnums.findIndex((el: DDProps) => id === el.label);

    if (index < 0) {
        return options[0];
    }

    return options[index];
}

/** Converts the options that were generated by enums back into a standardised form */
function convertDropdownLabelBackToStandardisedEnums(options: DDProps[]): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const option of options) {
        ddProps.push({
            ...option,
            label: decapitalizeFirstLetter(removeWhitespaceFromString(option.label))
        });
    }

    return ddProps;
}

/** converts the enum into dropdown options */
export function getGenericDropdownOptions(
    options: Enum<any>,
    splitString: boolean,
    optionsFrom: OptionsFromType
): DDProps[] {
    const ddProps: DDProps[] = [];
    const enumOptions: (string | number)[] = getOptions(options, optionsFrom);

    for (const option of enumOptions) {
        const label = splitString
            ? splitStringByCapitalLetter(option.toString())
            : option.toString();
        //Label is selectively rendered based on the props
        ddProps.push({
            value: option,
            label
        });
    }

    return ddProps;
}

/** Sorts the list of staff into dropdown props to be used by staff dropdown */
export function sortUserDataToDropdownProps(users: UserData[]): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const user of users) {
        ddProps.push({
            label: `${user.firstName} ${user.lastName}`,
            value: user.username
        });
    }

    return ddProps;
}

function getOptions(options: Enum<any>, optionsFrom: OptionsFromType) {
    switch (optionsFrom) {
        case OptionsFromType.Key:
            return Object.keys(options);
        case OptionsFromType.Value:
            return Object.values(options);
    }
}
