import React from "react";
import {Switch, Redirect} from "react-router-dom";
import Dashboard from "../../Pages/Dashboard/Dashboard";

/** Auth Pages */
import Login from "../../Pages/Auth/Login/Login";
import LoggedIn from "../../Pages/Auth/Loggedin/LoggedIn";
import NotFound from "../../Pages/Auth/NotFound/NotFound";
import NotAuthorised from "../../Pages/Auth/NotAuthorised/NotAuthorised";
import InActive from "../../Pages/Auth/InActive/InActive";
import Logout from "../../Pages/Auth/Logout/Logout";

import {routeNames} from "../routeNames";
import {GuardProvider} from "react-router-guards";
import {StaffAccessLevel} from "../../../api/staff";
import store from "../../../store/Store";
import {LoadingWheel, Error} from "react-state-helpers";

/** Route specific items */
import CustomGuardedRoute from "./CustomGuardedRoute";
import MedicareLayout from "../../Pages/Layouts/Layout/MedicareLayout";
import WageCalculation from "../../Pages/WageCalculation/WageCalculation";
import NoNavLayout from "../../Pages/Layouts/Layout/NoNavLayout";
import Totp from "../../Pages/Totp/Totp";
import {getConfig} from "../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../utils/userDataUtils";

const requireLogin = async (to: any, from: any, next: any) => {
    const accessLevels = to.meta.auth?.accessLevels as Array<StaffAccessLevel>;
    //No auth required, carry on
    if (!accessLevels) {
        next();
        return;
    }

    const prevRoutesBlackList = [
        routeNames.login.path,
        routeNames.loggedin.path,
        routeNames.notFound.path,
        routeNames.notFound.path,
        routeNames.inActive.path,
        routeNames.logout.path
    ];

    const idx = prevRoutesBlackList.findIndex((route: string) => route === from.location.pathname);

    if (idx < 0) {
        localStorage.setItem("previous_route", from.location.pathname);
    }

    localStorage.setItem("current_route", to.location.pathname);

    const authenticated = store.getState().auth.authenticated;
    const user = store.getState().auth.data;

    if (!authenticated) {
        next.redirect(routeNames.login.path);
        return;
    }

    if (user) {
        const config = await getConfig();

        const userAccessLevel = getUserAccessLevel(user, config);
        const index = accessLevels.findIndex((x) => x === userAccessLevel);
        if (index < 0) {
            next.redirect(routeNames.notAuthorised.path);
            return;
        }
    }

    next();
};

const Routes = () => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <GuardProvider guards={[requireLogin]} loading={LoadingWheel} error={Error}>
            <Switch>
                <CustomGuardedRoute
                    exact
                    path={routeNames.login.path}
                    layout={NoNavLayout}
                    component={Login}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.loggedin.path}
                    layout={NoNavLayout}
                    component={LoggedIn}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.notAuthorised.path}
                    layout={MedicareLayout}
                    component={NotAuthorised}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.inActive.path}
                    layout={NoNavLayout}
                    component={InActive}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.logout.path}
                    layout={NoNavLayout}
                    component={Logout}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.totp.path}
                    component={Totp}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.Staff,
                                StaffAccessLevel.DutyManager,
                                StaffAccessLevel.SystemAdministrator
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.dashboard.path}
                    component={Dashboard}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.Staff,
                                StaffAccessLevel.DutyManager,
                                StaffAccessLevel.SystemAdministrator
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.wageCalculation.path}
                    component={WageCalculation}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.notFound.path}
                    layout={MedicareLayout}
                    component={NotFound}
                />
                <CustomGuardedRoute
                    path="/"
                    render={(): JSX.Element => <Redirect to={routeNames.login.path} />}
                />

                <CustomGuardedRoute
                    render={(): JSX.Element => <Redirect to={routeNames.notFound.path} />}
                />
            </Switch>
        </GuardProvider>
    );
};

export default Routes;
