import {DRUG_BATCH_LIST_STORE} from "../actions/DrugBatchListActionTypes";
import {DrugBatch} from "../../../../api/mm";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugBatch[]>([]);

const drugBatchListReducer = (
    state: StoreServiceData<DrugBatch[]> = defaultState,
    action: ServiceActionTypes<DrugBatch[]>
): StoreServiceData<DrugBatch[]> =>
    createReducer(state, action, DRUG_BATCH_LIST_STORE, () => showErrorToast(action.error));

export default drugBatchListReducer;
