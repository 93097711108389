import {
    NOTIFICATIONS_HUB_STORE_STATE,
    NotificationItem
} from "../actions/NotificationsHubActionTypes";
import {ServiceActionTypes, StoreServiceData} from "store-fetch-wrappers";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<NotificationItem[]>([]);

const notificationsHubReducer = (
    state: StoreServiceData<NotificationItem[]> = defaultState,
    action: ServiceActionTypes<NotificationItem[]>
): StoreServiceData<NotificationItem[]> =>
    createReducer(state, action, NOTIFICATIONS_HUB_STORE_STATE, () => showErrorToast(action.error));

export default notificationsHubReducer;
