import React, {useEffect, useState} from "react";
import {showErrorToast} from "../../utils/toastUtils";
import {MethodType} from "./TotpSetup";
import {TotpWithQrCode} from "../../store/totp/actions/TotpActionTypes";
import {TotpVerifyResponse} from "../../api/staff";
import {verifyTotpConnection} from "../../store/totp/actions/TotpActions";
import Card from "../Cards/Card";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {Loading} from "store-fetch-wrappers";
import QrCodeCard from "./QrCode/QrCodeCard";
import SecretCodeCard from "./SecretKey/SecretCodeCard";
import QrCodeStep1 from "./QrCode/QrCodeStep1";
import SecretCodeStep1 from "./SecretKey/SecretCodeStep1";
import TotpStep2 from "./Shared/TotpStep2";
import {useKeyPress} from "../Hooks/useKeyPress";

const errorMessage = {
    default: "Could not verify code. Please try again",
    noCodeLength: "Invalid code"
};

function TotpFormPart1({base64QrCode, onSuccess, secretCode}: Props) {
    const [method, setMethod] = useState<MethodType>(MethodType.QrCode);
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);

    const submitPress = useKeyPress("Enter");

    useEffect(() => {
        if (submitPress) {
            verifyTotp();
        }
    }, [submitPress]);

    async function verifyTotp() {
        setLoading(true);
        if (!code.length) {
            showErrorToast(errorMessage.noCodeLength);
            setLoading(false);
            return;
        }

        try {
            const response = await verifyTotpConnection({totpCode: code});

            if (!response) {
                showErrorToast(errorMessage.default);
                setLoading(false);
                return;
            }

            if (response.success) {
                onSuccess(response);
                setLoading(false);
                return;
            }

            showErrorToast(response.message || errorMessage.default);
            setLoading(false);
        } catch (e: any) {
            showErrorToast(e || errorMessage.default);
            setLoading(false);
        }
    }

    return (
        <div className="totp-container fadeIn">
            <div className="totp-item left">
                <Card
                    footer={
                        <div className="row ml-0 mr-0">
                            <div className="col d-flex justify-content-end align-items-center pr-0">
                                {loading ? (
                                    <Loading type={"three-ring"} showLoadingText={false} />
                                ) : (
                                    <MCButton
                                        size={ButtonSize.Large}
                                        innerValue={"Verify"}
                                        disabled={loading}
                                        onClick={verifyTotp}
                                        colour={ButtonColourOptions.Yellow}
                                        className="mb-0 mr-0"
                                    />
                                )}
                            </div>
                        </div>
                    }
                >
                    <h5 className="mb-4">Set up multi factor authentication</h5>
                    <div className="totp-method-container">
                        <div className="totp-method-wrapper">
                            <MCButton
                                className="mb-0 mr-2"
                                size={ButtonSize.Large}
                                innerValue={"Qr Code"}
                                onClick={() => setMethod(MethodType.QrCode)}
                                colour={
                                    method === MethodType.QrCode
                                        ? ButtonColourOptions.DarkBlue
                                        : ButtonColourOptions.Default
                                }
                            />
                            <MCButton
                                className="mb-0 mr-0"
                                size={ButtonSize.Large}
                                innerValue={"Secret Code"}
                                onClick={() => setMethod(MethodType.SecretCode)}
                                colour={
                                    method === MethodType.SecretCode
                                        ? ButtonColourOptions.DarkBlue
                                        : ButtonColourOptions.Default
                                }
                            />
                        </div>
                    </div>
                    <div className="totp-section">
                        {method === MethodType.QrCode ? (
                            <QrCodeStep1 />
                        ) : (
                            <SecretCodeStep1 secretCode={secretCode} />
                        )}
                        <TotpStep2 />

                        <p className="mt-4 mb-3">
                            <b className="header-font">Authentication Code</b>
                        </p>
                        <input
                            disabled={loading}
                            className="input-fields mb-3"
                            placeholder="Enter 6 digit code here..."
                            onChange={(event) => {
                                const {value} = event.target;

                                setCode(value);
                            }}
                        />
                    </div>
                </Card>
            </div>
            <div className="totp-item right">
                {method === MethodType.QrCode ? (
                    <QrCodeCard base64QrCode={base64QrCode} />
                ) : (
                    <SecretCodeCard />
                )}
            </div>
        </div>
    );
}

export default TotpFormPart1;

interface Props extends TotpWithQrCode {
    onSuccess: (response: TotpVerifyResponse) => void;
}
