import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {OVERALL_COMPLIANCE_STORE} from "../actions/OverallComplianceActionTypes";
import {StatisticsItem} from "../../../helpers/statisticsHelpers";
import {showErrorToast} from "../../../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<StatisticsItem>({
    needleAngle: 0,
    compliancePercentage: 0,
    totalValue: 0,
    outerLabel: "PRF",
    tooltipText: "Overall PRF Audit Compliance: 0%"
});

/** Infer type of the reducer for code completion and quality */
const overallComplianceReducer = (
    state: StoreServiceData<StatisticsItem> = defaultState,
    action: ServiceActionTypes<StatisticsItem>
): StoreServiceData<StatisticsItem> =>
    createReducer(state, action, OVERALL_COMPLIANCE_STORE, () => showErrorToast(action.error));

export default overallComplianceReducer;
