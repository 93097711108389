import React from "react";
import {AbsenceStatsBlock} from "../../../../../../store/statistics/absence/absenceStats/actions/AbsenceStatsActionTypes";
import NoItemsMessage from "../../../../../Table/NoItemsMessage";
import StatsItem from "../Components/StatsItem";

function AbsenceStats(props: AbsenceStatsBlock) {
    return (
        <React.Fragment>
            {props.statItems.length > 0 ? (
                <React.Fragment>
                    {props.statItems.map((item, key) => {
                        return <StatsItem {...item} key={key} />;
                    })}
                </React.Fragment>
            ) : (
                <NoItemsMessage message={"There are no stats for this month"} />
            )}
        </React.Fragment>
    );
}

export default AbsenceStats;
