import React, {useState} from "react";
import {TotpWithQrCode} from "../../store/totp/actions/TotpActionTypes";

import TotpFormPart1 from "./TotpFormPart1";
import {TotpVerifyResponse} from "../../api/staff";
import TotpFormPart2 from "./TotpFormPart2";
import {useDispatch} from "react-redux";
import {fetchUserData} from "../../store/auth/actions/AuthActions";
import {useUserData} from "../Hooks/useUserData";

function TotpSetup(props: TotpWithQrCode) {
    const [totpSuccess, setTotpSuccess] = useState<TotpVerifyResponse>();
    const dispatch = useDispatch();
    const user = useUserData();

    function onSuccess(response: TotpVerifyResponse) {
        if (!user.username) return;
        setTotpSuccess(response);
        dispatch(fetchUserData(user.username));
    }
    return (
        <React.Fragment>
            {totpSuccess && totpSuccess.success ? (
                <TotpFormPart2 {...totpSuccess} />
            ) : (
                <TotpFormPart1 {...props} onSuccess={onSuccess} />
            )}
        </React.Fragment>
    );
}
export default TotpSetup;

// eslint-disable-next-line no-shadow
export enum MethodType {
    SecretCode = "SecretCode",
    QrCode = "QrCode"
}
// eslint-disable-next-line no-shadow
export enum TotpStep {
    One = "One",
    Two = "Two"
}
