// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Medicare MMS
 * API for Medicare Medicine Management System
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "http://localhost:9004/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * Administer some drugs from a drug batch
 * @export
 * @interface AdministerDrugsRequest
 */
export interface AdministerDrugsRequest {
    /**
     * Unique ID of the drug batch
     * @type {number}
     * @memberof AdministerDrugsRequest
     */
    drugBatchId: number;
    /**
     * 
     * @type {DrugBatchAdministration}
     * @memberof AdministerDrugsRequest
     */
    administration: DrugBatchAdministration;
}

/**
 * Basic range of Linux epoch dates
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * Start date, inclusive
     * @type {number}
     * @memberof DateRange
     */
    startDate: number;
    /**
     * End date, exclusive
     * @type {number}
     * @memberof DateRange
     */
    endDate: number;
}

/**
 * Dispose of some drugs from a drug batch
 * @export
 * @interface DisposeDrugsRequest
 */
export interface DisposeDrugsRequest {
    /**
     * Unique ID of the drug batch
     * @type {number}
     * @memberof DisposeDrugsRequest
     */
    drugBatchId: number;
    /**
     * 
     * @type {DrugBatchDisposal}
     * @memberof DisposeDrugsRequest
     */
    disposal: DrugBatchDisposal;
}

/**
 * The method of administering a drug, eg. how it got into the patient
 * @export
 * @enum {string}
 */
export enum DrugAdministrationMethod {
    Oral = 'Oral',
    Inhaled = 'Inhaled',
    Nebulised = 'Nebulised',
    Rectal = 'Rectal',
    Sublingual = 'Sublingual',
    Buccal = 'Buccal',
    Subcutaneous = 'Subcutaneous',
    Intramuscular = 'Intramuscular',
    Intravenous = 'Intravenous',
    Intraosseous = 'Intraosseous',
    Other = 'Other'
}

/**
 * A single audit, latest version
 * @export
 * @interface DrugAudit
 */
export interface DrugAudit {
    /**
     * Unique ID for this audit, historic versions will all related to this ID
     * @type {number}
     * @memberof DrugAudit
     */
    id: number;
    /**
     * Linux epoch for when the audit was first created
     * @type {number}
     * @memberof DrugAudit
     */
    dateCreated: number;
    /**
     * Linux epoch for when the audit was last updated (the date for this specific audit)
     * @type {number}
     * @memberof DrugAudit
     */
    dateModified: number;
    /**
     * 
     * @type {StaffLink}
     * @memberof DrugAudit
     */
    originalAuthor: StaffLink;
    /**
     * 
     * @type {StaffLink}
     * @memberof DrugAudit
     */
    modifiedAuthor: StaffLink;
    /**
     * 
     * @type {StaffLink}
     * @memberof DrugAudit
     */
    reportingManager: StaffLink;
    /**
     * ID of the location this audit is for
     * @type {number}
     * @memberof DrugAudit
     */
    locationId: number;
    /**
     * Name of the location this audit is for
     * @type {string}
     * @memberof DrugAudit
     */
    locationName: string;
    /**
     * 
     * @type {DrugAuditType}
     * @memberof DrugAudit
     */
    type: DrugAuditType;
    /**
     * Any applicable comments associated with the audit
     * @type {any}
     * @memberof DrugAudit
     */
    comments?: any;
    /**
     * RAW JSON string for the audit contents
     * @type {any}
     * @memberof DrugAudit
     */
    payload: any;
}

/**
 * Basic headline information for a historic version of an audit
 * @export
 * @interface DrugAuditHistoryInfo
 */
export interface DrugAuditHistoryInfo {
    /**
     * Unique ID for the **original** audit
     * @type {number}
     * @memberof DrugAuditHistoryInfo
     */
    id: number;
    /**
     * Unique ID for the **historic** audit
     * @type {number}
     * @memberof DrugAuditHistoryInfo
     */
    hid: number;
    /**
     * 
     * @type {StaffLink}
     * @memberof DrugAuditHistoryInfo
     */
    modifiedAuthor: StaffLink;
    /**
     * Linux epoch for when this historic version was originally created
     * @type {number}
     * @memberof DrugAuditHistoryInfo
     */
    dateModified: number;
    /**
     * 
     * @type {StaffLink}
     * @memberof DrugAuditHistoryInfo
     */
    reportingManager: StaffLink;
    /**
     * Comments attached to this specific historic version
     * @type {string}
     * @memberof DrugAuditHistoryInfo
     */
    comments?: string;
}

/**
 * Historic versions available for an audit
 * @export
 * @interface DrugAuditHistoryOverview
 */
export interface DrugAuditHistoryOverview {
    /**
     * 
     * @type {Array<DrugAuditHistoryInfo>}
     * @memberof DrugAuditHistoryOverview
     */
    results?: Array<DrugAuditHistoryInfo>;
}

/**
 * Overview for a drug audit, for listing purposes. Doesn't include bulky information
 * @export
 * @interface DrugAuditListEntry
 */
export interface DrugAuditListEntry {
    /**
     * ID of the audit itself
     * @type {number}
     * @memberof DrugAuditListEntry
     */
    auditId: number;
    /**
     * Date the audit was first created in the system, prior to updates
     * @type {number}
     * @memberof DrugAuditListEntry
     */
    dateCreated: number;
    /**
     * Date the audit was last modified
     * @type {number}
     * @memberof DrugAuditListEntry
     */
    dateModified: number;
    /**
     * 
     * @type {StaffLink}
     * @memberof DrugAuditListEntry
     */
    originalAuthor: StaffLink;
    /**
     * 
     * @type {StaffLink}
     * @memberof DrugAuditListEntry
     */
    modifiedAuthor: StaffLink;
    /**
     * 
     * @type {StaffLink}
     * @memberof DrugAuditListEntry
     */
    reportingManager: StaffLink;
    /**
     * ID of the location this audit is for
     * @type {number}
     * @memberof DrugAuditListEntry
     */
    locationId: number;
    /**
     * Name of the location this audit is for
     * @type {string}
     * @memberof DrugAuditListEntry
     */
    locationName: string;
    /**
     * 
     * @type {DrugAuditType}
     * @memberof DrugAuditListEntry
     */
    type: DrugAuditType;
    /**
     * Any applicable comments associated with the audit
     * @type {any}
     * @memberof DrugAuditListEntry
     */
    comments?: any;
}

/**
 * Request to get a list of drug audits that meet criteria
 * @export
 * @interface DrugAuditPagedRequest
 */
export interface DrugAuditPagedRequest {
    /**
     * Filter to a specific reporting manager, StaffId of the manager
     * @type {string}
     * @memberof DrugAuditPagedRequest
     */
    reportingManagerId?: string;
    /**
     * 
     * @type {DateRange}
     * @memberof DrugAuditPagedRequest
     */
    createdDate?: DateRange;
    /**
     * 
     * @type {DateRange}
     * @memberof DrugAuditPagedRequest
     */
    modifiedDate?: DateRange;
    /**
     * ID of the location for the audit
     * @type {number}
     * @memberof DrugAuditPagedRequest
     */
    locationId?: number;
    /**
     * 
     * @type {DrugAuditType}
     * @memberof DrugAuditPagedRequest
     */
    type?: DrugAuditType;
    /**
     * Page to fetch, starting from 0
     * @type {number}
     * @memberof DrugAuditPagedRequest
     */
    pageNum?: number;
    /**
     * Maximum number of entries to return for a single page
     * @type {number}
     * @memberof DrugAuditPagedRequest
     */
    numPerPage?: number;
}

/**
 * Response for listing audits
 * @export
 * @interface DrugAuditPagedResponse
 */
export interface DrugAuditPagedResponse {
    /**
     * Filter to a specific reporting manager, StaffId of the manager
     * @type {string}
     * @memberof DrugAuditPagedResponse
     */
    reportingManagerId?: string;
    /**
     * 
     * @type {DateRange}
     * @memberof DrugAuditPagedResponse
     */
    createdDate?: DateRange;
    /**
     * 
     * @type {DateRange}
     * @memberof DrugAuditPagedResponse
     */
    modifiedDate?: DateRange;
    /**
     * ID of the location for the audit
     * @type {number}
     * @memberof DrugAuditPagedResponse
     */
    locationId?: number;
    /**
     * 
     * @type {DrugAuditType}
     * @memberof DrugAuditPagedResponse
     */
    type?: DrugAuditType;
    /**
     * Page to fetch, starting from 0
     * @type {number}
     * @memberof DrugAuditPagedResponse
     */
    pageNum?: number;
    /**
     * Maximum number of entries to return for a single page
     * @type {number}
     * @memberof DrugAuditPagedResponse
     */
    numPerPage?: number;
    /**
     * Total number of matching audits in the DB
     * @type {number}
     * @memberof DrugAuditPagedResponse
     */
    totalResults: number;
    /**
     * Matching audits for the page
     * @type {Array<DrugAuditListEntry>}
     * @memberof DrugAuditPagedResponse
     */
    results: Array<DrugAuditListEntry>;
}

/**
 * The type of drug audit that occurred
 * @export
 * @enum {string}
 */
export enum DrugAuditType {
    WeeklyDrugPackAudit = 'weeklyDrugPackAudit',
    ControlledDrugAudit = 'controlledDrugAudit',
    None = 'none'
}

/**
 * A collection of drugs identified by a batch number. Drugs will always be linked to a batch and is the most specific form of identification for a specific drug. Within a batch, quantities are monitored.
 * @export
 * @interface DrugBatch
 */
export interface DrugBatch extends DrugBatchBasic {
    /**
     * All of the movements of drugs within this batch, eg. to/from drug packs
     * @type {Array<DrugBatchMovement>}
     * @memberof DrugBatch
     */
    movements: Array<DrugBatchMovement>;
    /**
     * All of times a drug was administered within this batch
     * @type {Array<DrugBatchAdministration>}
     * @memberof DrugBatch
     */
    administrations: Array<DrugBatchAdministration>;
    /**
     * All of times a drug was disposed within this batch
     * @type {Array<DrugBatchDisposal>}
     * @memberof DrugBatch
     */
    disposals: Array<DrugBatchDisposal>;
}

/**
 * 
 * @export
 * @interface DrugBatchAdministration
 */
export interface DrugBatchAdministration extends DrugBatchAdministrationBase {
    /**
     * Identifier for this administration
     * @type {string}
     * @memberof DrugBatchAdministration
     */
    id: string;
    /**
     * All of the previous iterations of this record
     * @type {Array<DrugBatchAdministrationChangeRecord>}
     * @memberof DrugBatchAdministration
     */
    history?: Array<DrugBatchAdministrationChangeRecord>;
}

/**
 * A record of a time a drug was administered within this batch
 * @export
 * @interface DrugBatchAdministrationBase
 */
export interface DrugBatchAdministrationBase {
    /**
     * ID of the user that performed the action
     * @type {string}
     * @memberof DrugBatchAdministrationBase
     */
    staffId?: string;
    /**
     * Real name of the staff member, should the staff member be deleted
     * @type {string}
     * @memberof DrugBatchAdministrationBase
     */
    staffName?: string;
    /**
     * ID of the witness that saw the administration
     * @type {string}
     * @memberof DrugBatchAdministrationBase
     */
    witnessId?: string;
    /**
     * Real name of the witness, should the staff member be deleted
     * @type {string}
     * @memberof DrugBatchAdministrationBase
     */
    witnessName?: string;
    /**
     * CAD number?
     * @type {string}
     * @memberof DrugBatchAdministrationBase
     */
    cadNumber?: string;
    /**
     * The number of drugs that were administered
     * @type {number}
     * @memberof DrugBatchAdministrationBase
     */
    qty: number;
    /**
     * Optional ID of the drug pack this drug was administered from
     * @type {number}
     * @memberof DrugBatchAdministrationBase
     */
    drugPackId?: number;
    /**
     * Optional dosage information
     * @type {string}
     * @memberof DrugBatchAdministrationBase
     */
    dosage?: string;
    /**
     * Optional wastage information
     * @type {string}
     * @memberof DrugBatchAdministrationBase
     */
    wastage?: string;
    /**
     * 
     * @type {DrugAdministrationMethod}
     * @memberof DrugBatchAdministrationBase
     */
    routeAdministration: DrugAdministrationMethod;
    /**
     * Whether this administration has been QA checked
     * @type {boolean}
     * @memberof DrugBatchAdministrationBase
     */
    qa: boolean;
    /**
     * Linux Epoch for when the action occurred
     * @type {number}
     * @memberof DrugBatchAdministrationBase
     */
    date: number;
    /**
     * Optional comments if the user so desires
     * @type {string}
     * @memberof DrugBatchAdministrationBase
     */
    comments?: string;
    /**
     * 
     * @type {GrsLocation}
     * @memberof DrugBatchAdministrationBase
     */
    grsLocation?: GrsLocation;
}

/**
 * Original value for an administration prior to modification. The next record in the history (or the real record if no history) is the new updated record that the changedByStaffId user created.
 * @export
 * @interface DrugBatchAdministrationChangeRecord
 */
export interface DrugBatchAdministrationChangeRecord extends DrugBatchAdministrationBase {
    /**
     * Date this record was changed
     * @type {number}
     * @memberof DrugBatchAdministrationChangeRecord
     */
    changedDate: number;
    /**
     * ID of the staff member that changed this record
     * @type {string}
     * @memberof DrugBatchAdministrationChangeRecord
     */
    changedByStaffId: string;
    /**
     * Name of the staff member that changed this record
     * @type {string}
     * @memberof DrugBatchAdministrationChangeRecord
     */
    changedByStaffName: string;
}

/**
 * Reduced view of DrugBatch of only the core 'small' fields
 * @export
 * @interface DrugBatchBasic
 */
export interface DrugBatchBasic {
    /**
     * Unique ID for this batch
     * @type {number}
     * @memberof DrugBatchBasic
     */
    id: number;
    /**
     * Batch number on the label. This combined with the name is guaranteed unique.
     * @type {string}
     * @memberof DrugBatchBasic
     */
    batchNumber: string;
    /**
     * Name of the drug, for example 'Paracetamol'
     * @type {string}
     * @memberof DrugBatchBasic
     */
    name: string;
    /**
     * The total quantity of drugs (pills, vials, etc.) in the batch
     * @type {number}
     * @memberof DrugBatchBasic
     */
    qty: number;
    /**
     * 
     * @type {DrugType}
     * @memberof DrugBatchBasic
     */
    type: DrugType;
    /**
     * Linux epoch for when the batch was created
     * @type {number}
     * @memberof DrugBatchBasic
     */
    creationDate: number;
    /**
     * Linux epoch for when the drugs expire
     * @type {number}
     * @memberof DrugBatchBasic
     */
    expiryDate: number;
    /**
     * The maximum number of drugs left in-stock that will trigger a low stock alert
     * @type {number}
     * @memberof DrugBatchBasic
     */
    lowStockQty: number;
}

/**
 * A record of a time a drug was disposed of within this batch
 * @export
 * @interface DrugBatchDisposal
 */
export interface DrugBatchDisposal {
    /**
     * Identifier for this disposal
     * @type {string}
     * @memberof DrugBatchDisposal
     */
    id: string;
    /**
     * ID of the user that performed the action
     * @type {string}
     * @memberof DrugBatchDisposal
     */
    staffId: string;
    /**
     * Real name of the staff member, should the staff member be deleted
     * @type {string}
     * @memberof DrugBatchDisposal
     */
    staffName: string;
    /**
     * ID of the witness that saw the administration
     * @type {string}
     * @memberof DrugBatchDisposal
     */
    witnessId?: string;
    /**
     * Real name of the witness, should the staff member be deleted
     * @type {string}
     * @memberof DrugBatchDisposal
     */
    witnessName?: string;
    /**
     * Whether this drug was explicitly denatured as part of disposal
     * @type {boolean}
     * @memberof DrugBatchDisposal
     */
    denatured: boolean;
    /**
     * Whether the drug was broken instead of properly disposed of
     * @type {boolean}
     * @memberof DrugBatchDisposal
     */
    broken: boolean;
    /**
     * The number of drugs that were disposed of
     * @type {number}
     * @memberof DrugBatchDisposal
     */
    qty: number;
    /**
     * Optional ID of the drug pack this drug was disposed from
     * @type {number}
     * @memberof DrugBatchDisposal
     */
    drugPackId?: number;
    /**
     * Linux Epoch for when the action occurred
     * @type {number}
     * @memberof DrugBatchDisposal
     */
    date: number;
    /**
     * Optional comments if the user so desires
     * @type {string}
     * @memberof DrugBatchDisposal
     */
    comments?: string;
    /**
     * 
     * @type {GrsLocation}
     * @memberof DrugBatchDisposal
     */
    grsLocation?: GrsLocation;
}

/**
 * A record of a specific movement of drugs within this batch from one location to another
 * @export
 * @interface DrugBatchMovement
 */
export interface DrugBatchMovement {
    /**
     * Identifier for this movement
     * @type {string}
     * @memberof DrugBatchMovement
     */
    id: string;
    /**
     * ID of the user that performed the action
     * @type {string}
     * @memberof DrugBatchMovement
     */
    staffId: string;
    /**
     * Real name of the staff member, should the staff member be deleted
     * @type {string}
     * @memberof DrugBatchMovement
     */
    staffName: string;
    /**
     * Linux Epoch for when the action occurred
     * @type {number}
     * @memberof DrugBatchMovement
     */
    date: number;
    /**
     * The number of drugs that were moved
     * @type {number}
     * @memberof DrugBatchMovement
     */
    qty: number;
    /**
     * The drug pack these drugs were moved FROM, NULL/missing if from the main inventory store
     * @type {number}
     * @memberof DrugBatchMovement
     */
    fromDrugPackId?: number;
    /**
     * The drug pack these drugs were moved TO, NULL/missing if to the main inventory store
     * @type {number}
     * @memberof DrugBatchMovement
     */
    toDrugPackId?: number;
    /**
     * Optional comments if the user so desires
     * @type {string}
     * @memberof DrugBatchMovement
     */
    comments?: string;
}

/**
 * Ordering for DrugBatch lists
 * @export
 * @enum {string}
 */
export enum DrugBatchOrder {
    CreationDateAscending = 'CreationDateAscending',
    CreationDateDescending = 'CreationDateDescending',
    ExpiryDateAscending = 'ExpiryDateAscending',
    ExpiryDateDescending = 'ExpiryDateDescending',
    DrugNameAscending = 'DrugNameAscending',
    DrugNameDescending = 'DrugNameDescending'
}

/**
 * Request a page of DrugBatch records
 * @export
 * @interface DrugBatchPageRequest
 */
export interface DrugBatchPageRequest {
    /**
     * Page to get, starting from 0
     * @type {number}
     * @memberof DrugBatchPageRequest
     */
    pageNum: number;
    /**
     * Maximum number of records to return
     * @type {number}
     * @memberof DrugBatchPageRequest
     */
    numPerPage: number;
    /**
     * 
     * @type {DrugType}
     * @memberof DrugBatchPageRequest
     */
    type?: DrugType;
    /**
     * 
     * @type {StringSearch}
     * @memberof DrugBatchPageRequest
     */
    drugName?: StringSearch;
    /**
     * 
     * @type {StringSearch}
     * @memberof DrugBatchPageRequest
     */
    batchNumber?: StringSearch;
    /**
     * Linux epoch of the minimum date for the creation date
     * @type {number}
     * @memberof DrugBatchPageRequest
     */
    creationDateMinInclusive?: number;
    /**
     * Linux epoch of the maximum date for the creation date
     * @type {number}
     * @memberof DrugBatchPageRequest
     */
    creationDateMaxExclusive?: number;
    /**
     * Linux epoch of the minimum date for the expiry date
     * @type {number}
     * @memberof DrugBatchPageRequest
     */
    expiryDateMinInclusive?: number;
    /**
     * Linux epoch of the maximum date for the expiry date
     * @type {number}
     * @memberof DrugBatchPageRequest
     */
    expiryDateMaxExclusive?: number;
    /**
     * Filter out any batches that have been fully administered and/or disposed of. Defaults to true.
     * @type {boolean}
     * @memberof DrugBatchPageRequest
     */
    ignoreFullyDisposed?: boolean;
    /**
     * 
     * @type {DrugBatchOrder}
     * @memberof DrugBatchPageRequest
     */
    sortOrder?: DrugBatchOrder;
}

/**
 * Response for a page of DrugBatch records
 * @export
 * @interface DrugBatchPageResponse
 */
export interface DrugBatchPageResponse {
    /**
     * Total number of matching entries in the DB
     * @type {number}
     * @memberof DrugBatchPageResponse
     */
    totalCount?: number;
    /**
     * Found drug batches
     * @type {Array<DrugBatch>}
     * @memberof DrugBatchPageResponse
     */
    results: Array<DrugBatch>;
    /**
     * Page to get, starting from 0
     * @type {number}
     * @memberof DrugBatchPageResponse
     */
    pageNum: number;
    /**
     * Maximum number of records to return
     * @type {number}
     * @memberof DrugBatchPageResponse
     */
    numPerPage: number;
    /**
     * 
     * @type {DrugType}
     * @memberof DrugBatchPageResponse
     */
    type?: DrugType;
    /**
     * 
     * @type {StringSearch}
     * @memberof DrugBatchPageResponse
     */
    drugName?: StringSearch;
    /**
     * 
     * @type {StringSearch}
     * @memberof DrugBatchPageResponse
     */
    batchNumber?: StringSearch;
    /**
     * Linux epoch of the minimum date for the creation date
     * @type {number}
     * @memberof DrugBatchPageResponse
     */
    creationDateMinInclusive?: number;
    /**
     * Linux epoch of the maximum date for the creation date
     * @type {number}
     * @memberof DrugBatchPageResponse
     */
    creationDateMaxExclusive?: number;
    /**
     * Linux epoch of the minimum date for the expiry date
     * @type {number}
     * @memberof DrugBatchPageResponse
     */
    expiryDateMinInclusive?: number;
    /**
     * Linux epoch of the maximum date for the expiry date
     * @type {number}
     * @memberof DrugBatchPageResponse
     */
    expiryDateMaxExclusive?: number;
    /**
     * Filter out any batches that have been fully administered and/or disposed of. Defaults to true.
     * @type {boolean}
     * @memberof DrugBatchPageResponse
     */
    ignoreFullyDisposed?: boolean;
    /**
     * 
     * @type {DrugBatchOrder}
     * @memberof DrugBatchPageResponse
     */
    sortOrder: DrugBatchOrder;
}

/**
 * Pack containing one or more drugs, used to transport drugs within vehicles
 * @export
 * @interface DrugPack
 */
export interface DrugPack {
    /**
     * Unique ID for this pack
     * @type {number}
     * @memberof DrugPack
     */
    id: number;
    /**
     * The category this pack is within
     * @type {number}
     * @memberof DrugPack
     */
    categoryId: number;
    /**
     * Unique name for this pack
     * @type {string}
     * @memberof DrugPack
     */
    name: string;
    /**
     * Unique number on the seal for this pack
     * @type {string}
     * @memberof DrugPack
     */
    sealNumber: string;
}

/**
 * Category for drug packs
 * @export
 * @interface DrugPackCategory
 */
export interface DrugPackCategory {
    /**
     * Unique ID for this category
     * @type {number}
     * @memberof DrugPackCategory
     */
    id: number;
    /**
     * Unique name for this category
     * @type {string}
     * @memberof DrugPackCategory
     */
    name: string;
}

/**
 * Single drug record for a drug within a pack
 * @export
 * @interface DrugPackDrug
 */
export interface DrugPackDrug {
    /**
     * ID of the drug pack this drug is within
     * @type {number}
     * @memberof DrugPackDrug
     */
    drugPackId: number;
    /**
     * ID of the batch the drugs come from
     * @type {number}
     * @memberof DrugPackDrug
     */
    drugBatchId: number;
    /**
     * The number of drugs from the batch that are in this pack
     * @type {number}
     * @memberof DrugPackDrug
     */
    qty: number;
}

/**
 * Drug within a pack, including batch details
 * @export
 * @interface DrugPackDrugWithBatch
 */
export interface DrugPackDrugWithBatch extends DrugPackDrug {
    /**
     * 
     * @type {DrugBatchBasic}
     * @memberof DrugPackDrugWithBatch
     */
    batchInfo?: DrugBatchBasic;
}

/**
 * DrugPack but also with the contents too
 * @export
 * @interface DrugPackWithContents
 */
export interface DrugPackWithContents extends DrugPack {
    /**
     * Drugs within this pack
     * @type {Array<DrugPackDrugWithBatch>}
     * @memberof DrugPackWithContents
     */
    drugs: Array<DrugPackDrugWithBatch>;
}

/**
 * Any special criteria for the drug
 * @export
 * @enum {string}
 */
export enum DrugType {
    Normal = 'Normal',
    Controlled = 'Controlled'
}

/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}

/**
 * Filters for generic report, at *least* one date range must be specified
 * @export
 * @interface GeneralReportRequest
 */
export interface GeneralReportRequest {
    /**
     * 
     * @type {DateRange}
     * @memberof GeneralReportRequest
     */
    creationDate?: DateRange;
    /**
     * 
     * @type {DateRange}
     * @memberof GeneralReportRequest
     */
    expiryDate?: DateRange;
    /**
     * 
     * @type {DateRange}
     * @memberof GeneralReportRequest
     */
    administrationDate?: DateRange;
    /**
     * 
     * @type {DateRange}
     * @memberof GeneralReportRequest
     */
    disposalDate?: DateRange;
}

/**
 * General report data
 * @export
 * @interface GeneralReportResponse
 */
export interface GeneralReportResponse {
    /**
     * 
     * @type {DateRange}
     * @memberof GeneralReportResponse
     */
    creationDate?: DateRange;
    /**
     * 
     * @type {DateRange}
     * @memberof GeneralReportResponse
     */
    expiryDate?: DateRange;
    /**
     * 
     * @type {DateRange}
     * @memberof GeneralReportResponse
     */
    administrationDate?: DateRange;
    /**
     * 
     * @type {DateRange}
     * @memberof GeneralReportResponse
     */
    disposalDate?: DateRange;
    /**
     * The drug batches that match the search criteria
     * @type {Array<DrugBatch>}
     * @memberof GeneralReportResponse
     */
    drugBatches: Array<DrugBatch>;
    /**
     * The packs that are referenced by the drug batches
     * @type {Array<DrugPack>}
     * @memberof GeneralReportResponse
     */
    drugPacks: Array<DrugPack>;
    /**
     * The categories that are referenced by the drug packs
     * @type {Array<DrugPackCategory>}
     * @memberof GeneralReportResponse
     */
    drugPackCategories: Array<DrugPackCategory>;
}

/**
 * A GRS event reference with a little extra location info
 * @export
 * @interface GrsLocation
 */
export interface GrsLocation {
    /**
     * Unique ID of the GRS event
     * @type {number}
     * @memberof GrsLocation
     */
    entryId: number;
    /**
     * Name of the event for reference should the event be deleted and can't be fetched
     * @type {string}
     * @memberof GrsLocation
     */
    entryName: string;
    /**
     * ID of the venue the event was held at
     * @type {number}
     * @memberof GrsLocation
     */
    venueId: number;
    /**
     * Name of the venue, should it not be fetchable from the DB
     * @type {string}
     * @memberof GrsLocation
     */
    venueName: string;
    /**
     * Type of calendar, useful reference
     * @type {string}
     * @memberof GrsLocation
     */
    calendarType?: string;
}

/**
 * Location for an audit to have taken place, or the place the audit is targeted
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * Unique ID for the location
     * @type {number}
     * @memberof Location
     */
    id: number;
    /**
     * Name of the location
     * @type {string}
     * @memberof Location
     */
    name: string;
}

/**
 * Move drugs from one place to another
 * @export
 * @interface MoveDrugsRequest
 */
export interface MoveDrugsRequest {
    /**
     * Unique ID of the DrugBatch that contains the drugs
     * @type {number}
     * @memberof MoveDrugsRequest
     */
    drugBatchId: number;
    /**
     * 
     * @type {DrugBatchMovement}
     * @memberof MoveDrugsRequest
     */
    movement: DrugBatchMovement;
}

/**
 * Link to a staff member
 * @export
 * @interface StaffLink
 */
export interface StaffLink {
    /**
     * Unique username / ID for staff member
     * @type {string}
     * @memberof StaffLink
     */
    staffId: string;
    /**
     * Real human name of the staff member
     * @type {string}
     * @memberof StaffLink
     */
    staffName: string;
}

/**
 * Kind of string search to perform
 * @export
 * @enum {string}
 */
export enum StringOperator {
    StartsWith = 'StartsWith',
    EndsWith = 'EndsWith',
    Contains = 'Contains'
}

/**
 * Perform a string matching search on a specific value
 * @export
 * @interface StringSearch
 */
export interface StringSearch {
    /**
     * The search query that must match
     * @type {string}
     * @memberof StringSearch
     */
    searchString: string;
    /**
     * 
     * @type {StringOperator}
     * @memberof StringSearch
     */
    operator: StringOperator;
}

/**
 * Update an administration record in the DB
 * @export
 * @interface UpdateAdministration
 */
export interface UpdateAdministration {
    /**
     * ID of the batch the administration is for
     * @type {number}
     * @memberof UpdateAdministration
     */
    batchId: number;
    /**
     * 
     * @type {DrugBatchAdministration}
     * @memberof UpdateAdministration
     */
    administration: DrugBatchAdministration;
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Administer drugs within a batch
         * @param {AdministerDrugsRequest} [administerDrugsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administerDrugs(administerDrugsRequest?: AdministerDrugsRequest, options: any = {}): RequestArgs {
            const localVarPath = `/drug/batch/administer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdministerDrugsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(administerDrugsRequest || {}) : (administerDrugsRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an audit location from the DB
         * @param {number} id Unique ID for the audit location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditLocation(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAuditLocation.');
            }
            const localVarPath = `/audit/location/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an audit from the DB, will delete all historic versions too
         * @param {number} id Unique ID for the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugAudit(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDrugAudit.');
            }
            const localVarPath = `/audit/drug/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a DrugBatch from the DB, can't be done if there are any drugs in the batch that are in packs, administered, or disposed
         * @param {number} id Unique ID for the drug batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugBatch(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDrugBatch.');
            }
            const localVarPath = `/drug/batch/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a DrugPack from the DB, can't be done if there are any drugs in the pack
         * @param {number} id Unique ID for the drug pack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugPack(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDrugPack.');
            }
            const localVarPath = `/drug/pack/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a DrugPackCategory from the DB, can't be done if there are any drug packs in the category
         * @param {number} id Unique ID for the drug pack category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugPackCategory(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDrugPackCategory.');
            }
            const localVarPath = `/drug/pack/category/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dispose of drugs in a batch
         * @param {DisposeDrugsRequest} [disposeDrugsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disposeDrugs(disposeDrugsRequest?: DisposeDrugsRequest, options: any = {}): RequestArgs {
            const localVarPath = `/drug/batch/dispose`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DisposeDrugsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(disposeDrugsRequest || {}) : (disposeDrugsRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all DrugBatches in the system that are NOT expired and have NOT been fully administered / disposed of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveDrugBatches(options: any = {}): RequestArgs {
            const localVarPath = `/drug/batch/active`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of all audit locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuditLocations(options: any = {}): RequestArgs {
            const localVarPath = `/audit/location/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of all drug pack categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDrugPackCategories(options: any = {}): RequestArgs {
            const localVarPath = `/drug/pack/category/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of all drug packs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDrugPacks(options: any = {}): RequestArgs {
            const localVarPath = `/drug/pack/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific audit location
         * @param {number} id Unique ID for the audit location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLocation(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAuditLocation.');
            }
            const localVarPath = `/audit/location/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific drug audit
         * @param {number} id Unique ID for the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugAudit(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDrugAudit.');
            }
            const localVarPath = `/audit/drug/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a page of drug audits
         * @param {DrugAuditPagedRequest} [drugAuditPagedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugAuditsPaged(drugAuditPagedRequest?: DrugAuditPagedRequest, options: any = {}): RequestArgs {
            const localVarPath = `/audit/drug/paged`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DrugAuditPagedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(drugAuditPagedRequest || {}) : (drugAuditPagedRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific DrugBatch
         * @param {number} id Unique ID for the drug batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugBatch(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDrugBatch.');
            }
            const localVarPath = `/drug/batch/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all of the DrugBatch records in a single pack
         * @param {number} id DrugPack ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugBatchesWithinPack(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDrugBatchesWithinPack.');
            }
            const localVarPath = `/drug/batch/withinpack/{id}/all`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific DrugPack
         * @param {number} id Unique ID for the drug pack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugPack(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDrugPack.');
            }
            const localVarPath = `/drug/pack/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific DrugPackCategory
         * @param {number} id Unique ID for the drug pack category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugPackCategory(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDrugPackCategory.');
            }
            const localVarPath = `/drug/pack/category/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all DrugPacks within a category
         * @param {number} id Category ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugPacksWithinCategory(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDrugPacksWithinCategory.');
            }
            const localVarPath = `/drug/pack/bycategory/{id}/all`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all of the necessary data for a general report
         * @param {GeneralReportRequest} [generalReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralReport(generalReportRequest?: GeneralReportRequest, options: any = {}): RequestArgs {
            const localVarPath = `/report/general`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GeneralReportRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(generalReportRequest || {}) : (generalReportRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id Unique ID for the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricDrugAuditList(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getHistoricDrugAuditList.');
            }
            const localVarPath = `/audit/drug/byid/{id}/historic/list`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id Unique ID for the original audit
         * @param {number} hid Unique ID for the historic audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricDrugAuditList_1(id: number, hid: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getHistoricDrugAuditList_1.');
            }
            // verify required parameter 'hid' is not null or undefined
            if (hid === null || hid === undefined) {
                throw new RequiredError('hid','Required parameter hid was null or undefined when calling getHistoricDrugAuditList_1.');
            }
            const localVarPath = `/audit/drug/byid/{id}/historic/byid/{hid}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"hid"}}`, encodeURIComponent(String(hid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move drugs from one location to another
         * @param {MoveDrugsRequest} [moveDrugsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveDrugs(moveDrugsRequest?: MoveDrugsRequest, options: any = {}): RequestArgs {
            const localVarPath = `/drug/batch/move`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MoveDrugsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(moveDrugsRequest || {}) : (moveDrugsRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a page of DrugBatches that meet the criteria
         * @param {DrugBatchPageRequest} [drugBatchPageRequest] Filter for pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDrugBatches(drugBatchPageRequest?: DrugBatchPageRequest, options: any = {}): RequestArgs {
            const localVarPath = `/drug/batch/paged`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DrugBatchPageRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(drugBatchPageRequest || {}) : (drugBatchPageRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save an audit location to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {Location} [location] Audit location to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuditLocation(location?: Location, options: any = {}): RequestArgs {
            const localVarPath = `/audit/location`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Location" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(location || {}) : (location || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a drug audit to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugAudit} [drugAudit] Audit to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugAudit(drugAudit?: DrugAudit, options: any = {}): RequestArgs {
            const localVarPath = `/audit/drug`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DrugAudit" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(drugAudit || {}) : (drugAudit || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a DrugBatch to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugBatch} [drugBatch] DrugBatch to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugBatch(drugBatch?: DrugBatch, options: any = {}): RequestArgs {
            const localVarPath = `/drug/batch`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DrugBatch" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(drugBatch || {}) : (drugBatch || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a DrugPack to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugPack} [drugPack] DrugPack to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugPack(drugPack?: DrugPack, options: any = {}): RequestArgs {
            const localVarPath = `/drug/pack`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DrugPack" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(drugPack || {}) : (drugPack || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a DrugPackCategory to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugPackCategory} [drugPackCategory] DrugPackCategory to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugPackCategory(drugPackCategory?: DrugPackCategory, options: any = {}): RequestArgs {
            const localVarPath = `/drug/pack/category`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DrugPackCategory" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(drugPackCategory || {}) : (drugPackCategory || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing administration record
         * @param {UpdateAdministration} [updateAdministration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdministration(updateAdministration?: UpdateAdministration, options: any = {}): RequestArgs {
            const localVarPath = `/drug/batch/administer/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateAdministration" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateAdministration || {}) : (updateAdministration || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Administer drugs within a batch
         * @param {AdministerDrugsRequest} [administerDrugsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administerDrugs(administerDrugsRequest?: AdministerDrugsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugBatch> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).administerDrugs(administerDrugsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete an audit location from the DB
         * @param {number} id Unique ID for the audit location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditLocation(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteAuditLocation(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete an audit from the DB, will delete all historic versions too
         * @param {number} id Unique ID for the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugAudit(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteDrugAudit(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete a DrugBatch from the DB, can't be done if there are any drugs in the batch that are in packs, administered, or disposed
         * @param {number} id Unique ID for the drug batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugBatch(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteDrugBatch(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete a DrugPack from the DB, can't be done if there are any drugs in the pack
         * @param {number} id Unique ID for the drug pack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugPack(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteDrugPack(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete a DrugPackCategory from the DB, can't be done if there are any drug packs in the category
         * @param {number} id Unique ID for the drug pack category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugPackCategory(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteDrugPackCategory(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Dispose of drugs in a batch
         * @param {DisposeDrugsRequest} [disposeDrugsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disposeDrugs(disposeDrugsRequest?: DisposeDrugsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugBatch> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).disposeDrugs(disposeDrugsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get all DrugBatches in the system that are NOT expired and have NOT been fully administered / disposed of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveDrugBatches(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DrugBatch>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAllActiveDrugBatches(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Returns a list of all audit locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuditLocations(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Location>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAllAuditLocations(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Returns a list of all drug pack categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDrugPackCategories(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DrugPackCategory>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAllDrugPackCategories(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Returns a list of all drug packs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDrugPacks(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DrugPack>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAllDrugPacks(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific audit location
         * @param {number} id Unique ID for the audit location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLocation(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getAuditLocation(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific drug audit
         * @param {number} id Unique ID for the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugAudit(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugAudit> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDrugAudit(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Returns a page of drug audits
         * @param {DrugAuditPagedRequest} [drugAuditPagedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugAuditsPaged(drugAuditPagedRequest?: DrugAuditPagedRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DrugAuditPagedResponse>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDrugAuditsPaged(drugAuditPagedRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific DrugBatch
         * @param {number} id Unique ID for the drug batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugBatch(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugBatch> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDrugBatch(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get all of the DrugBatch records in a single pack
         * @param {number} id DrugPack ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugBatchesWithinPack(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DrugBatch>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDrugBatchesWithinPack(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific DrugPack
         * @param {number} id Unique ID for the drug pack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugPack(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugPackWithContents> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDrugPack(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific DrugPackCategory
         * @param {number} id Unique ID for the drug pack category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugPackCategory(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugPackCategory> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDrugPackCategory(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get all DrugPacks within a category
         * @param {number} id Category ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugPacksWithinCategory(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DrugPackWithContents>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getDrugPacksWithinCategory(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get all of the necessary data for a general report
         * @param {GeneralReportRequest} [generalReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralReport(generalReportRequest?: GeneralReportRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralReportResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getGeneralReport(generalReportRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @param {number} id Unique ID for the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricDrugAuditList(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugAuditHistoryOverview> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getHistoricDrugAuditList(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @param {number} id Unique ID for the original audit
         * @param {number} hid Unique ID for the historic audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricDrugAuditList_1(id: number, hid: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugAudit> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getHistoricDrugAuditList_1(id, hid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Move drugs from one location to another
         * @param {MoveDrugsRequest} [moveDrugsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveDrugs(moveDrugsRequest?: MoveDrugsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugBatch> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).moveDrugs(moveDrugsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a page of DrugBatches that meet the criteria
         * @param {DrugBatchPageRequest} [drugBatchPageRequest] Filter for pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDrugBatches(drugBatchPageRequest?: DrugBatchPageRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugBatchPageResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).pageDrugBatches(drugBatchPageRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save an audit location to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {Location} [location] Audit location to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuditLocation(location?: Location, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).saveAuditLocation(location, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a drug audit to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugAudit} [drugAudit] Audit to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugAudit(drugAudit?: DrugAudit, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugAudit> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).saveDrugAudit(drugAudit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a DrugBatch to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugBatch} [drugBatch] DrugBatch to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugBatch(drugBatch?: DrugBatch, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugBatch> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).saveDrugBatch(drugBatch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a DrugPack to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugPack} [drugPack] DrugPack to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugPack(drugPack?: DrugPack, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugPack> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).saveDrugPack(drugPack, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a DrugPackCategory to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugPackCategory} [drugPackCategory] DrugPackCategory to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugPackCategory(drugPackCategory?: DrugPackCategory, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugPackCategory> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).saveDrugPackCategory(drugPackCategory, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Update an existing administration record
         * @param {UpdateAdministration} [updateAdministration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdministration(updateAdministration?: UpdateAdministration, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrugBatch> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).updateAdministration(updateAdministration, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Administer drugs within a batch
         * @param {AdministerDrugsRequest} [administerDrugsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administerDrugs(administerDrugsRequest?: AdministerDrugsRequest, options?: any) {
            return DefaultApiFp(configuration).administerDrugs(administerDrugsRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete an audit location from the DB
         * @param {number} id Unique ID for the audit location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuditLocation(id: number, options?: any) {
            return DefaultApiFp(configuration).deleteAuditLocation(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete an audit from the DB, will delete all historic versions too
         * @param {number} id Unique ID for the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugAudit(id: number, options?: any) {
            return DefaultApiFp(configuration).deleteDrugAudit(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a DrugBatch from the DB, can't be done if there are any drugs in the batch that are in packs, administered, or disposed
         * @param {number} id Unique ID for the drug batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugBatch(id: number, options?: any) {
            return DefaultApiFp(configuration).deleteDrugBatch(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a DrugPack from the DB, can't be done if there are any drugs in the pack
         * @param {number} id Unique ID for the drug pack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugPack(id: number, options?: any) {
            return DefaultApiFp(configuration).deleteDrugPack(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a DrugPackCategory from the DB, can't be done if there are any drug packs in the category
         * @param {number} id Unique ID for the drug pack category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDrugPackCategory(id: number, options?: any) {
            return DefaultApiFp(configuration).deleteDrugPackCategory(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Dispose of drugs in a batch
         * @param {DisposeDrugsRequest} [disposeDrugsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disposeDrugs(disposeDrugsRequest?: DisposeDrugsRequest, options?: any) {
            return DefaultApiFp(configuration).disposeDrugs(disposeDrugsRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all DrugBatches in the system that are NOT expired and have NOT been fully administered / disposed of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveDrugBatches(options?: any) {
            return DefaultApiFp(configuration).getAllActiveDrugBatches(options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns a list of all audit locations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuditLocations(options?: any) {
            return DefaultApiFp(configuration).getAllAuditLocations(options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns a list of all drug pack categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDrugPackCategories(options?: any) {
            return DefaultApiFp(configuration).getAllDrugPackCategories(options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns a list of all drug packs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDrugPacks(options?: any) {
            return DefaultApiFp(configuration).getAllDrugPacks(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific audit location
         * @param {number} id Unique ID for the audit location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditLocation(id: number, options?: any) {
            return DefaultApiFp(configuration).getAuditLocation(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific drug audit
         * @param {number} id Unique ID for the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugAudit(id: number, options?: any) {
            return DefaultApiFp(configuration).getDrugAudit(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns a page of drug audits
         * @param {DrugAuditPagedRequest} [drugAuditPagedRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugAuditsPaged(drugAuditPagedRequest?: DrugAuditPagedRequest, options?: any) {
            return DefaultApiFp(configuration).getDrugAuditsPaged(drugAuditPagedRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific DrugBatch
         * @param {number} id Unique ID for the drug batch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugBatch(id: number, options?: any) {
            return DefaultApiFp(configuration).getDrugBatch(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all of the DrugBatch records in a single pack
         * @param {number} id DrugPack ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugBatchesWithinPack(id: number, options?: any) {
            return DefaultApiFp(configuration).getDrugBatchesWithinPack(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific DrugPack
         * @param {number} id Unique ID for the drug pack
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugPack(id: number, options?: any) {
            return DefaultApiFp(configuration).getDrugPack(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific DrugPackCategory
         * @param {number} id Unique ID for the drug pack category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugPackCategory(id: number, options?: any) {
            return DefaultApiFp(configuration).getDrugPackCategory(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all DrugPacks within a category
         * @param {number} id Category ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDrugPacksWithinCategory(id: number, options?: any) {
            return DefaultApiFp(configuration).getDrugPacksWithinCategory(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all of the necessary data for a general report
         * @param {GeneralReportRequest} [generalReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralReport(generalReportRequest?: GeneralReportRequest, options?: any) {
            return DefaultApiFp(configuration).getGeneralReport(generalReportRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id Unique ID for the audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricDrugAuditList(id: number, options?: any) {
            return DefaultApiFp(configuration).getHistoricDrugAuditList(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id Unique ID for the original audit
         * @param {number} hid Unique ID for the historic audit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoricDrugAuditList_1(id: number, hid: number, options?: any) {
            return DefaultApiFp(configuration).getHistoricDrugAuditList_1(id, hid, options)(axios, basePath);
        },
        /**
         * 
         * @summary Move drugs from one location to another
         * @param {MoveDrugsRequest} [moveDrugsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveDrugs(moveDrugsRequest?: MoveDrugsRequest, options?: any) {
            return DefaultApiFp(configuration).moveDrugs(moveDrugsRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a page of DrugBatches that meet the criteria
         * @param {DrugBatchPageRequest} [drugBatchPageRequest] Filter for pages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageDrugBatches(drugBatchPageRequest?: DrugBatchPageRequest, options?: any) {
            return DefaultApiFp(configuration).pageDrugBatches(drugBatchPageRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save an audit location to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {Location} [location] Audit location to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuditLocation(location?: Location, options?: any) {
            return DefaultApiFp(configuration).saveAuditLocation(location, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a drug audit to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugAudit} [drugAudit] Audit to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugAudit(drugAudit?: DrugAudit, options?: any) {
            return DefaultApiFp(configuration).saveDrugAudit(drugAudit, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a DrugBatch to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugBatch} [drugBatch] DrugBatch to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugBatch(drugBatch?: DrugBatch, options?: any) {
            return DefaultApiFp(configuration).saveDrugBatch(drugBatch, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a DrugPack to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugPack} [drugPack] DrugPack to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugPack(drugPack?: DrugPack, options?: any) {
            return DefaultApiFp(configuration).saveDrugPack(drugPack, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a DrugPackCategory to the DB. ID 0 is an insert, greater than 0 will update.
         * @param {DrugPackCategory} [drugPackCategory] DrugPackCategory to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDrugPackCategory(drugPackCategory?: DrugPackCategory, options?: any) {
            return DefaultApiFp(configuration).saveDrugPackCategory(drugPackCategory, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update an existing administration record
         * @param {UpdateAdministration} [updateAdministration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAdministration(updateAdministration?: UpdateAdministration, options?: any) {
            return DefaultApiFp(configuration).updateAdministration(updateAdministration, options)(axios, basePath);
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Administer drugs within a batch
     * @param {AdministerDrugsRequest} [administerDrugsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public administerDrugs(administerDrugsRequest?: AdministerDrugsRequest, options?: any) {
        return DefaultApiFp(this.configuration).administerDrugs(administerDrugsRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete an audit location from the DB
     * @param {number} id Unique ID for the audit location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteAuditLocation(id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteAuditLocation(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete an audit from the DB, will delete all historic versions too
     * @param {number} id Unique ID for the audit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDrugAudit(id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteDrugAudit(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a DrugBatch from the DB, can't be done if there are any drugs in the batch that are in packs, administered, or disposed
     * @param {number} id Unique ID for the drug batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDrugBatch(id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteDrugBatch(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a DrugPack from the DB, can't be done if there are any drugs in the pack
     * @param {number} id Unique ID for the drug pack
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDrugPack(id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteDrugPack(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a DrugPackCategory from the DB, can't be done if there are any drug packs in the category
     * @param {number} id Unique ID for the drug pack category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDrugPackCategory(id: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteDrugPackCategory(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Dispose of drugs in a batch
     * @param {DisposeDrugsRequest} [disposeDrugsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public disposeDrugs(disposeDrugsRequest?: DisposeDrugsRequest, options?: any) {
        return DefaultApiFp(this.configuration).disposeDrugs(disposeDrugsRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all DrugBatches in the system that are NOT expired and have NOT been fully administered / disposed of.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllActiveDrugBatches(options?: any) {
        return DefaultApiFp(this.configuration).getAllActiveDrugBatches(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns a list of all audit locations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllAuditLocations(options?: any) {
        return DefaultApiFp(this.configuration).getAllAuditLocations(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns a list of all drug pack categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllDrugPackCategories(options?: any) {
        return DefaultApiFp(this.configuration).getAllDrugPackCategories(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns a list of all drug packs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllDrugPacks(options?: any) {
        return DefaultApiFp(this.configuration).getAllDrugPacks(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific audit location
     * @param {number} id Unique ID for the audit location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAuditLocation(id: number, options?: any) {
        return DefaultApiFp(this.configuration).getAuditLocation(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific drug audit
     * @param {number} id Unique ID for the audit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDrugAudit(id: number, options?: any) {
        return DefaultApiFp(this.configuration).getDrugAudit(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns a page of drug audits
     * @param {DrugAuditPagedRequest} [drugAuditPagedRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDrugAuditsPaged(drugAuditPagedRequest?: DrugAuditPagedRequest, options?: any) {
        return DefaultApiFp(this.configuration).getDrugAuditsPaged(drugAuditPagedRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific DrugBatch
     * @param {number} id Unique ID for the drug batch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDrugBatch(id: number, options?: any) {
        return DefaultApiFp(this.configuration).getDrugBatch(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all of the DrugBatch records in a single pack
     * @param {number} id DrugPack ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDrugBatchesWithinPack(id: number, options?: any) {
        return DefaultApiFp(this.configuration).getDrugBatchesWithinPack(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific DrugPack
     * @param {number} id Unique ID for the drug pack
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDrugPack(id: number, options?: any) {
        return DefaultApiFp(this.configuration).getDrugPack(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific DrugPackCategory
     * @param {number} id Unique ID for the drug pack category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDrugPackCategory(id: number, options?: any) {
        return DefaultApiFp(this.configuration).getDrugPackCategory(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all DrugPacks within a category
     * @param {number} id Category ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDrugPacksWithinCategory(id: number, options?: any) {
        return DefaultApiFp(this.configuration).getDrugPacksWithinCategory(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all of the necessary data for a general report
     * @param {GeneralReportRequest} [generalReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGeneralReport(generalReportRequest?: GeneralReportRequest, options?: any) {
        return DefaultApiFp(this.configuration).getGeneralReport(generalReportRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id Unique ID for the audit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHistoricDrugAuditList(id: number, options?: any) {
        return DefaultApiFp(this.configuration).getHistoricDrugAuditList(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id Unique ID for the original audit
     * @param {number} hid Unique ID for the historic audit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getHistoricDrugAuditList_1(id: number, hid: number, options?: any) {
        return DefaultApiFp(this.configuration).getHistoricDrugAuditList_1(id, hid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Move drugs from one location to another
     * @param {MoveDrugsRequest} [moveDrugsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public moveDrugs(moveDrugsRequest?: MoveDrugsRequest, options?: any) {
        return DefaultApiFp(this.configuration).moveDrugs(moveDrugsRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a page of DrugBatches that meet the criteria
     * @param {DrugBatchPageRequest} [drugBatchPageRequest] Filter for pages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pageDrugBatches(drugBatchPageRequest?: DrugBatchPageRequest, options?: any) {
        return DefaultApiFp(this.configuration).pageDrugBatches(drugBatchPageRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save an audit location to the DB. ID 0 is an insert, greater than 0 will update.
     * @param {Location} [location] Audit location to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveAuditLocation(location?: Location, options?: any) {
        return DefaultApiFp(this.configuration).saveAuditLocation(location, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a drug audit to the DB. ID 0 is an insert, greater than 0 will update.
     * @param {DrugAudit} [drugAudit] Audit to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveDrugAudit(drugAudit?: DrugAudit, options?: any) {
        return DefaultApiFp(this.configuration).saveDrugAudit(drugAudit, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a DrugBatch to the DB. ID 0 is an insert, greater than 0 will update.
     * @param {DrugBatch} [drugBatch] DrugBatch to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveDrugBatch(drugBatch?: DrugBatch, options?: any) {
        return DefaultApiFp(this.configuration).saveDrugBatch(drugBatch, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a DrugPack to the DB. ID 0 is an insert, greater than 0 will update.
     * @param {DrugPack} [drugPack] DrugPack to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveDrugPack(drugPack?: DrugPack, options?: any) {
        return DefaultApiFp(this.configuration).saveDrugPack(drugPack, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a DrugPackCategory to the DB. ID 0 is an insert, greater than 0 will update.
     * @param {DrugPackCategory} [drugPackCategory] DrugPackCategory to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveDrugPackCategory(drugPackCategory?: DrugPackCategory, options?: any) {
        return DefaultApiFp(this.configuration).saveDrugPackCategory(drugPackCategory, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update an existing administration record
     * @param {UpdateAdministration} [updateAdministration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateAdministration(updateAdministration?: UpdateAdministration, options?: any) {
        return DefaultApiFp(this.configuration).updateAdministration(updateAdministration, options)(this.axios, this.basePath);
    }

}

