import {ReportsApi} from "../../api/im";

export default class ImApiModel {
    private static instance: ImApiModel;
    private static reportsApi: ReportsApi;

    public static getInstance(): ImApiModel {
        if (!ImApiModel.instance) {
            ImApiModel.instance = new ImApiModel();
        }
        return ImApiModel.instance;
    }

    public static getReportsApi(): ReportsApi {
        if (!ImApiModel.reportsApi) {
            ImApiModel.reportsApi = new ReportsApi({
                basePath: process.env.REACT_APP_IM_API
            });
        }

        return ImApiModel.reportsApi;
    }
}
