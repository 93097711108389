import {StoreServiceData} from "store-fetch-wrappers";
import {StaffWageReportResponse} from "../../../../api/grs";
import {createStoreState} from "store-fetch-wrappers/dist";

export const STAFF_WAGE_REPORT_STORE_STATE = createStoreState("staff_wage_report");

interface StaffWageReportLoading extends StoreServiceData<StaffWageReportResponse> {
    type: typeof STAFF_WAGE_REPORT_STORE_STATE.LOADING;
}
interface StaffWageReportError extends StoreServiceData<StaffWageReportResponse> {
    type: typeof STAFF_WAGE_REPORT_STORE_STATE.ERROR;
}
interface StaffWageReportSuccess extends StoreServiceData<StaffWageReportResponse> {
    type: typeof STAFF_WAGE_REPORT_STORE_STATE.SUCCESS;
}

export type StaffWageReportDispatchTypes =
    | StaffWageReportSuccess
    | StaffWageReportError
    | StaffWageReportLoading;
