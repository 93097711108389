import React from "react";
import useWindowSize from "../../Hooks/useWindowResize";

function QrCodeStep1() {
    const {width} = useWindowSize();

    function getStartOfSentence() {
        if (width > 768) {
            return "Scan the QR code on the right";
        }
        return "Scan the QR code above";
    }
    return (
        <React.Fragment>
            <p className="mt-4">
                <b className="header-font">Step 1</b>
            </p>
            <p>
                {getStartOfSentence()} in your authenticator app of choice. You can download one
                from the App Store for iPhone or the Play Store for Android.
            </p>
        </React.Fragment>
    );
}

export default QrCodeStep1;
