import {UsersApi, ContactApi, AbsenceApi} from "../../api/staff";

export default class StaffApiModel {
    private static instance: StaffApiModel;
    private static usersApi: UsersApi;
    private static contactApi: ContactApi;
    private static absenceApi: AbsenceApi;

    public static getInstance(): StaffApiModel {
        if (!StaffApiModel.instance) {
            StaffApiModel.instance = new StaffApiModel();
        }
        return StaffApiModel.instance;
    }

    public static getUsersApi(): UsersApi {
        if (!StaffApiModel.usersApi) {
            StaffApiModel.usersApi = new UsersApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(mime: string): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel.usersApi;
    }

    public static getContactApi(): ContactApi {
        if (!StaffApiModel.contactApi) {
            StaffApiModel.contactApi = new ContactApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(mime: string): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel.contactApi;
    }

    public static getAbsenceApi(): AbsenceApi {
        if (!StaffApiModel.absenceApi) {
            StaffApiModel.absenceApi = new AbsenceApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(mime: string): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel.absenceApi;
    }
}
