import React from "react";
import useAbsenceStatsContainer from "./Hooks/useAbsenceStats";
import AbsenceStats from "./AbsenceStats";

function AbsenceStatsContainer() {
    const {absenceStatsStore} = useAbsenceStatsContainer();

    return (
        <React.Fragment>
            {absenceStatsStore.data ? <AbsenceStats {...absenceStatsStore.data} /> : null}
        </React.Fragment>
    );
}

export default AbsenceStatsContainer;
