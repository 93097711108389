import React, {Fragment, ReactNode} from "react";

function Card({children, className, wrapperClassName, footer}: Props) {
    return (
        <Fragment>
            <div className={`mc-card ${className || ""}`}>
                <div
                    className={`mc-card-wrapper ${wrapperClassName || ""}`}
                    style={{paddingBottom: footer ? "1rem" : "2rem"}}
                >
                    {children}
                </div>
                {footer && (
                    <Fragment>
                        <div className="card-spacer" />
                        <div className={`mc-card-wrapper ${wrapperClassName || ""}`}>{footer}</div>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}

export default Card;

interface Props {
    className?: string;
    wrapperClassName?: string;
    children: ReactNode;
    footer?: ReactNode;
}
