import React, {useState} from "react";
import {
    NotificationItem,
    SubSystem
} from "../../../../../../store/notificationsHub/actions/NotificationsHubActionTypes";
import {AssignmentState} from "../../../../../../api/grs";
import {useDispatch} from "react-redux";
import {removeNotificationFromList} from "../../../../../../store/notificationsHub/actions/NotificationsHubActions";
import GrsApiModel from "../../../../../../store/apiModel/GrsApiModel";
import {AxiosPromise} from "axios";
import {useUserData} from "../../../../../Hooks/useUserData";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import {showErrorToast, showSuccessToast} from "../../../../../../utils/toastUtils";

const GRSNotificationListItem = (props: NotificationItem) => {
    const dispatch = useDispatch();
    const user = useUserData();
    const [rootDivClassName, setRootDivClassName] = useState<string>("notification-item");
    const [loading, setLoading] = useState<boolean>(false);

    const acceptAssignment = async () => {
        if (!props.assignmentState) return;

        await handleRequest(GrsApiModel.getStaffApi().acceptAssignment(+props.id));
        showSuccessToast("Accepted Assignment");
    };

    const rejectAssignment = async () => {
        if (!props.assignmentState) return;

        await handleRequest(GrsApiModel.getStaffApi().rejectAssignment(+props.id));
        showSuccessToast("Rejected Assignment");
    };

    const acceptBriefing = async () => {
        if (!props.assignmentState) return;
        await handleRequest(GrsApiModel.getStaffApi().acceptBriefing(+props.id));
        showSuccessToast("Accepted Briefing");
    };

    const handleRequest = async (axiosPromise: AxiosPromise<Response>) => {
        try {
            setLoading(true);
            const request = await axiosPromise;
            if (request.status === 401) {
                showErrorToast("Not Authenticated");
                setLoading(false);
                return;
            }
            if (request.status === 403) {
                showErrorToast("Not Authorised");
                setLoading(false);
                return;
            }
            if (request.status === 406) {
                showErrorToast("Validation Error");
                setLoading(false);
                return;
            }
            if (request.status === 200) {
                setTimeout(() => {
                    setLoading(false);
                    setRootDivClassName("notification-item fadeOut");
                    setTimeout(() => {
                        dispatch(removeNotificationFromList(props.id, SubSystem.GRS, user));
                    }, 300);
                }, 500);
            }
        } catch (e: any) {
            showErrorToast(e.response.data.message);
        }
    };

    return (
        <React.Fragment>
            <div className={rootDivClassName}>
                <div className="row ml-0 mr-0 pt-2">
                    <div className="col-sm-1">{props.icon && props.icon}</div>
                    <div className="col-sm-9">
                        <div className="row ml-0 mr-0">
                            <p className="notification-item-title pt-1">{props.title}</p>
                        </div>
                        <div className="row ml-0 mr-0">
                            <div className="row ml-0 mr-0">{props.actionText}</div>
                        </div>
                    </div>
                    <div className="col-sm-2 d-flex align-items-center justify-content-end">
                        {props.assignmentState && !loading && (
                            <React.Fragment>
                                {props.assignmentState === AssignmentState.WaitingAccept && (
                                    <React.Fragment>
                                        <MCButton
                                            size={ButtonSize.Large}
                                            innerValue={"Accept"}
                                            onClick={acceptAssignment}
                                            colour={ButtonColourOptions.Yellow}
                                            roundedCorner
                                            scalableFont
                                        />
                                        <MCButton
                                            size={ButtonSize.Large}
                                            innerValue={"Reject"}
                                            onClick={rejectAssignment}
                                            colour={ButtonColourOptions.DarkBlue}
                                            roundedCorner
                                            scalableFont
                                        />
                                    </React.Fragment>
                                )}
                                {props.assignmentState === AssignmentState.Accepted && (
                                    <MCButton
                                        size={ButtonSize.Large}
                                        innerValue={"Accept"}
                                        onClick={acceptBriefing}
                                        colour={ButtonColourOptions.Yellow}
                                        roundedCorner
                                        scalableFont
                                    />
                                )}
                            </React.Fragment>
                        )}

                        {loading && (
                            <div className="text-center">
                                <div className="spinner-border dark-blue-icon mr-3" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default GRSNotificationListItem;
