import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../../../store/Store";
import {useEffect} from "react";
import moment from "moment";
import {
    getAbsenceStatsForDashboard,
    nullifyAbsenceStatsStore
} from "../../../../../../../store/statistics/absence/absenceStats/actions/AbsenceStatsActions";

function useAbsenceStats() {
    const absenceStatsStore = useSelector((state: RootStore) => state.absenceStats);
    const dispatch = useDispatch();
    useEffect(() => {
        const now = moment();
        const startDate = now.clone().startOf("month").unix();
        const endDate = now.clone().endOf("month").unix();

        dispatch(getAbsenceStatsForDashboard({startDate, endDate}));

        return function () {
            dispatch(nullifyAbsenceStatsStore());
        };
    }, []);

    return {
        absenceStatsStore
    };
}

export default useAbsenceStats;
