import React, {useState} from "react";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../../../../api/grs";
import {useDispatch} from "react-redux";
import Calendar from "react-calendar";
import moment from "moment";
import {toArray} from "../../../../../../utils/sortingUtils";
import {getEntriesFromService} from "../../../../../../store/grs/calendarEntryList/actions/CalendarEntryListActions";
import {useUserData} from "../../../../../Hooks/useUserData";
import {getLinkToStaffCalendar} from "../Helpers/staffCalendarHelpers";

const MiniCalendar = (props: FrontlineCalendarEntry[] | EventCalendarEntry[]) => {
    const dispatch = useDispatch();
    const [currentDate, setCurrentDate] = useState(moment().toDate());
    const user = useUserData();

    const tileClassName = ({date}: any) => {
        for (const entry of toArray(props)) {
            const date_A = moment.unix(entry.startDate).startOf("day").unix();
            const date_B = moment(date).startOf("day").unix();

            //Check if dates match
            if (date_A === date_B) {
                //Apply custom class
                return "calendar-booked-date";
            }
        }

        return null;
    };

    // eslint-disable-next-line no-shadow
    const onMonthChange = async ({activeStartDate}: any) => {
        setCurrentDate(activeStartDate);
        const startDate = moment(activeStartDate).startOf("month").subtract(7, "day").unix();
        const endDate = moment(activeStartDate).endOf("month").add(7, "day").unix();

        dispatch(
            getEntriesFromService({
                startDate,
                endDate,
                staffId: user.username
            })
        );
    };

    //Action for when a day is clicked
    const onDayClicked = (value: any) => {
        const date = moment(value).startOf("month").unix();
        window.open(getLinkToStaffCalendar(date), "_self");
    };

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 mb-5 pb-3">
                <div className="calendar-wrapper panel-item-shadow">
                    <Calendar
                        value={currentDate}
                        view="month"
                        defaultView="month"
                        onActiveStartDateChange={onMonthChange}
                        onClickDay={onDayClicked}
                        tileClassName={tileClassName}
                    />
                    <div className="calendar-key">
                        <div className="calendar-key-item">
                            Open events: <span className="ml-2 mt-1 open-event" />
                        </div>
                        <div className="calendar-key-item">
                            Booked events: <span className="ml-2 mt-1 booked-event" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default MiniCalendar;
