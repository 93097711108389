import React from "react";
import {StatisticsItem} from "../../../../../../store/statistics/helpers/statisticsHelpers";
import barScale from "../../../../../../images/BarScale.png";
import barScaleNeedle from "../../../../../../images/BarScaleNeedle.png";
import {getValueFromPercentageWithCustomTotal} from "../../../../../../utils/mathUtils";
import Tooltip from "../../../../../Tooltip/Tooltip";

const Bar = (props: StatisticsItem) => {
    //Get the needle height
    const getNeedleHeight = () => {
        const innerHeight = 110;
        const percent = Math.floor(
            getValueFromPercentageWithCustomTotal(props.compliancePercentage, innerHeight)
        );
        // Invert the value
        return -1 * percent;
    };
    return (
        <React.Fragment>
            <div className="d-inline-block mobile-stats-container bar-wrapper">
                <div className="row">
                    <div className="col d-flex position-relative justify-content-center">
                        <Tooltip
                            wrapperClassName="cursor-pointer"
                            tooltipText={props.tooltipText}
                            size={"lg"}
                            place={"right"}
                            theme={"dark"}
                        >
                            <img className="bar" src={barScale} alt="" />
                            <div
                                style={{
                                    transform: `translateY(${getNeedleHeight()}px)`
                                }}
                                className="bar-needle-parent"
                            >
                                <img className="bar-needle" src={barScaleNeedle} alt="" />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Bar;
