import {VIEW_STATS_STORE_STATE, ViewCountDispatchTypes} from "./DocumentViewStatsActionTypes";
import {Dispatch} from "redux";
import PpbApiModel from "../../../../apiModel/PpbApiModel";
import {showErrorToast} from "../../../../../utils/toastUtils";
import {CategoryViewStat} from "../../../../../api/ppb";
import {generatePpbCategoryViewStats} from "../../helpers/ppbStatsHelpers";

export const nullifyViewCountStore = () => {
    return async (dispatch: Dispatch<ViewCountDispatchTypes>) => {
        dispatch({
            type: VIEW_STATS_STORE_STATE.SUCCESS,
            data: null,
            error: null,
            loading: false
        });
    };
};

export const fetchViewCountForCategoryStats = () => {
    return async (dispatch: Dispatch<ViewCountDispatchTypes>) => {
        try {
            const stats = await getCategoryViewStatsTypeStats();

            dispatch({
                type: VIEW_STATS_STORE_STATE.SUCCESS,
                error: null,
                loading: false,
                data: generatePpbCategoryViewStats(stats)
            });
        } catch (e: any) {
            dispatch({
                type: VIEW_STATS_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

async function getCategoryViewStatsTypeStats(): Promise<CategoryViewStat[]> {
    try {
        const request = await PpbApiModel.getDocsApi().getCategoryViewCounts();

        return request.data;
    } catch (e) {
        showErrorToast("Could not get all category view stats");
        return [];
    }
}
