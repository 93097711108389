import {StoreServiceData} from "store-fetch-wrappers";
import {StatisticsItem} from "../../../helpers/statisticsHelpers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const VIEW_STATS_STORE_STATE = createStoreState("view_stats");

interface ViewStatsLoading extends StoreServiceData<StatisticsItem> {
    type: typeof VIEW_STATS_STORE_STATE.LOADING;
}

interface ViewStatsError extends StoreServiceData<StatisticsItem> {
    type: typeof VIEW_STATS_STORE_STATE.ERROR;
}

interface ViewStatsSuccess extends StoreServiceData<StatisticsItem> {
    type: typeof VIEW_STATS_STORE_STATE.SUCCESS;
}

export type ViewCountDispatchTypes = ViewStatsSuccess | ViewStatsError | ViewStatsLoading;
