import {AssignmentState, CalendarTypeEnum, StaffPendingAction} from "../../../api/grs";
import {NotificationItem, SubSystem} from "../actions/NotificationsHubActionTypes";
import React from "react";
import Icon, {IconType} from "../../../components/Icon/Icon";
import {formatUnixToCustomFormat, formatUnixToDDMMYYYY} from "../../../utils/momentUtils";

/** Sorts the raw data from GRS pending notification to a notification item */
export function convertPendingNotificationToNotificationItemList(
    payload: StaffPendingAction[]
): NotificationItem[] {
    const notificationList: NotificationItem[] = [];
    for (const action of payload) {
        switch (action.currentState) {
            case AssignmentState.WaitingAccept:
            case AssignmentState.Accepted:
                notificationList.push(getGrsNotificationItem(action));
                break;
        }
    }
    return notificationList;
}

/** Sorts out the contents of the action text based on the pending action (GRS) */
function grsActionText(props: StaffPendingAction): JSX.Element {
    const eventStartDate = formatUnixToDDMMYYYY(props.startDate);
    const flStartDate = formatUnixToCustomFormat(props.startDate, "DD/MM/YYYY - HH:mm");
    const flEndDate = formatUnixToCustomFormat(props.endDate, "DD/MM/YYYY - HH:mm");
    switch (props.currentState) {
        case AssignmentState.WaitingAccept:
            return props.calendarType === CalendarTypeEnum.Event ? (
                <p className="notification-item-message">
                    You have been assigned to an event at{" "}
                    <b className="notification-bold-text">{props.venueName}</b> on the{" "}
                    <b className="notification-bold-text">{eventStartDate}</b>. Click to{" "}
                    <b className="notification-bold-text">Accept</b> or{" "}
                    <b className="notification-bold-text">Reject</b> the assignment.
                </p>
            ) : (
                <p className="notification-item-message">
                    You have been assigned to{" "}
                    <b className="notification-bold-text">{props.entryName}</b> from{" "}
                    <b className="notification-bold-text">{flStartDate}</b> to
                    <b className="notification-bold-text">{flEndDate}</b>. Click to{" "}
                    <b className="notification-bold-text">Accept</b> or{" "}
                    <b className="notification-bold-text">Reject</b> the assignment.
                </p>
            );
        case AssignmentState.Accepted:
            return props.calendarType === CalendarTypeEnum.Event ? (
                <p className="notification-item-message">
                    Confirm that you have read the briefing document for{" "}
                    <b className="notification-bold-text">{props.venueName}</b> on the{" "}
                    <b className="notification-bold-text">{eventStartDate}</b>.
                </p>
            ) : (
                <p className="notification-item-message">
                    Confirm that you have read the briefing document for{" "}
                    <b className="notification-bold-text">{props.entryName}</b> from{" "}
                    <b className="notification-bold-text">{flStartDate}</b> to{" "}
                    <b className="notification-bold-text">{flEndDate}</b>.
                </p>
            );

        default:
            return <div />;
    }
}

//get GRS Icon
export function getGrsNotificationItemIcon(): JSX.Element {
    return (
        <React.Fragment>
            <Icon
                icon={IconType.Calendar}
                size={"Large"}
                rootElement={"Span"}
                className="dark-blue-icon"
            />
        </React.Fragment>
    );
}

function getGrsNotificationItem(action: StaffPendingAction): NotificationItem {
    return {
        subSystem: SubSystem.GRS,
        icon: getGrsNotificationItemIcon(),
        id: action.entryId,
        title: getGrsNotificationTitle(action),
        actionText: grsActionText(action),
        date: action.startDate,
        assignmentState: action.currentState
    };
}

function getGrsNotificationTitle(action: StaffPendingAction): string {
    const prefix = "GRS -";
    const location = getGrsLocation(action);
    switch (action.currentState) {
        case AssignmentState.WaitingAccept:
            return `${prefix} Pending Assignment (${location})`;
        case AssignmentState.Accepted:
            return `${prefix} Briefing (${location})`;
        default:
            return "";
    }
}

function getGrsLocation(action: StaffPendingAction) {
    switch (action.calendarType) {
        case CalendarTypeEnum.Event:
            return action.venueName;
        case CalendarTypeEnum.Frontline:
            return action.entryName;
        default:
            return action.entryName;
    }
}
