import PrfApiModel from "../../../../apiModel/PrfApiModel";
import {Dispatch} from "redux";
import {
    OVERALL_COMPLIANCE_STORE,
    OverallComplianceDispatchTypes
} from "./OverallComplianceActionTypes";
import {AuditTypeStats, AuditTypeStatsRequest} from "../../../../../api/prf";
import moment from "moment";
import {showErrorToast} from "../../../../../utils/toastUtils";
import {generateOverallAuditComplianceStats} from "../../helpers/prfStatsHelpers";

/** Reset the state of the Calendar Audit Stats List store */
export const nullifyOverallComplianceStore = () => {
    return async (dispatch: Dispatch<OverallComplianceDispatchTypes>) => {
        dispatch({
            type: OVERALL_COMPLIANCE_STORE.SUCCESS,
            data: null,
            loading: false,
            error: null
        });
    };
};

/** Gets all overall stats for audit compliance. */
export const getOverallPrfStats = () => {
    return async (dispatch: Dispatch<OverallComplianceDispatchTypes>) => {
        try {
            dispatch({
                type: OVERALL_COMPLIANCE_STORE.LOADING,
                loading: true,
                error: null
            });
            const stats = await getOverallAuditTypeStats();

            dispatch({
                type: OVERALL_COMPLIANCE_STORE.SUCCESS,
                error: null,
                loading: false,
                data: generateOverallAuditComplianceStats(stats)
            });
        } catch (e: any) {
            dispatch({
                type: OVERALL_COMPLIANCE_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

async function getOverallAuditTypeStats(): Promise<AuditTypeStats[]> {
    try {
        const auditTypeRequest = buildAllAuditTypeStatsRequest();

        const request = await PrfApiModel.getPrfApi().getAllAuditTypeStats(auditTypeRequest);

        return request.data;
    } catch (e) {
        showErrorToast("Could not get all audit type stats");
        return [];
    }
}

function buildAllAuditTypeStatsRequest(): AuditTypeStatsRequest {
    const now = moment();
    const startOfYear = now.clone().startOf("year").unix();
    const endOfYear = now.clone().endOf("year").unix();

    return {
        creationDate: {
            startDate: startOfYear,
            endDate: endOfYear
        }
    };
}
