import {
    DRUG_BATCH_LIST_STATS_STORE,
    DrugBatchListDispatchTypes
} from "./DrugBatchListStatsActionTypes";
import {Dispatch} from "redux";
import {generateActiveDrugBatchStats} from "../../helpers/mmStatsHelpers";
import {DrugBatch} from "../../../../../api/mm";

export const nullifyDrugBatchListStore = () => {
    return async (dispatch: Dispatch<DrugBatchListDispatchTypes>) => {
        dispatch({
            type: DRUG_BATCH_LIST_STATS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const getActiveDrugBatchStats = (drugs: DrugBatch[]) => {
    return async (dispatch: Dispatch<DrugBatchListDispatchTypes>) => {
        try {
            dispatch({
                type: DRUG_BATCH_LIST_STATS_STORE.SUCCESS,
                error: null,
                loading: false,
                data: generateActiveDrugBatchStats(drugs)
            });
        } catch (e: any) {
            dispatch({
                type: DRUG_BATCH_LIST_STATS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
