import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../../api/grs";
import {CALENDAR_ENTRY_LIST_STORE_STATE} from "../actions/CalendarEntryListActionTypes";
import {showErrorToast} from "../../../../utils/toastUtils";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";

const defaultState = createDefaultStoreState<EventCalendarEntry[] | FrontlineCalendarEntry[]>([]);

const calendarEntryListReducer = (
    state: StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> = defaultState,
    action: ServiceActionTypes<EventCalendarEntry[] | FrontlineCalendarEntry[]>
): StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> =>
    createReducer(state, action, CALENDAR_ENTRY_LIST_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default calendarEntryListReducer;
