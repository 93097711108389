/** Gets the monthly wage for wage report entry */
import {BillingType, StaffWageReportEntry} from "../../../../api/grs";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const numeral = require("numeral");

export const getMonthlyWage = (entries: StaffWageReportEntry[]) => {
    let wage = 0;

    for (const entry of entries) {
        const cost = getWageForWageReport(entry);

        wage += cost;
    }

    return wage;
};

/** Based on calendar billing type, will return the correct value. */
export const getWageForWageReport = (report: StaffWageReportEntry) => {
    switch (report.billingType) {
        case BillingType.Daily:
            // eslint-disable-next-line no-case-declarations
            let dailyCost = 0;
            // eslint-disable-next-line no-case-declarations
            for (const staff of report.staff) {
                dailyCost += staff.wage;
            }
            return dailyCost;
        case BillingType.Hourly:
            // eslint-disable-next-line no-case-declarations
            let hourlyCost = 0;
            // eslint-disable-next-line no-case-declarations
            for (const staff of report.staff) {
                const wage = staff.assignmentRate * staff.numHours;

                hourlyCost += wage;
            }
            return hourlyCost;
    }

    return 0;
};

/** Gets average cost per event */
export const getAverageCostPerEvent = (monthlyWage: number, entryCount: number) => {
    //Stops division by 0 from occurring.
    if (monthlyWage === 0) return 0;

    return +(monthlyWage / entryCount).toFixed(2);
};

export function getFormattedWage(wage: number): string {
    const formattedWage = numeral(wage).format("0,0.00");
    return `£${formattedWage}`;
}

export function getWageCalculationTableRows(
    reports: StaffWageReportEntry[]
): WageCalculationCostingsRow[] {
    const monthlyWage = getMonthlyWage(reports);
    return [
        {
            label: "Monthly Cost",
            value: getFormattedWage(monthlyWage)
        },
        {
            label: "Average Cost",
            value: getFormattedWage(getAverageCostPerEvent(monthlyWage, reports.length))
        },
        {
            label: "Number of Events",
            value: reports.length.toString()
        }
    ];
}

export interface WageCalculationCostingsRow {
    label: string;
    value: string;
}
