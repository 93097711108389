import React, {useEffect, useState} from "react";
import {StaffWageReportEntry, StaffWageReportResponse} from "../../../../api/grs";
import WageCalculationTable from "./WageCalculationTable";
import {formatUnixToMMMMYYYY} from "../../../../utils/momentUtils";

const WageCalculationTableWrapper = (props: StaffWageReportResponse) => {
    const [sortedReportsByCalendarId, setSortedReportsByCalendarId] = useState<any>(null);

    useEffect(() => {
        if (!props.entries) return;
        sortEntriesByCalendarId(props.entries);
    }, [props]);

    const sortEntriesByCalendarId = (entries: StaffWageReportEntry[]) => {
        const localEntries = entries;

        //Order by calendar ID ascending
        localEntries.sort((a: StaffWageReportEntry, b: StaffWageReportEntry) => {
            return a.calendarId - b.calendarId;
        });

        const sortedReports = localEntries.reduce((results: any, entry: any) => {
            (results[entry.calendarId] = results[entry.calendarId] || []).push(entry);
            return results;
        }, []);

        setSortedReportsByCalendarId(sortedReports);
    };

    const getMonth = () => {
        if (!props.startDateInclusive) return "this month";

        return formatUnixToMMMMYYYY(props.startDateInclusive);
    };
    return (
        <React.Fragment>
            {sortedReportsByCalendarId &&
                sortedReportsByCalendarId.length > 0 &&
                sortedReportsByCalendarId.map((el: StaffWageReportEntry[], index: number) => {
                    return <WageCalculationTable {...el} key={index} />;
                })}
            {sortedReportsByCalendarId && sortedReportsByCalendarId.length === 0 && (
                <div className="row ml-0 mr-0 fadeIn mt-3">
                    <div className="col">
                        <h5 className="text-center p-3">
                            Report could not be generated for {getMonth()} as there are no events
                            scheduled.
                        </h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default WageCalculationTableWrapper;
