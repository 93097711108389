import {DocsApi} from "../../api/ppb";

export default class PpbApiModel {
    private static instance: PpbApiModel;
    private static docsApi: DocsApi;

    public static getInstance(): PpbApiModel {
        if (!PpbApiModel.instance) {
            PpbApiModel.instance = new PpbApiModel();
        }
        return PpbApiModel.instance;
    }

    public static getDocsApi(): DocsApi {
        if (!PpbApiModel.docsApi) {
            PpbApiModel.docsApi = new DocsApi({
                basePath: process.env.REACT_APP_PPB_API
            });
        }

        return PpbApiModel.docsApi;
    }
}
