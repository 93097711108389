import React, {Fragment} from "react";

function TotpStep2() {
    return (
        <Fragment>
            <p className="mt-4">
                <b className="header-font">Step 2</b>
            </p>
            <p>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Once you've scanned the QR code or entered the secret, complete the setup by
                entering the <b>6 digit code</b> listed in your authenticator app. Remember to be
                quick as the code will refresh after a period of time.
            </p>
        </Fragment>
    );
}

export default TotpStep2;
