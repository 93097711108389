import GrsApiModel from "../../../apiModel/GrsApiModel";
import {Dispatch} from "redux";
import {
    CALENDAR_ENTRY_LIST_STORE_STATE,
    CalendarEntryListDispatchTypes
} from "./CalendarEntryListActionTypes";
import {CalendarEntryListRequest} from "../../../../api/grs";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

export const getEntriesFromService = (request: CalendarEntryListRequest) => {
    return async (dispatch: Dispatch<CalendarEntryListDispatchTypes>) => {
        try {
            await getDataFromServiceWithRedux(
                CALENDAR_ENTRY_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listCalendarEntries(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_ENTRY_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
