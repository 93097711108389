import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {StatisticsItem} from "../../../helpers/statisticsHelpers";

export const ABSENCE_STATS_STORE = createStoreState("absence_store");

interface AbsenceStatsLoading extends StoreServiceData<AbsenceStatsBlock> {
    readonly type: typeof ABSENCE_STATS_STORE.LOADING;
}

interface AbsenceStatsError extends StoreServiceData<AbsenceStatsBlock> {
    readonly type: typeof ABSENCE_STATS_STORE.ERROR;
}

interface AbsenceStatsSuccess extends StoreServiceData<AbsenceStatsBlock> {
    readonly type: typeof ABSENCE_STATS_STORE.SUCCESS;
}

export type AbsenceStatsDispatchTypes =
    | AbsenceStatsLoading
    | AbsenceStatsError
    | AbsenceStatsSuccess;

export interface AbsenceStatsBlock {
    monthName: string;
    statItems: StatisticsItem[];
    start: number;
    end: number;
}
