// eslint-disable-next-line no-shadow
import moment from "moment";
import {CalendarEntryListRequest} from "../../../../../../api/grs";

// eslint-disable-next-line no-shadow
export enum CalendarMonthView {
    Staff = "Staff Calendar",
    Global = "Global Calendar"
}

export function getLinkToStaffCalendar(startDate: number) {
    const encodedRoute = encodeURI(CalendarMonthView.Staff);
    const globalCalendarRoute = "global-calendar/";
    const month = `?month=${startDate}`;
    const globalCalendarQuery = "&isGlobalCalendar=true";

    return `${process.env.REACT_APP_GRS}${globalCalendarRoute}${encodedRoute}${month}${globalCalendarQuery}`;
}

export function buildInitialCalendarEntryListRequest(staffId: string): CalendarEntryListRequest {
    const startDate = moment().startOf("month").subtract(8, "days").unix();
    const endDate = moment().endOf("month").add(7, "days").unix();

    return {
        startDate,
        endDate,
        staffId
    };
}
