import {StoreServiceData} from "store-fetch-wrappers";
import {AssignmentState} from "../../../api/grs";
import {ReportState} from "../../../api/im";
import {createStoreState} from "store-fetch-wrappers/dist";

export const NOTIFICATIONS_HUB_STORE_STATE = createStoreState("notifications_hub");

// eslint-disable-next-line no-shadow
export enum SubSystem {
    ALL = "All",
    GRS = "Group Rostering System",
    IM = "Incident Management",
    PPB = "My Documents",
    MM = "Medicine Management",
    PRF = "Patient Report Forms"
}

// eslint-disable-next-line no-shadow
export enum SubSystemForStaff {
    ALL = "All",
    GRS = "Group Rostering System",
    IM = "Incident Management",
    PPB = "My Documents",
    PRF = "Patient Report Forms"
}

// eslint-disable-next-line no-shadow
export enum IncidentType {
    Incident = "Incident",
    Complaint = "Complaint",
    VehicleDefect = "VehicleDefect",
    EquipmentDefect = "EquipmentDefect"
}

export interface NotificationItem {
    subSystem: SubSystem;
    icon: JSX.Element;
    id: string | number;
    title: string;
    actionText: JSX.Element;
    date?: number;
    subsystemIconColour?: string;
    link?: string;
    assignmentState?: AssignmentState;
    reportState?: ReportState;
}

interface NotificationsHubLoading extends StoreServiceData<NotificationItem[]> {
    type: typeof NOTIFICATIONS_HUB_STORE_STATE.LOADING;
}
interface NotificationsHubError extends StoreServiceData<NotificationItem[]> {
    type: typeof NOTIFICATIONS_HUB_STORE_STATE.ERROR;
}
interface NotificationsHubSuccess extends StoreServiceData<NotificationItem[]> {
    type: typeof NOTIFICATIONS_HUB_STORE_STATE.SUCCESS;
}

export type NotificationsHubDispatchTypes =
    | NotificationsHubSuccess
    | NotificationsHubError
    | NotificationsHubLoading;
