import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useUserData} from "../../Hooks/useUserData";
import {getNavItemsForUser} from "./Helpers/dashboardHelpers";
import LiveIncidentTracker from "./Components/LiveIncidentTracker/LiveIncidentTracker";
import BackgroundContainer from "../../Containers/BackgroundContainer";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import StaffCalendar from "./Components/StaffCalendar/StaffCalendar";
import ComplianceStatsContainer from "./Components/ComplianceStats/ComplianceStatsContainer";
import useMcConfig from "../../Hooks/useMcConfig";
import {McConfig} from "../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../utils/userDataUtils";

const Dashboard = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const {config} = useMcConfig();

    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        //Set Nav Items
        dispatch(setNavigationItems(getNavItemsForUser(user, config)));
    }, [user, config]);

    const renderContentBasedOnAccessLevel = (userData: UserData, mcConfig: McConfig) => {
        const accessLevel = getUserAccessLevel(userData, mcConfig);
        switch (accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return (
                    <BackgroundContainer background={"background-clear"} addPageContainer>
                        <ComplianceStatsContainer />
                    </BackgroundContainer>
                );
            case StaffAccessLevel.DutyManager:
            case StaffAccessLevel.Staff:
                return (
                    <BackgroundContainer background={"background-clear"} addPageContainer>
                        <StaffCalendar />
                    </BackgroundContainer>
                );
        }
    };
    return (
        <React.Fragment>
            {user.username.length > 0 && config.auth && (
                <React.Fragment>
                    <BackgroundContainer background={"background-chevrons"} addPageContainer>
                        <LiveIncidentTracker />
                    </BackgroundContainer>
                    {renderContentBasedOnAccessLevel(user, config)}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Dashboard;
