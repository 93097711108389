import React from "react";
import {NotificationItem} from "../../../../../../store/notificationsHub/actions/NotificationsHubActionTypes";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";

const NotificationListItem = (props: NotificationItem) => {
    const openLink = () => {
        if (!props.link) return;

        window.open(props.link, "_self");
    };

    return (
        <React.Fragment>
            <div className="notification-item">
                <div className="row ml-0 mr-0 pt-2">
                    <div className="col-sm-0">{props.icon}</div>
                    <div className="col-sm-9">
                        <div className="row ml-0 mr-0">
                            <p className="notification-item-title pt-1">{props.title}</p>
                        </div>
                        <div className="row ml-0 mr-0">{props.actionText}</div>
                    </div>
                    <div className="col-sm-2 d-flex align-items-center justify-content-end">
                        {props.link && (
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue="View"
                                onClick={openLink}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                                scalableFont
                            />
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NotificationListItem;
