import {StoreServiceData} from "store-fetch-wrappers";
import {EventCalendarEntry, FrontlineCalendarEntry, OnCallCalendarEntry} from "../../../../api/grs";
import {createStoreState} from "store-fetch-wrappers/dist";

export const CALENDAR_ENTRY_LIST_STORE_STATE = createStoreState("calendar_entry_list");

interface CalendarEntryListLoading
    extends StoreServiceData<
        OnCallCalendarEntry[] | FrontlineCalendarEntry[] | EventCalendarEntry[]
    > {
    type: typeof CALENDAR_ENTRY_LIST_STORE_STATE.LOADING;
}
interface CalendarEntryListError
    extends StoreServiceData<
        OnCallCalendarEntry[] | FrontlineCalendarEntry[] | EventCalendarEntry[]
    > {
    type: typeof CALENDAR_ENTRY_LIST_STORE_STATE.ERROR;
}
interface CalendarEntryListSuccess
    extends StoreServiceData<
        OnCallCalendarEntry[] | FrontlineCalendarEntry[] | EventCalendarEntry[]
    > {
    type: typeof CALENDAR_ENTRY_LIST_STORE_STATE.SUCCESS;
}

export type CalendarEntryListDispatchTypes =
    | CalendarEntryListError
    | CalendarEntryListLoading
    | CalendarEntryListSuccess;
