import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {TotpVerifyResponse} from "../../api/staff";
import {routeNames} from "../Navigation/routeNames";
import {useHistory} from "react-router-dom";
import Card from "../Cards/Card";
import Tick from "../Icon/Tick";

function TotpFormPart2(props: TotpVerifyResponse) {
    const history = useHistory();
    return (
        <div className="totp-container justify-content-center fadeIn">
            <Card>
                <div className="totp-success-container">
                    <div className="totp-success">
                        <div className="dot bottom-left" />
                        <div className="dot top-right" />
                        <Tick />
                    </div>
                </div>

                <p className="mt-4 text-center">
                    <b className="header-font totp-qr-code-cta-text">Success</b>
                </p>
                <p className="mb-4 text-center">
                    {props.message || "You have successfully setup TOTP."}
                </p>
                <div className="row ml-0 mr-0">
                    <div className="col d-flex justify-content-center align-items-center">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Back to Dashboard"}
                            onClick={() => history.push(routeNames.dashboard.path)}
                            colour={ButtonColourOptions.Yellow}
                            className="mb-0 mr-0"
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default TotpFormPart2;
