import React from "react";

const NoItemsMessage = ({message}: Props) => (
    <div className="row ml-0 mr-0 fadeIn">
        <div className="col">
            <h5 className="text-center p-3">{message}</h5>
        </div>
    </div>
);

export default NoItemsMessage;

interface Props {
    message: string;
}
