import {Dispatch} from "redux";
import {
    NotificationItem,
    NOTIFICATIONS_HUB_STORE_STATE,
    NotificationsHubDispatchTypes,
    SubSystem
} from "./NotificationsHubActionTypes";
import store from "../../Store";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import {ReportPageResponse, ReportState} from "../../../api/im";
import {
    convertActiveDrugBatchesToNotificationItem,
    convertDrugAuditResponseToNotificationItem
} from "../helpers/mmHelpers";
import {
    fetchDocumentsForStaffMember,
    fetchDrugAuditsForCurrentMonth,
    fetchReportListing,
    getPagedAudits,
    getPendingNotifications
} from "../helpers/requestHelpers";
import {convertPPBDocumentsToNotificationItems} from "../helpers/ppbHelpers";
import {convertIMReportsNotificationItemList} from "../helpers/imHelpers";
import {convertPendingNotificationToNotificationItemList} from "../helpers/grsHelpers";
import {convertAuditListToNotificationItemList} from "../helpers/prfHelpers";
import {fetchAllActiveDrugBatches} from "../../mm/drugBatchList/actions/DrugBatchListActions";
import {DrugAuditPagedResponse, DrugBatch} from "../../../api/mm";
import {McConfig} from "../../../McConfigPlugin";
import {
    AccessDetailType,
    doesUserHaveAdminAccess,
    getUserAccessLevel
} from "../../../utils/userDataUtils";

export const clearNotificationsHubStore =
    () => async (dispatch: Dispatch<NotificationsHubDispatchTypes>) => {
        dispatch({
            type: NOTIFICATIONS_HUB_STORE_STATE.LOADING,
            error: null,
            loading: true
        });
        dispatch({
            type: NOTIFICATIONS_HUB_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };

/** Fetches the notifications for the user */
export const fetchNotificationsForUser = (user: UserData, mcConfig: McConfig, filter?: string) => {
    return async (dispatch: Dispatch<NotificationsHubDispatchTypes>) => {
        //Set loading
        dispatch({
            type: NOTIFICATIONS_HUB_STORE_STATE.LOADING,
            error: null,
            loading: true
        });

        const accessLevel = getUserAccessLevel(user, mcConfig);

        //generate request based on users access level.
        switch (accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                await generateSystemAdminNotificationsRequest(dispatch, user, filter);
                break;
            case StaffAccessLevel.DutyManager:
            case StaffAccessLevel.Staff:
                await generateDutyManagerAndStaffNotificationsRequest(dispatch, user, filter);
                break;
        }
    };
};

/** Generates the request for staff and duty managers and creates notification item objects */
async function generateDutyManagerAndStaffNotificationsRequest(
    dispatch: Dispatch<NotificationsHubDispatchTypes>,
    user: UserData,
    filter?: string
) {
    try {
        //Declare array to push to.
        const notifications: NotificationItem[] = [];

        const pendingGrsNotifications = await getPendingNotifications();
        //Gets incidents currently under review.
        const reportsUnderReview = await fetchReportListing(ReportState.UnderReview, user.username);
        //Gets incidents completed by admin
        const reportsCompletedReview = await fetchReportListing(
            ReportState.CompletedReview,
            user.username
        );

        //Get audits completed by admin
        const auditsForUser = await getPagedAudits(user.username);

        //Gets PPB documents from service
        const documentsForUser = await fetchDocumentsForStaffMember(user.username);

        //Sort the data if it exists.
        if (pendingGrsNotifications) {
            notifications.push(
                ...convertPendingNotificationToNotificationItemList(pendingGrsNotifications)
            );
        }
        if (reportsUnderReview) {
            notifications.push(...convertIMReportsNotificationItemList(reportsUnderReview));
        }
        if (reportsCompletedReview) {
            notifications.push(...convertIMReportsNotificationItemList(reportsCompletedReview));
        }
        if (documentsForUser) {
            notifications.push(...convertPPBDocumentsToNotificationItems(documentsForUser));
        }

        if (auditsForUser) {
            notifications.push(...convertAuditListToNotificationItemList(auditsForUser));
        }

        dispatch({
            type: NOTIFICATIONS_HUB_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: filterNotifications(notifications, filter)
        });
    } catch (e: any) {
        dispatch({
            type: NOTIFICATIONS_HUB_STORE_STATE.ERROR,
            error: e,
            loading: false
        });
    }
}

/** Generates the request for system administrators and creates notification item objects */
async function generateSystemAdminNotificationsRequest(
    dispatch: Dispatch<NotificationsHubDispatchTypes>,
    user: UserData,
    filter?: string
) {
    try {
        //Declare array to push to.
        const notifications: NotificationItem[] = [];
        let reportsPendingReview: ReportPageResponse | null | undefined;
        let drugAudits: DrugAuditPagedResponse | null | undefined;
        let activeDrugBatches: DrugBatch[] = [];

        if (doesUserHaveAdminAccess(AccessDetailType.im, user)) {
            reportsPendingReview = await fetchReportListing(ReportState.PendingReview);
        }
        // Make request to get reports pending made within the last 2 weeks.
        if (doesUserHaveAdminAccess(AccessDetailType.mm, user)) {
            drugAudits = await fetchDrugAuditsForCurrentMonth();

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            activeDrugBatches = await store.dispatch(fetchAllActiveDrugBatches());
        }

        // Make request to get in-date drugs

        //Sort the data if it exists.
        if (reportsPendingReview) {
            notifications.push(...convertIMReportsNotificationItemList(reportsPendingReview));
        }
        if (activeDrugBatches) {
            notifications.push(...convertActiveDrugBatchesToNotificationItem(activeDrugBatches));
        }
        if (drugAudits) {
            notifications.push(...convertDrugAuditResponseToNotificationItem(drugAudits));
        }

        dispatch({
            type: NOTIFICATIONS_HUB_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: filterNotifications(notifications, filter)
        });
    } catch (e: any) {
        dispatch({
            type: NOTIFICATIONS_HUB_STORE_STATE.ERROR,
            error: e,
            loading: false
        });
    }
}

/** Removes notification from live incident tracker. */
export const removeNotificationFromList = (
    id: number | string,
    subSystem: SubSystem,
    user: UserData
) => {
    return async (dispatch: Dispatch<NotificationsHubDispatchTypes>) => {
        const data = store.getState().notificationsHub.data;
        if (!data) return;

        const index = data.findIndex(
            (el: NotificationItem) => el.id === id && el.subSystem === subSystem
        );

        if (index < 0) return;

        //Remove data notification from the list.
        data.splice(index, 1);

        dispatch({
            type: NOTIFICATIONS_HUB_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: data
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await store.dispatch(fetchNotificationsForUser(user));
    };
};

function filterNotifications(
    notificationItems: NotificationItem[],
    filter: string | undefined
): NotificationItem[] {
    if (!filter) {
        return notificationItems;
    }
    switch (filter) {
        case SubSystem.ALL:
            return notificationItems;
        default:
            return notificationItems.filter((el: NotificationItem) => el.subSystem === filter);
    }
}
