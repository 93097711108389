import {ReportListing, ReportPageResponse, ReportState} from "../../../api/im";
import {IncidentType, NotificationItem, SubSystem} from "../actions/NotificationsHubActionTypes";
import {getUiFriendlyText, splitStringByCapitalLetter} from "../../../utils/textUtils";

import React from "react";
import Icon, {IconType} from "../../../components/Icon/Icon";
import {formatUnixToDDMMYYYY} from "../../../utils/momentUtils";

/** Sorts the raw data from IM to a notification item */
export function convertIMReportsNotificationItemList(
    payload: ReportPageResponse
): NotificationItem[] {
    const notificationList: NotificationItem[] = [];
    for (const report of payload.reports) {
        notificationList.push({
            subSystem: SubSystem.IM,
            icon: getIconForIncidentNotification(report.type),
            id: report.id,
            title: getImNotificationTitle(report),
            actionText: getImActionText(report),
            reportState: report.state,
            link: getLinkToImReport(report)
        });
    }

    return notificationList;
}

/** Generates the action text for the IM notifications based on the report state and report type.
 * Returns JSX.Element so that we can control what elements are bold within the action text*/
function getImActionText(report: ReportListing) {
    let actionText: JSX.Element = <div />;
    const createdReportDate = formatUnixToDDMMYYYY(report.dateCreated);
    const modifiedReportDate = formatUnixToDDMMYYYY(report.dateModified);
    const readableReportState = report.state.split(/(?=[A-Z])+/g).join(" ");

    // For grammatical purposes.
    const startOfSentence = getStartOfSentenceForImActionText(report.type);

    switch (report.state) {
        case ReportState.CompletedReview:
            actionText = (
                <p className="notification-item-message">
                    <p className="notification-bold-text">{startOfSentence}</p> report you made on{" "}
                    the <b className="notification-bold-text">{createdReportDate}</b> has been been{" "}
                    marked as <b className="notification-bold-text">complete</b> on the{" "}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <b className="notification-bold-text">{modifiedReportDate}</b>. Select View{" "}
                    button to review the report.
                </p>
            );
            break;
        case ReportState.UnderReview:
            actionText = (
                <p className="notification-item-message">
                    <b className="notification-bold-text">{startOfSentence}</b> report you made on
                    the <b className="notification-bold-text">{createdReportDate}</b> is now{" "}
                    <b className="notification-bold-text">{readableReportState.toLowerCase()}</b>.
                    Select {/* eslint-disable-next-line react/no-unescaped-entities */}
                    View to review the report.
                </p>
            );
            break;
        case ReportState.PendingReview:
            actionText = (
                <p className="notification-item-message">
                    <b className="notification-bold-text">{startOfSentence}</b> was created by{" "}
                    <b className="notification-bold-text">{report.staffName}</b> on the{" "}
                    <b className="notification-bold-text">{createdReportDate}</b> for{" "}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <b className="notification-bold-text">{report.calendarName}</b>. Select View{" "}
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    to review this report.
                </p>
            );
            break;
    }

    return actionText;
}

function getStartOfSentenceForImActionText(reportType: string) {
    switch (reportType) {
        case IncidentType.Incident:
        case IncidentType.EquipmentDefect:
            return `An ${splitStringByCapitalLetter(reportType).toLowerCase()}`; // A complaint
        case IncidentType.VehicleDefect:
        case IncidentType.Complaint:
            return `A ${splitStringByCapitalLetter(reportType).toLowerCase()}`; // A complaint
    }
}

//Get IM icon
export function getIconForIncidentNotification(reportType: string): JSX.Element {
    switch (reportType) {
        case IncidentType.Incident:
        case IncidentType.Complaint:
            return (
                <React.Fragment>
                    <Icon
                        rootElement={"Span"}
                        icon={IconType.IncidentNotification}
                        size={"Large"}
                        className={getImIconColour(reportType)}
                    />
                </React.Fragment>
            );
        case IncidentType.EquipmentDefect:
            return (
                <React.Fragment>
                    <Icon
                        rootElement={"Span"}
                        icon={IconType.EquipmentNotification}
                        size={"Large"}
                        className={getImIconColour(reportType)}
                    />
                </React.Fragment>
            );

        case IncidentType.VehicleDefect:
            return (
                <React.Fragment>
                    <Icon
                        rootElement={"Span"}
                        icon={IconType.VehicleNotification}
                        size={"Large"}
                        className={getImIconColour(reportType)}
                    />
                </React.Fragment>
            );

        default:
            return <React.Fragment />;
    }
}

function getImIconColour(reportType: string) {
    switch (reportType) {
        case IncidentType.Incident:
            return "yellow-icon";
        case IncidentType.EquipmentDefect:
            return "orange-icon";
        case IncidentType.VehicleDefect:
            return "red-icon";
        case IncidentType.Complaint:
            return "dark-blue-icon";
        default:
            return "dark-blue-icon";
    }
}

function getLinkToImReport(report: ReportListing): string {
    const imReportRoute = getReportRoute(report.state);
    return `${process.env.REACT_APP_IM}${imReportRoute}/${report.id}?type=${report.type}`;
}

function getReportRoute(reportState: ReportState): string {
    switch (reportState) {
        case ReportState.PendingReview:
            return "review-report";
        default:
            return "view-report";
    }
}

function getImNotificationTitle(report: ReportListing): string {
    const readableReportState = getUiFriendlyText(report.state);
    const readableReportType = getUiFriendlyText(report.type);
    return `${readableReportType} - ${readableReportState}`;
}
