import React, {useEffect, useState} from "react";
import {StaffWageReportEntry} from "../../../../api/grs";
import {toArray} from "../../../../utils/sortingUtils";
import {
    getWageCalculationTableRows,
    WageCalculationCostingsRow
} from "../Helpers/wageCalculationHelpers";
import WageCalculationTableHeader from "./WageCalculationTableHeader";
import WageCalculationTableRow from "./WageCalculationTableRow";

const WageCalculationTable = (props: StaffWageReportEntry[]) => {
    const [tableRows, setTableRows] = useState<WageCalculationCostingsRow[]>(
        getWageCalculationTableRows(toArray(props))
    );
    useEffect(() => {
        setTableRows(getWageCalculationTableRows(toArray(props)));
    }, [props]);

    return (
        <React.Fragment>
            <div className="fadeIn mt-5 pt-3">
                <div className="d-block table-overflow-x">
                    <table className="mc-table fadeIn mb-5">
                        <WageCalculationTableHeader {...toArray(props)[0]} />
                        <tbody>
                            {tableRows.map((row: WageCalculationCostingsRow, index: number) => {
                                return <WageCalculationTableRow {...row} key={index} />;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default WageCalculationTable;
