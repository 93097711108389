import {clamp} from "../../../utils/mathUtils";

export interface StatisticsItem {
    tooltipText: string;
    totalValue: number;
    needleAngle: number;
    outerLabel: string;
    compliancePercentage: number;
}

export function getAverageValueFromNumbers(array: number[]): number {
    return Math.floor(array.reduce((a, b) => a + b, 0) / array.length);
}

/** Function takes the red percentage and amber percentage and will take half value of the amber percent as we are half way there */
export function getOverallCompliancePercentage(amberPercent: number, redPercent: number): number {
    const halfAmberPercent = amberPercent * 0.5;
    const overallPercent = Math.floor(100 - halfAmberPercent - redPercent);

    return clamp(overallPercent, 0, 100);
}
