// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * GRS API
 * API to manipulate GRS entities
 *
 * OpenAPI spec version: 0.1-SNAPSHOT
 * Contact: dan@pulseadvancedmedia.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "http://localhost:9000".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum AssignmentState {
    Unassigned = 'Unassigned',
    WaitingAccept = 'WaitingAccept',
    Accepted = 'Accepted',
    Attended = 'Attended',
    BriefingAccepted = 'BriefingAccepted'
}

/**
 * Stats for a specific assignment type. For example the total number of people within the specified assignment type.
 * @export
 * @interface AssignmentStateStats
 */
export interface AssignmentStateStats {
    /**
     * 
     * @type {AssignmentState}
     * @memberof AssignmentStateStats
     */
    state?: AssignmentState;
    /**
     * Total number of people with this assignment state
     * @type {number}
     * @memberof AssignmentStateStats
     */
    count?: number;
}

/**
 * Core stats for a calendar summary
 * @export
 * @interface BaseCalendarSummaryStats
 */
export interface BaseCalendarSummaryStats {
    /**
     * Total number of slots in the entries
     * @type {number}
     * @memberof BaseCalendarSummaryStats
     */
    numRequired: number;
    /**
     * Total number of staff assigned to the slots, regardless of state
     * @type {number}
     * @memberof BaseCalendarSummaryStats
     */
    numAssignments: number;
    /**
     * Stats for individual states within the system
     * @type {Array<AssignmentStateStats>}
     * @memberof BaseCalendarSummaryStats
     */
    stateBreakdown?: Array<AssignmentStateStats>;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum BillingType {
    Hourly = 'Hourly',
    Daily = 'Daily'
}

/**
 * Master container for an individual 'Calendar View' that the user can see. Entries are then added to the calendar to assign staff to certain events or other objects.
 * @export
 * @interface Calendar
 */
export interface Calendar {
    /**
     * Unique ID for the calendar. Set to 0 to insert a new calendar.
     * @type {number}
     * @memberof Calendar
     */
    id: number;
    /**
     * Version number for concurrency control. Ignored when initially saving, incremented automatically on each save. The version number must be specified and must match the version in the DB. If the versions don't match the save request will fail.
     * @type {number}
     * @memberof Calendar
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof Calendar
     */
    name: string;
    /**
     * 
     * @type {CalendarTypeEnum}
     * @memberof Calendar
     */
    calendarType: CalendarTypeEnum;
    /**
     * 
     * @type {BillingType}
     * @memberof Calendar
     */
    billingType: BillingType;
    /**
     * Direct URL to the background image for the calendar
     * @type {string}
     * @memberof Calendar
     */
    bgImageUrl: string;
    /**
     * 
     * @type {StaffBlock}
     * @memberof Calendar
     */
    staffBlock: StaffBlock;
}

/**
 * A specific event happening in a specific calendar
 * @export
 * @interface CalendarEntry
 */
export interface CalendarEntry {
    /**
     * Unique ID for the entry. Set to 0 to insert a new entry.
     * @type {number}
     * @memberof CalendarEntry
     */
    id: number;
    /**
     * Version number for concurrency control. Ignored when initially saving, incremented automatically on each save. The version number must be specified and must match the version in the DB. If the versions don't match the save request will fail.
     * @type {number}
     * @memberof CalendarEntry
     */
    version: number;
    /**
     * 
     * @type {CalendarTypeEnum}
     * @memberof CalendarEntry
     */
    calendarType: CalendarTypeEnum;
    /**
     * 
     * @type {CalendarEntryState}
     * @memberof CalendarEntry
     */
    state: CalendarEntryState;
    /**
     * Linux epoch for when this entry starts
     * @type {number}
     * @memberof CalendarEntry
     */
    startDate: number;
    /**
     * Linux epoch for when this entry ends
     * @type {number}
     * @memberof CalendarEntry
     */
    endDate: number;
    /**
     * Linux epoch for when this entry **actually** started
     * @type {number}
     * @memberof CalendarEntry
     */
    actualStartDate?: number;
    /**
     * Linux epoch for when this entry **actually** ended
     * @type {number}
     * @memberof CalendarEntry
     */
    actualEndDate?: number;
    /**
     * Quick summary for this entry
     * @type {string}
     * @memberof CalendarEntry
     */
    description?: string;
    /**
     * Any additional notes entered by users
     * @type {string}
     * @memberof CalendarEntry
     */
    notes?: string;
    /**
     * 
     * @type {StaffBlock}
     * @memberof CalendarEntry
     */
    requiredStaff: StaffBlock;
    /**
     * Unique ID to reference an entity
     * @type {number}
     * @memberof CalendarEntry
     */
    calendarId: number;
    /**
     * Unique ID to reference an entity
     * @type {number}
     * @memberof CalendarEntry
     */
    venueId?: number;
}

/**
 * Get calendar entries that meet the requirements of this object. Automatically filters to the user's entries, unless they're an admin in which case all entries are returned.
 * @export
 * @interface CalendarEntryListRequest
 */
export interface CalendarEntryListRequest {
    /**
     * Linux Epoch for the earliest date/time to get an entry for
     * @type {number}
     * @memberof CalendarEntryListRequest
     */
    startDate: number;
    /**
     * Linux Epoch for the latest date/time to get an entry for
     * @type {number}
     * @memberof CalendarEntryListRequest
     */
    endDate: number;
    /**
     * Optional. The ID of the calendar to restrict the entries to
     * @type {number}
     * @memberof CalendarEntryListRequest
     */
    calendarId?: number;
    /**
     * Optional. If the requesting user in an admin, filter entries to only those that contain staffId
     * @type {string}
     * @memberof CalendarEntryListRequest
     */
    staffId?: string;
    /**
     * Optional. Include ALL on-call entries in the specified date range
     * @type {boolean}
     * @memberof CalendarEntryListRequest
     */
    includeOnCall?: boolean;
}

/**
 * Current state of a calendar entry
 * @export
 * @enum {string}
 */
export enum CalendarEntryState {
    Active = 'Active',
    Suspended = 'Suspended',
    Cancelled = 'Cancelled'
}

/**
 * Stats for a single event
 * @export
 * @interface CalendarEventSummaryStats
 */
export interface CalendarEventSummaryStats extends BaseCalendarSummaryStats {
    /**
     * ID of the calendar entry these stats are for
     * @type {number}
     * @memberof CalendarEventSummaryStats
     */
    entryId: number;
    /**
     * Linux epoch for the date these stats are for
     * @type {number}
     * @memberof CalendarEventSummaryStats
     */
    eventDate: number;
}

/**
 * Overall stats for a single month
 * @export
 * @interface CalendarMonthSummaryStats
 */
export interface CalendarMonthSummaryStats extends BaseCalendarSummaryStats {
    /**
     * The month of the year (1-12) for this set of stats
     * @type {number}
     * @memberof CalendarMonthSummaryStats
     */
    month: number;
    /**
     * The year (eg. 2020) for this set of stats
     * @type {number}
     * @memberof CalendarMonthSummaryStats
     */
    year: number;
}

/**
 * Core properties for a calendar object
 * @export
 * @interface CalendarSummary
 */
export interface CalendarSummary {
    /**
     * Unique ID for the calendar. Set to 0 to insert a new calendar.
     * @type {number}
     * @memberof CalendarSummary
     */
    id: number;
    /**
     * Version number for concurrency control. Ignored when initially saving, incremented automatically on each save. The version number must be specified and must match the version in the DB. If the versions don't match the save request will fail.
     * @type {number}
     * @memberof CalendarSummary
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarSummary
     */
    name: string;
    /**
     * 
     * @type {CalendarTypeEnum}
     * @memberof CalendarSummary
     */
    calendarType: CalendarTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CalendarSummary
     */
    billingType: CalendarSummary.BillingTypeEnum;
    /**
     * Direct URL to the background image for the calendar
     * @type {string}
     * @memberof CalendarSummary
     */
    bgImageUrl: string;
}

/**
 * @export
 * @namespace CalendarSummary
 */
export namespace CalendarSummary {
    /**
     * @export
     * @enum {string}
     */
    export enum BillingTypeEnum {
        Hourly = 'Hourly',
        Daily = 'Daily'
    }
}

/**
 * Summary of a calendar as well as associated stats
 * @export
 * @interface CalendarSummaryStats
 */
export interface CalendarSummaryStats {
    /**
     * 
     * @type {CalendarSummary}
     * @memberof CalendarSummaryStats
     */
    summary: CalendarSummary;
    /**
     * 
     * @type {CalendarEventSummaryStats}
     * @memberof CalendarSummaryStats
     */
    nextEventStats: CalendarEventSummaryStats;
    /**
     * 
     * @type {Array<CalendarMonthSummaryStats>}
     * @memberof CalendarSummaryStats
     */
    futureMonthStats: Array<CalendarMonthSummaryStats>;
}

/**
 * The type of calendar this is
 * @export
 * @enum {string}
 */
export enum CalendarTypeEnum {
    Event = 'Event',
    Frontline = 'Frontline',
    OnCall = 'OnCall'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ClinicalGrade {
    Paramedic = 'Paramedic',
    Technician = 'Technician',
    FirstResponder = 'FirstResponder',
    EAC = 'EAC'
}

/**
 * 
 * @export
 * @interface DutyManagerReport
 */
export interface DutyManagerReport {
    /**
     * Unique ID for the report. 0 to insert, >0 to update
     * @type {number}
     * @memberof DutyManagerReport
     */
    id: number;
    /**
     * ID of the calendar entry this report is for
     * @type {number}
     * @memberof DutyManagerReport
     */
    calendarEntryId: number;
    /**
     * Username of the duty manager that created this report
     * @type {string}
     * @memberof DutyManagerReport
     */
    dutyManagerId: string;
    /**
     * Name of the duty manager that created this report
     * @type {string}
     * @memberof DutyManagerReport
     */
    dutyManagerName: string;
    /**
     * ID of the venue, or NULL if the venue has since been deleted
     * @type {number}
     * @memberof DutyManagerReport
     */
    venueId?: number;
    /**
     * Name of the venue for this report
     * @type {string}
     * @memberof DutyManagerReport
     */
    venueName: string;
    /**
     * Date this report was created
     * @type {number}
     * @memberof DutyManagerReport
     */
    date: number;
    /**
     * JSON string that contains all information required by the report
     * @type {string}
     * @memberof DutyManagerReport
     */
    payload: string;
}

/**
 * Cut down version of DutyManagerReport, used for instances where only the core fields are required
 * @export
 * @interface DutyManagerReportListing
 */
export interface DutyManagerReportListing {
    /**
     * Unique ID for the report. 0 to insert, >0 to update
     * @type {number}
     * @memberof DutyManagerReportListing
     */
    id: number;
    /**
     * ID of the calendar entry this report is for
     * @type {number}
     * @memberof DutyManagerReportListing
     */
    calendarEntryId: number;
    /**
     * Username of the duty manager that created this report
     * @type {string}
     * @memberof DutyManagerReportListing
     */
    dutyManagerId: string;
    /**
     * Name of the duty manager that created this report
     * @type {string}
     * @memberof DutyManagerReportListing
     */
    dutyManagerName: string;
    /**
     * ID of the venue, or NULL if the venue has since been deleted
     * @type {number}
     * @memberof DutyManagerReportListing
     */
    venueId?: number;
    /**
     * Name of the venue for this report
     * @type {string}
     * @memberof DutyManagerReportListing
     */
    venueName: string;
    /**
     * Date this report was created
     * @type {number}
     * @memberof DutyManagerReportListing
     */
    date: number;
}

/**
 * 
 * @export
 * @interface DutyManagerReportPagedRequest
 */
export interface DutyManagerReportPagedRequest {
    /**
     * The page of data to return, starting from 0
     * @type {number}
     * @memberof DutyManagerReportPagedRequest
     */
    pageNum?: number;
    /**
     * The maximum number of entries to return for a single page
     * @type {number}
     * @memberof DutyManagerReportPagedRequest
     */
    numPerPage?: number;
    /**
     * Optional linux epoch for the earliest date allowed for the reports
     * @type {number}
     * @memberof DutyManagerReportPagedRequest
     */
    startDateInclusive?: number;
    /**
     * Optional linux epoch for the last date allowed for the reports
     * @type {number}
     * @memberof DutyManagerReportPagedRequest
     */
    endDateExclusive?: number;
    /**
     * Optional username for the duty manager to filter reports
     * @type {string}
     * @memberof DutyManagerReportPagedRequest
     */
    dutyManagerId?: string;
    /**
     * Optional ID of the calendar entry to filter reports
     * @type {number}
     * @memberof DutyManagerReportPagedRequest
     */
    calendarEntryId?: number;
}

/**
 * 
 * @export
 * @interface DutyManagerReportPagedResponse
 */
export interface DutyManagerReportPagedResponse {
    /**
     * The page of data to return, starting from 0
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    pageNum: number;
    /**
     * The maximum number of entries to return for a single page
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    numPerPage: number;
    /**
     * The total number of reports that meet the criteria, can be used to calculate number of pages
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    totalEntries: number;
    /**
     * Found reports for this page
     * @type {Array<DutyManagerReportListing>}
     * @memberof DutyManagerReportPagedResponse
     */
    entries: Array<DutyManagerReportListing>;
    /**
     * Optional linux epoch for the earliest date allowed for the reports
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    startDateInclusive?: number;
    /**
     * Optional linux epoch for the last date allowed for the reports
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    endDateExclusive?: number;
    /**
     * Optional username for the duty manager to filter reports
     * @type {string}
     * @memberof DutyManagerReportPagedResponse
     */
    dutyManagerId?: string;
    /**
     * Optional ID of the calendar entry to filter reports
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    calendarEntryId?: number;
}

/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}

/**
 * A specific basic event happening in the calendar
 * @export
 * @interface EventCalendarEntry
 */
export interface EventCalendarEntry extends CalendarEntry {
    /**
     * Linux epoch when staff should be at the depo
     * @type {number}
     * @memberof EventCalendarEntry
     */
    depotTime: number;
    /**
     * Linux epoch when staff should be at the depo
     * @type {number}
     * @memberof EventCalendarEntry
     */
    venueTime: number;
}

/**
 * 
 * @export
 * @interface EventsReportEntry
 */
export interface EventsReportEntry {
    /**
     * 
     * @type {number}
     * @memberof EventsReportEntry
     */
    entryId: number;
    /**
     * 
     * @type {any}
     * @memberof EventsReportEntry
     */
    entryName: any;
    /**
     * 
     * @type {number}
     * @memberof EventsReportEntry
     */
    venueId?: number;
    /**
     * 
     * @type {string}
     * @memberof EventsReportEntry
     */
    venueName?: string;
    /**
     * 
     * @type {number}
     * @memberof EventsReportEntry
     */
    startDate: number;
    /**
     * 
     * @type {number}
     * @memberof EventsReportEntry
     */
    endDate: number;
    /**
     * 
     * @type {Array<EventsReportEntryStaffSection>}
     * @memberof EventsReportEntry
     */
    sections: Array<EventsReportEntryStaffSection>;
}

/**
 * 
 * @export
 * @interface EventsReportEntryAssignment
 */
export interface EventsReportEntryAssignment {
    /**
     * 
     * @type {AssignmentState}
     * @memberof EventsReportEntryAssignment
     */
    state: AssignmentState;
    /**
     * 
     * @type {string}
     * @memberof EventsReportEntryAssignment
     */
    staffId: string;
    /**
     * 
     * @type {string}
     * @memberof EventsReportEntryAssignment
     */
    staffName: string;
}

/**
 * 
 * @export
 * @interface EventsReportEntryStaffSection
 */
export interface EventsReportEntryStaffSection {
    /**
     * 
     * @type {string}
     * @memberof EventsReportEntryStaffSection
     */
    sectionName: string;
    /**
     * 
     * @type {Array<EventsReportEntryStaffSectionGrouping>}
     * @memberof EventsReportEntryStaffSection
     */
    groups: Array<EventsReportEntryStaffSectionGrouping>;
}

/**
 * 
 * @export
 * @interface EventsReportEntryStaffSectionGrouping
 */
export interface EventsReportEntryStaffSectionGrouping {
    /**
     * 
     * @type {string}
     * @memberof EventsReportEntryStaffSectionGrouping
     */
    groupName: string;
    /**
     * 
     * @type {number}
     * @memberof EventsReportEntryStaffSectionGrouping
     */
    numSlots: number;
    /**
     * 
     * @type {Array<EventsReportEntryAssignment>}
     * @memberof EventsReportEntryStaffSectionGrouping
     */
    assignments: Array<EventsReportEntryAssignment>;
}

/**
 * 
 * @export
 * @interface EventsReportRequest
 */
export interface EventsReportRequest {
    /**
     * 
     * @type {number}
     * @memberof EventsReportRequest
     */
    calendarId: number;
    /**
     * 
     * @type {number}
     * @memberof EventsReportRequest
     */
    startDateInclusive: number;
    /**
     * 
     * @type {number}
     * @memberof EventsReportRequest
     */
    endDateExclusive: number;
}

/**
 * 
 * @export
 * @interface EventsReportResponse
 */
export interface EventsReportResponse {
    /**
     * 
     * @type {number}
     * @memberof EventsReportResponse
     */
    calendarId?: number;
    /**
     * 
     * @type {number}
     * @memberof EventsReportResponse
     */
    startDateInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof EventsReportResponse
     */
    endDateExclusive?: number;
    /**
     * 
     * @type {Array<EventsReportEntry>}
     * @memberof EventsReportResponse
     */
    entries?: Array<EventsReportEntry>;
}

/**
 * A specific front-line event happening in the calendar
 * @export
 * @interface FrontlineCalendarEntry
 */
export interface FrontlineCalendarEntry extends CalendarEntry {
}

/**
 * 
 * @export
 * @interface MarkAttendedRequest
 */
export interface MarkAttendedRequest {
    /**
     * Unique ID of the event that needs to have staff marked as attended
     * @type {number}
     * @memberof MarkAttendedRequest
     */
    entryId: number;
    /**
     * Unique ID of each staff member that should be marked as attended
     * @type {Array<string>}
     * @memberof MarkAttendedRequest
     */
    staffIds: Array<string>;
}

/**
 * A specific day for on-call staff
 * @export
 * @interface OnCallCalendarEntry
 */
export interface OnCallCalendarEntry extends CalendarEntry {
    /**
     * Current UK terrorism alert level
     * @type {string}
     * @memberof OnCallCalendarEntry
     */
    alertLevel: string;
    /**
     * 
     * @type {Array<OnCallClinicalNote>}
     * @memberof OnCallCalendarEntry
     */
    clinicalNotes?: Array<OnCallClinicalNote>;
}

/**
 * Single note for an On-Call calendar event
 * @export
 * @interface OnCallClinicalNote
 */
export interface OnCallClinicalNote {
    /**
     * 
     * @type {number}
     * @memberof OnCallClinicalNote
     */
    date?: number;
    /**
     * 
     * @type {string}
     * @memberof OnCallClinicalNote
     */
    staffName?: string;
    /**
     * 
     * @type {string}
     * @memberof OnCallClinicalNote
     */
    note?: string;
}

/**
 * 
 * @export
 * @interface ResendUserNotificationsRequest
 */
export interface ResendUserNotificationsRequest {
    /**
     * Unique ID for the staff member
     * @type {string}
     * @memberof ResendUserNotificationsRequest
     */
    staffId: string;
    /**
     * Unique ID of the event that needs to have staff marked as attended
     * @type {number}
     * @memberof ResendUserNotificationsRequest
     */
    entryId: number;
}

/**
 * The level of access a staff member has
 * @export
 * @enum {string}
 */
export enum StaffAccessLevelEnum {
    SystemAdministrator = 'SystemAdministrator',
    ComplianceManager = 'ComplianceManager',
    DutyManager = 'DutyManager'
}

/**
 * Individual assignment of a staff member to an event
 * @export
 * @interface StaffAssignment
 */
export interface StaffAssignment {
    /**
     * Unique ID for this section
     * @type {string}
     * @memberof StaffAssignment
     */
    id: string;
    /**
     * 
     * @type {StaffLink}
     * @memberof StaffAssignment
     */
    staffMember?: StaffLink;
    /**
     * 
     * @type {AssignmentState}
     * @memberof StaffAssignment
     */
    state: AssignmentState;
    /**
     * 
     * @type {number}
     * @memberof StaffAssignment
     */
    rate?: number;
    /**
     * Date the staff member is due to start work. Overrides event's start date. If endDate is specified, startDate MUST be specified.
     * @type {number}
     * @memberof StaffAssignment
     */
    startDate?: number;
    /**
     * Date the staff member is due to finish work. Overrides event's end date. If startDate is specified, endDate MUST be specified.
     * @type {number}
     * @memberof StaffAssignment
     */
    endDate?: number;
}

/**
 * Grouping with direct staff assignments
 * @export
 * @interface StaffAssignmentGrouping
 */
export interface StaffAssignmentGrouping {
    /**
     * Unique ID for this section
     * @type {string}
     * @memberof StaffAssignmentGrouping
     */
    id: string;
    /**
     * Name of the grouping, for example 'Technicians'
     * @type {string}
     * @memberof StaffAssignmentGrouping
     */
    name: string;
    /**
     * Restrict the clinical grades of staff to this list. Empty list to signify no restrictions.
     * @type {Array<ClinicalGrade>}
     * @memberof StaffAssignmentGrouping
     */
    clinicalGrades: Array<ClinicalGrade>;
    /**
     * Assignments if applicable. If this is a template, this should be an empty array
     * @type {Array<StaffAssignment>}
     * @memberof StaffAssignmentGrouping
     */
    assignments: Array<StaffAssignment>;
}

/**
 * The slots for staff to be assigned to a calendar entry, as well as the assignments themselves if applicable
 * @export
 * @interface StaffBlock
 */
export interface StaffBlock {
    /**
     * 
     * @type {Array<StaffBlockSection>}
     * @memberof StaffBlock
     */
    sections: Array<StaffBlockSection>;
}

/**
 * Section of staff members, for example 'Stadium and Ambulance Crew'
 * @export
 * @interface StaffBlockSection
 */
export interface StaffBlockSection {
    /**
     * Unique ID for this section
     * @type {string}
     * @memberof StaffBlockSection
     */
    id: string;
    /**
     * Name of this section, for example 'Stadium and Ambulance Crew'
     * @type {string}
     * @memberof StaffBlockSection
     */
    name: string;
    /**
     * Sub groupings for this section, for example 'Technicians'
     * @type {Array<StaffAssignmentGrouping>}
     * @memberof StaffBlockSection
     */
    groupings: Array<StaffAssignmentGrouping>;
}

/**
 * Direct reference to a staff member
 * @export
 * @interface StaffLink
 */
export interface StaffLink {
    /**
     * Unique reference to the staff member
     * @type {string}
     * @memberof StaffLink
     */
    staffId: string;
    /**
     * Real name of the staff member at moment of assignment
     * @type {string}
     * @memberof StaffLink
     */
    staffName: string;
}

/**
 * 
 * @export
 * @interface StaffPendingAction
 */
export interface StaffPendingAction {
    /**
     * 
     * @type {string}
     * @memberof StaffPendingAction
     */
    staffId: string;
    /**
     * 
     * @type {number}
     * @memberof StaffPendingAction
     */
    calendarId: number;
    /**
     * 
     * @type {string}
     * @memberof StaffPendingAction
     */
    calendarName: string;
    /**
     * 
     * @type {CalendarTypeEnum}
     * @memberof StaffPendingAction
     */
    calendarType: CalendarTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof StaffPendingAction
     */
    venueId?: number;
    /**
     * 
     * @type {string}
     * @memberof StaffPendingAction
     */
    venueName?: string;
    /**
     * 
     * @type {number}
     * @memberof StaffPendingAction
     */
    entryId: number;
    /**
     * 
     * @type {string}
     * @memberof StaffPendingAction
     */
    entryName: string;
    /**
     * 
     * @type {number}
     * @memberof StaffPendingAction
     */
    startDate: number;
    /**
     * 
     * @type {number}
     * @memberof StaffPendingAction
     */
    endDate: number;
    /**
     * 
     * @type {string}
     * @memberof StaffPendingAction
     */
    assignmentId: string;
    /**
     * 
     * @type {AssignmentState}
     * @memberof StaffPendingAction
     */
    currentState: AssignmentState;
}

/**
 * 
 * @export
 * @interface StaffReportEntry
 */
export interface StaffReportEntry {
    /**
     * 
     * @type {number}
     * @memberof StaffReportEntry
     */
    calendarId: number;
    /**
     * 
     * @type {string}
     * @memberof StaffReportEntry
     */
    calendarName: string;
    /**
     * 
     * @type {number}
     * @memberof StaffReportEntry
     */
    venueId?: number;
    /**
     * 
     * @type {string}
     * @memberof StaffReportEntry
     */
    venueName?: string;
    /**
     * 
     * @type {number}
     * @memberof StaffReportEntry
     */
    entryId: number;
    /**
     * 
     * @type {string}
     * @memberof StaffReportEntry
     */
    entryName: string;
    /**
     * 
     * @type {number}
     * @memberof StaffReportEntry
     */
    entryStartDate: number;
    /**
     * 
     * @type {number}
     * @memberof StaffReportEntry
     */
    entryEndDate: number;
    /**
     * 
     * @type {boolean}
     * @memberof StaffReportEntry
     */
    attended: boolean;
    /**
     * 
     * @type {AssignmentState}
     * @memberof StaffReportEntry
     */
    state: AssignmentState;
    /**
     * 
     * @type {CalendarEntryState}
     * @memberof StaffReportEntry
     */
    entryState?: CalendarEntryState;
    /**
     * 
     * @type {number}
     * @memberof StaffReportEntry
     */
    actualStartDate?: number;
    /**
     * 
     * @type {number}
     * @memberof StaffReportEntry
     */
    actualEndDate?: number;
    /**
     * The wage rate for this specific assignment, how this value is used depends on 'billingType'
     * @type {number}
     * @memberof StaffReportEntry
     */
    rate?: number;
    /**
     * The calculated number of hours for this assignment, using best-guess based on all dates in the assignment / entry
     * @type {number}
     * @memberof StaffReportEntry
     */
    numHours?: number;
    /**
     * 
     * @type {BillingType}
     * @memberof StaffReportEntry
     */
    billingType?: BillingType;
    /**
     * The calculated wage for the user using the number of hours, assignment rate, and billing type
     * @type {number}
     * @memberof StaffReportEntry
     */
    wage?: number;
}

/**
 * 
 * @export
 * @interface StaffReportRequest
 */
export interface StaffReportRequest {
    /**
     * 
     * @type {number}
     * @memberof StaffReportRequest
     */
    startDateInclusive: number;
    /**
     * 
     * @type {number}
     * @memberof StaffReportRequest
     */
    endDateExclusive: number;
    /**
     * Unique ID for the staff member
     * @type {string}
     * @memberof StaffReportRequest
     */
    staffId: string;
}

/**
 * 
 * @export
 * @interface StaffReportResponse
 */
export interface StaffReportResponse {
    /**
     * 
     * @type {number}
     * @memberof StaffReportResponse
     */
    startDateInclusive: number;
    /**
     * 
     * @type {number}
     * @memberof StaffReportResponse
     */
    endDateExclusive: number;
    /**
     * Unique ID for the staff member
     * @type {string}
     * @memberof StaffReportResponse
     */
    staffId: string;
    /**
     * 
     * @type {Array<StaffReportEntry>}
     * @memberof StaffReportResponse
     */
    entries: Array<StaffReportEntry>;
}

/**
 * 
 * @export
 * @interface StaffWageReportEntry
 */
export interface StaffWageReportEntry {
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportEntry
     */
    calendarId: number;
    /**
     * 
     * @type {string}
     * @memberof StaffWageReportEntry
     */
    calendarName: string;
    /**
     * 
     * @type {CalendarTypeEnum}
     * @memberof StaffWageReportEntry
     */
    calendarType?: CalendarTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportEntry
     */
    entryId: number;
    /**
     * 
     * @type {string}
     * @memberof StaffWageReportEntry
     */
    entryName: string;
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportEntry
     */
    startDate: number;
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportEntry
     */
    endDate: number;
    /**
     * 
     * @type {BillingType}
     * @memberof StaffWageReportEntry
     */
    billingType: BillingType;
    /**
     * 
     * @type {Array<StaffWageReportEntryStaffMember>}
     * @memberof StaffWageReportEntry
     */
    staff: Array<StaffWageReportEntryStaffMember>;
}

/**
 * 
 * @export
 * @interface StaffWageReportEntryStaffMember
 */
export interface StaffWageReportEntryStaffMember {
    /**
     * 
     * @type {string}
     * @memberof StaffWageReportEntryStaffMember
     */
    staffId: string;
    /**
     * 
     * @type {string}
     * @memberof StaffWageReportEntryStaffMember
     */
    staffName: string;
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportEntryStaffMember
     */
    assignmentRate: number;
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportEntryStaffMember
     */
    numHours: number;
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportEntryStaffMember
     */
    wage: number;
}

/**
 * 
 * @export
 * @interface StaffWageReportRequest
 */
export interface StaffWageReportRequest {
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportRequest
     */
    startDateInclusive: number;
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportRequest
     */
    endDateExclusive: number;
}

/**
 * 
 * @export
 * @interface StaffWageReportResponse
 */
export interface StaffWageReportResponse {
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportResponse
     */
    startDateInclusive?: number;
    /**
     * 
     * @type {number}
     * @memberof StaffWageReportResponse
     */
    endDateExclusive?: number;
    /**
     * 
     * @type {Array<StaffWageReportEntry>}
     * @memberof StaffWageReportResponse
     */
    entries?: Array<StaffWageReportEntry>;
}

/**
 * 
 * @export
 * @interface UserEntriesRequest
 */
export interface UserEntriesRequest {
    /**
     * Unique ID for the staff member
     * @type {string}
     * @memberof UserEntriesRequest
     */
    userId: string;
    /**
     * Start date to get entries for, inclusive
     * @type {number}
     * @memberof UserEntriesRequest
     */
    startDate: number;
    /**
     * End date to get entries for, exclusive
     * @type {number}
     * @memberof UserEntriesRequest
     */
    endDate: number;
}

/**
 * Location where a calendar entry can take place
 * @export
 * @interface Venue
 */
export interface Venue {
    /**
     * Unique ID for the venue. Set to 0 to insert a new venue.
     * @type {number}
     * @memberof Venue
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    state: Venue.StateEnum;
    /**
     * Unique ID of the calendar this venue is for
     * @type {number}
     * @memberof Venue
     */
    calendarId: number;
    /**
     * Postal address for Venue
     * @type {string}
     * @memberof Venue
     */
    address: string;
    /**
     * Post code for Venue
     * @type {string}
     * @memberof Venue
     */
    postcode: string;
    /**
     * URL for the logo for the Venue
     * @type {string}
     * @memberof Venue
     */
    logoUrl?: string;
}

/**
 * @export
 * @namespace Venue
 */
export namespace Venue {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        Active = 'Active',
        Inactive = 'Inactive'
    }
}


/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendar(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCalendar.');
            }
            const localVarPath = `/calendars/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEntry(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteCalendarEntry.');
            }
            const localVarPath = `/calendars/entries/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDutyManagerReport(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDutyManagerReport.');
            }
            const localVarPath = `/reports/dutymanager/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarById(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCalendarById.');
            }
            const localVarPath = `/calendars/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEntryById(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCalendarEntryById.');
            }
            const localVarPath = `/calendars/entries/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportById(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDutyManagerReportById.');
            }
            const localVarPath = `/reports/dutymanager/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsForCalendarEntry(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDutyManagerReportsForCalendarEntry.');
            }
            const localVarPath = `/reports/dutymanager/forcalentry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options: any = {}): RequestArgs {
            // verify required parameter 'dutyManagerReportPagedRequest' is not null or undefined
            if (dutyManagerReportPagedRequest === null || dutyManagerReportPagedRequest === undefined) {
                throw new RequiredError('dutyManagerReportPagedRequest','Required parameter dutyManagerReportPagedRequest was null or undefined when calling getDutyManagerReportsPaged.');
            }
            const localVarPath = `/reports/dutymanager/paged`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DutyManagerReportPagedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dutyManagerReportPagedRequest || {}) : (dutyManagerReportPagedRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all of the calendar entries that a user is booked on between some dates. Unlike the normal list method, this will return all entries that in any way overlap the provided date range, not just events that start and finish within the range.
         * @summary Get all of the calendar entries that a user is booked on between some dates.
         * @param {UserEntriesRequest} userEntriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEntries(userEntriesRequest: UserEntriesRequest, options: any = {}): RequestArgs {
            // verify required parameter 'userEntriesRequest' is not null or undefined
            if (userEntriesRequest === null || userEntriesRequest === undefined) {
                throw new RequiredError('userEntriesRequest','Required parameter userEntriesRequest was null or undefined when calling getUserEntries.');
            }
            const localVarPath = `/calendars/entries/existing/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserEntriesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userEntriesRequest || {}) : (userEntriesRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all calendar entries that meet the provided requirements
         * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarEntries(calendarEntryListRequest: CalendarEntryListRequest, options: any = {}): RequestArgs {
            // verify required parameter 'calendarEntryListRequest' is not null or undefined
            if (calendarEntryListRequest === null || calendarEntryListRequest === undefined) {
                throw new RequiredError('calendarEntryListRequest','Required parameter calendarEntryListRequest was null or undefined when calling listCalendarEntries.');
            }
            const localVarPath = `/calendars/entries/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CalendarEntryListRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(calendarEntryListRequest || {}) : (calendarEntryListRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all calendars in the system, core fields only
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarSummary(options: any = {}): RequestArgs {
            const localVarPath = `/calendars/list/summary`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of all calendars in the system, as well as general stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarSummaryStats(options: any = {}): RequestArgs {
            const localVarPath = `/calendars/list/summary/stats`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a calendar to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
         * @param {Calendar} calendar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendar(calendar: Calendar, options: any = {}): RequestArgs {
            // verify required parameter 'calendar' is not null or undefined
            if (calendar === null || calendar === undefined) {
                throw new RequiredError('calendar','Required parameter calendar was null or undefined when calling saveCalendar.');
            }
            const localVarPath = `/calendars`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Calendar" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(calendar || {}) : (calendar || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a calendar entry to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
         * @param {CalendarEntry} calendarEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendarEntry(calendarEntry: CalendarEntry, options: any = {}): RequestArgs {
            // verify required parameter 'calendarEntry' is not null or undefined
            if (calendarEntry === null || calendarEntry === undefined) {
                throw new RequiredError('calendarEntry','Required parameter calendarEntry was null or undefined when calling saveCalendarEntry.');
            }
            const localVarPath = `/calendars/entries`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CalendarEntry" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(calendarEntry || {}) : (calendarEntry || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options: any = {}): RequestArgs {
            // verify required parameter 'dutyManagerReport' is not null or undefined
            if (dutyManagerReport === null || dutyManagerReport === undefined) {
                throw new RequiredError('dutyManagerReport','Required parameter dutyManagerReport was null or undefined when calling saveDutyManagerReport.');
            }
            const localVarPath = `/reports/dutymanager`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DutyManagerReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dutyManagerReport || {}) : (dutyManagerReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendar(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).deleteCalendar(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEntry(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).deleteCalendarEntry(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDutyManagerReport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).deleteDutyManagerReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Calendar> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).getCalendarById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEntryById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntry> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).getCalendarEntryById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReport> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).getDutyManagerReportById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsForCalendarEntry(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DutyManagerReportListing>> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).getDutyManagerReportsForCalendarEntry(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReportPagedResponse> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Get all of the calendar entries that a user is booked on between some dates. Unlike the normal list method, this will return all entries that in any way overlap the provided date range, not just events that start and finish within the range.
         * @summary Get all of the calendar entries that a user is booked on between some dates.
         * @param {UserEntriesRequest} userEntriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEntries(userEntriesRequest: UserEntriesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEntry>> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).getUserEntries(userEntriesRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a list of all calendar entries that meet the provided requirements
         * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarEntries(calendarEntryListRequest: CalendarEntryListRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEntry>> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).listCalendarEntries(calendarEntryListRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a list of all calendars in the system, core fields only
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarSummary(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarSummary>> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).listCalendarSummary(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a list of all calendars in the system, as well as general stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarSummaryStats(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarSummaryStats>> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).listCalendarSummaryStats(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a calendar to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
         * @param {Calendar} calendar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendar(calendar: Calendar, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Calendar> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).saveCalendar(calendar, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a calendar entry to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
         * @param {CalendarEntry} calendarEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendarEntry(calendarEntry: CalendarEntry, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntry> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).saveCalendarEntry(calendarEntry, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReport> {
            const localVarAxiosArgs = CalendarApiAxiosParamCreator(configuration).saveDutyManagerReport(dutyManagerReport, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendar(id: number, options?: any) {
            return CalendarApiFp(configuration).deleteCalendar(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEntry(id: number, options?: any) {
            return CalendarApiFp(configuration).deleteCalendarEntry(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDutyManagerReport(id: number, options?: any) {
            return CalendarApiFp(configuration).deleteDutyManagerReport(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarById(id: number, options?: any) {
            return CalendarApiFp(configuration).getCalendarById(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEntryById(id: number, options?: any) {
            return CalendarApiFp(configuration).getCalendarEntryById(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportById(id: number, options?: any) {
            return CalendarApiFp(configuration).getDutyManagerReportById(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsForCalendarEntry(id: number, options?: any) {
            return CalendarApiFp(configuration).getDutyManagerReportsForCalendarEntry(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: any) {
            return CalendarApiFp(configuration).getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options)(axios, basePath);
        },
        /**
         * Get all of the calendar entries that a user is booked on between some dates. Unlike the normal list method, this will return all entries that in any way overlap the provided date range, not just events that start and finish within the range.
         * @summary Get all of the calendar entries that a user is booked on between some dates.
         * @param {UserEntriesRequest} userEntriesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEntries(userEntriesRequest: UserEntriesRequest, options?: any) {
            return CalendarApiFp(configuration).getUserEntries(userEntriesRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of all calendar entries that meet the provided requirements
         * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarEntries(calendarEntryListRequest: CalendarEntryListRequest, options?: any) {
            return CalendarApiFp(configuration).listCalendarEntries(calendarEntryListRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of all calendars in the system, core fields only
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarSummary(options?: any) {
            return CalendarApiFp(configuration).listCalendarSummary(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of all calendars in the system, as well as general stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarSummaryStats(options?: any) {
            return CalendarApiFp(configuration).listCalendarSummaryStats(options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a calendar to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
         * @param {Calendar} calendar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendar(calendar: Calendar, options?: any) {
            return CalendarApiFp(configuration).saveCalendar(calendar, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a calendar entry to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
         * @param {CalendarEntry} calendarEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendarEntry(calendarEntry: CalendarEntry, options?: any) {
            return CalendarApiFp(configuration).saveCalendarEntry(calendarEntry, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: any) {
            return CalendarApiFp(configuration).saveDutyManagerReport(dutyManagerReport, options)(axios, basePath);
        },
    };
};

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     * 
     * @summary Delete calendar with the specified ID
     * @param {number} id Unique ID for the calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteCalendar(id: number, options?: any) {
        return CalendarApiFp(this.configuration).deleteCalendar(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete calendar entry with the specified ID
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteCalendarEntry(id: number, options?: any) {
        return CalendarApiFp(this.configuration).deleteCalendarEntry(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a specific duty manager report
     * @param {number} id Unique ID for the duty manager report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteDutyManagerReport(id: number, options?: any) {
        return CalendarApiFp(this.configuration).deleteDutyManagerReport(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a calendar with the specified ID
     * @param {number} id Unique ID for the calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarById(id: number, options?: any) {
        return CalendarApiFp(this.configuration).getCalendarById(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a calendar entry with the specified ID
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarEntryById(id: number, options?: any) {
        return CalendarApiFp(this.configuration).getCalendarEntryById(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific duty manager report
     * @param {number} id Unique ID for the duty manager report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getDutyManagerReportById(id: number, options?: any) {
        return CalendarApiFp(this.configuration).getDutyManagerReportById(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the duty manager reports for a specific calendar entry
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getDutyManagerReportsForCalendarEntry(id: number, options?: any) {
        return CalendarApiFp(this.configuration).getDutyManagerReportsForCalendarEntry(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get duty manager reports list, paged and filtered to specific criteria
     * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: any) {
        return CalendarApiFp(this.configuration).getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options)(this.axios, this.basePath);
    }

    /**
     * Get all of the calendar entries that a user is booked on between some dates. Unlike the normal list method, this will return all entries that in any way overlap the provided date range, not just events that start and finish within the range.
     * @summary Get all of the calendar entries that a user is booked on between some dates.
     * @param {UserEntriesRequest} userEntriesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getUserEntries(userEntriesRequest: UserEntriesRequest, options?: any) {
        return CalendarApiFp(this.configuration).getUserEntries(userEntriesRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of all calendar entries that meet the provided requirements
     * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listCalendarEntries(calendarEntryListRequest: CalendarEntryListRequest, options?: any) {
        return CalendarApiFp(this.configuration).listCalendarEntries(calendarEntryListRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of all calendars in the system, core fields only
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listCalendarSummary(options?: any) {
        return CalendarApiFp(this.configuration).listCalendarSummary(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of all calendars in the system, as well as general stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listCalendarSummaryStats(options?: any) {
        return CalendarApiFp(this.configuration).listCalendarSummaryStats(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a calendar to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
     * @param {Calendar} calendar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public saveCalendar(calendar: Calendar, options?: any) {
        return CalendarApiFp(this.configuration).saveCalendar(calendar, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a calendar entry to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
     * @param {CalendarEntry} calendarEntry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public saveCalendarEntry(calendarEntry: CalendarEntry, options?: any) {
        return CalendarApiFp(this.configuration).saveCalendarEntry(calendarEntry, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a report made by a duty manager about an event (calendar entry)
     * @param {DutyManagerReport} dutyManagerReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: any) {
        return CalendarApiFp(this.configuration).saveDutyManagerReport(dutyManagerReport, options)(this.axios, this.basePath);
    }

}

/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get binary file data previously saved to the server
         * @param {string} filename Filename of the desired file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(filename: string, options: any = {}): RequestArgs {
            // verify required parameter 'filename' is not null or undefined
            if (filename === null || filename === undefined) {
                throw new RequiredError('filename','Required parameter filename was null or undefined when calling getFile.');
            }
            const localVarPath = `/file/storage/file`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get binary file data previously saved to the server
         * @param {string} filename Filename of the desired file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(filename: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = FileApiAxiosParamCreator(configuration).getFile(filename, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get binary file data previously saved to the server
         * @param {string} filename Filename of the desired file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(filename: string, options?: any) {
            return FileApiFp(configuration).getFile(filename, options)(axios, basePath);
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Get binary file data previously saved to the server
     * @param {string} filename Filename of the desired file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFile(filename: string, options?: any) {
        return FileApiFp(this.configuration).getFile(filename, options)(this.axios, this.basePath);
    }

}

/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDutyManagerReport(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDutyManagerReport.');
            }
            const localVarPath = `/reports/dutymanager/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportById(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDutyManagerReportById.');
            }
            const localVarPath = `/reports/dutymanager/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsForCalendarEntry(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDutyManagerReportsForCalendarEntry.');
            }
            const localVarPath = `/reports/dutymanager/forcalentry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options: any = {}): RequestArgs {
            // verify required parameter 'dutyManagerReportPagedRequest' is not null or undefined
            if (dutyManagerReportPagedRequest === null || dutyManagerReportPagedRequest === undefined) {
                throw new RequiredError('dutyManagerReportPagedRequest','Required parameter dutyManagerReportPagedRequest was null or undefined when calling getDutyManagerReportsPaged.');
            }
            const localVarPath = `/reports/dutymanager/paged`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DutyManagerReportPagedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dutyManagerReportPagedRequest || {}) : (dutyManagerReportPagedRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an overview of all the entries for a specific calendar in a specific time range
         * @param {EventsReportRequest} eventsReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsReport(eventsReportRequest: EventsReportRequest, options: any = {}): RequestArgs {
            // verify required parameter 'eventsReportRequest' is not null or undefined
            if (eventsReportRequest === null || eventsReportRequest === undefined) {
                throw new RequiredError('eventsReportRequest','Required parameter eventsReportRequest was null or undefined when calling getEventsReport.');
            }
            const localVarPath = `/reports/events`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EventsReportRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(eventsReportRequest || {}) : (eventsReportRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary For a specific member of staff, all of the entries the staff member is booked on for a date range and whether they've attended or not
         * @param {StaffReportRequest} staffReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffMemberAssignmentsReport(staffReportRequest: StaffReportRequest, options: any = {}): RequestArgs {
            // verify required parameter 'staffReportRequest' is not null or undefined
            if (staffReportRequest === null || staffReportRequest === undefined) {
                throw new RequiredError('staffReportRequest','Required parameter staffReportRequest was null or undefined when calling getStaffMemberAssignmentsReport.');
            }
            const localVarPath = `/reports/staff`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StaffReportRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(staffReportRequest || {}) : (staffReportRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the calculated wages for all staff across all calendars for a specific time period
         * @param {StaffWageReportRequest} staffWageReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffWageReport(staffWageReportRequest: StaffWageReportRequest, options: any = {}): RequestArgs {
            // verify required parameter 'staffWageReportRequest' is not null or undefined
            if (staffWageReportRequest === null || staffWageReportRequest === undefined) {
                throw new RequiredError('staffWageReportRequest','Required parameter staffWageReportRequest was null or undefined when calling getStaffWageReport.');
            }
            const localVarPath = `/reports/staffwage`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StaffWageReportRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(staffWageReportRequest || {}) : (staffWageReportRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options: any = {}): RequestArgs {
            // verify required parameter 'dutyManagerReport' is not null or undefined
            if (dutyManagerReport === null || dutyManagerReport === undefined) {
                throw new RequiredError('dutyManagerReport','Required parameter dutyManagerReport was null or undefined when calling saveDutyManagerReport.');
            }
            const localVarPath = `/reports/dutymanager`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DutyManagerReport" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(dutyManagerReport || {}) : (dutyManagerReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDutyManagerReport(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).deleteDutyManagerReport(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReport> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getDutyManagerReportById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsForCalendarEntry(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DutyManagerReportListing>> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getDutyManagerReportsForCalendarEntry(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReportPagedResponse> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get an overview of all the entries for a specific calendar in a specific time range
         * @param {EventsReportRequest} eventsReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsReport(eventsReportRequest: EventsReportRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsReportResponse> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getEventsReport(eventsReportRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary For a specific member of staff, all of the entries the staff member is booked on for a date range and whether they've attended or not
         * @param {StaffReportRequest} staffReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffMemberAssignmentsReport(staffReportRequest: StaffReportRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffReportResponse> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getStaffMemberAssignmentsReport(staffReportRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get the calculated wages for all staff across all calendars for a specific time period
         * @param {StaffWageReportRequest} staffWageReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffWageReport(staffWageReportRequest: StaffWageReportRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffWageReportResponse> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).getStaffWageReport(staffWageReportRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReport> {
            const localVarAxiosArgs = ReportsApiAxiosParamCreator(configuration).saveDutyManagerReport(dutyManagerReport, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDutyManagerReport(id: number, options?: any) {
            return ReportsApiFp(configuration).deleteDutyManagerReport(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportById(id: number, options?: any) {
            return ReportsApiFp(configuration).getDutyManagerReportById(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsForCalendarEntry(id: number, options?: any) {
            return ReportsApiFp(configuration).getDutyManagerReportsForCalendarEntry(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: any) {
            return ReportsApiFp(configuration).getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get an overview of all the entries for a specific calendar in a specific time range
         * @param {EventsReportRequest} eventsReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsReport(eventsReportRequest: EventsReportRequest, options?: any) {
            return ReportsApiFp(configuration).getEventsReport(eventsReportRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary For a specific member of staff, all of the entries the staff member is booked on for a date range and whether they've attended or not
         * @param {StaffReportRequest} staffReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffMemberAssignmentsReport(staffReportRequest: StaffReportRequest, options?: any) {
            return ReportsApiFp(configuration).getStaffMemberAssignmentsReport(staffReportRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the calculated wages for all staff across all calendars for a specific time period
         * @param {StaffWageReportRequest} staffWageReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffWageReport(staffWageReportRequest: StaffWageReportRequest, options?: any) {
            return ReportsApiFp(configuration).getStaffWageReport(staffWageReportRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: any) {
            return ReportsApiFp(configuration).saveDutyManagerReport(dutyManagerReport, options)(axios, basePath);
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Delete a specific duty manager report
     * @param {number} id Unique ID for the duty manager report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteDutyManagerReport(id: number, options?: any) {
        return ReportsApiFp(this.configuration).deleteDutyManagerReport(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a specific duty manager report
     * @param {number} id Unique ID for the duty manager report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getDutyManagerReportById(id: number, options?: any) {
        return ReportsApiFp(this.configuration).getDutyManagerReportById(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the duty manager reports for a specific calendar entry
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getDutyManagerReportsForCalendarEntry(id: number, options?: any) {
        return ReportsApiFp(this.configuration).getDutyManagerReportsForCalendarEntry(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get duty manager reports list, paged and filtered to specific criteria
     * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: any) {
        return ReportsApiFp(this.configuration).getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get an overview of all the entries for a specific calendar in a specific time range
     * @param {EventsReportRequest} eventsReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getEventsReport(eventsReportRequest: EventsReportRequest, options?: any) {
        return ReportsApiFp(this.configuration).getEventsReport(eventsReportRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary For a specific member of staff, all of the entries the staff member is booked on for a date range and whether they've attended or not
     * @param {StaffReportRequest} staffReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getStaffMemberAssignmentsReport(staffReportRequest: StaffReportRequest, options?: any) {
        return ReportsApiFp(this.configuration).getStaffMemberAssignmentsReport(staffReportRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the calculated wages for all staff across all calendars for a specific time period
     * @param {StaffWageReportRequest} staffWageReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getStaffWageReport(staffWageReportRequest: StaffWageReportRequest, options?: any) {
        return ReportsApiFp(this.configuration).getStaffWageReport(staffWageReportRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a report made by a duty manager about an event (calendar entry)
     * @param {DutyManagerReport} dutyManagerReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: any) {
        return ReportsApiFp(this.configuration).saveDutyManagerReport(dutyManagerReport, options)(this.axios, this.basePath);
    }

}

/**
 * StaffApi - axios parameter creator
 * @export
 */
export const StaffApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change the user's assignment state for WaitingAccept to Accepted. Uses the user's auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAssignment(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling acceptAssignment.');
            }
            const localVarPath = `/staff/actions/acceptassignment`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the user's assignment state for Accepted to BriefingAccepted. Uses the user's auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptBriefing(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling acceptBriefing.');
            }
            const localVarPath = `/staff/actions/acceptbriefing`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If the state is 'WaitingAccept' the next step is to accept/reject the assignment. If the state is 'Accepted' the next step is to accept the briefing document.
         * @summary Get all of the entries the user has to either accept, or accept the briefing doc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingActions(options: any = {}): RequestArgs {
            const localVarPath = `/staff/actions/pending`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a user on WaitingAccept from the entry entirely. Uses the user's auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAssignment(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling rejectAssignment.');
            }
            const localVarPath = `/staff/actions/rejectassignment`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend notifications for a specific user for a specific calendar entry
         * @param {ResendUserNotificationsRequest} [resendUserNotificationsRequest] Information about the user and calendar entry to send notifications for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserNotifications(resendUserNotificationsRequest?: ResendUserNotificationsRequest, options: any = {}): RequestArgs {
            const localVarPath = `/staff/actions/notifications/resend`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResendUserNotificationsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resendUserNotificationsRequest || {}) : (resendUserNotificationsRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set one or more staff as having attended the event
         * @param {MarkAttendedRequest} [markAttendedRequest] Staff that attended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaffAttended(markAttendedRequest?: MarkAttendedRequest, options: any = {}): RequestArgs {
            const localVarPath = `/staff/actions/attended`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MarkAttendedRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(markAttendedRequest || {}) : (markAttendedRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaffApi - functional programming interface
 * @export
 */
export const StaffApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change the user's assignment state for WaitingAccept to Accepted. Uses the user's auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAssignment(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = StaffApiAxiosParamCreator(configuration).acceptAssignment(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Change the user's assignment state for Accepted to BriefingAccepted. Uses the user's auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptBriefing(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = StaffApiAxiosParamCreator(configuration).acceptBriefing(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * If the state is 'WaitingAccept' the next step is to accept/reject the assignment. If the state is 'Accepted' the next step is to accept the briefing document.
         * @summary Get all of the entries the user has to either accept, or accept the briefing doc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingActions(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StaffPendingAction>> {
            const localVarAxiosArgs = StaffApiAxiosParamCreator(configuration).getPendingActions(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Remove a user on WaitingAccept from the entry entirely. Uses the user's auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAssignment(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = StaffApiAxiosParamCreator(configuration).rejectAssignment(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Resend notifications for a specific user for a specific calendar entry
         * @param {ResendUserNotificationsRequest} [resendUserNotificationsRequest] Information about the user and calendar entry to send notifications for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserNotifications(resendUserNotificationsRequest?: ResendUserNotificationsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = StaffApiAxiosParamCreator(configuration).resendUserNotifications(resendUserNotificationsRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Set one or more staff as having attended the event
         * @param {MarkAttendedRequest} [markAttendedRequest] Staff that attended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaffAttended(markAttendedRequest?: MarkAttendedRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntry> {
            const localVarAxiosArgs = StaffApiAxiosParamCreator(configuration).setStaffAttended(markAttendedRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * StaffApi - factory interface
 * @export
 */
export const StaffApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Change the user's assignment state for WaitingAccept to Accepted. Uses the user's auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAssignment(id: number, options?: any) {
            return StaffApiFp(configuration).acceptAssignment(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Change the user's assignment state for Accepted to BriefingAccepted. Uses the user's auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptBriefing(id: number, options?: any) {
            return StaffApiFp(configuration).acceptBriefing(id, options)(axios, basePath);
        },
        /**
         * If the state is 'WaitingAccept' the next step is to accept/reject the assignment. If the state is 'Accepted' the next step is to accept the briefing document.
         * @summary Get all of the entries the user has to either accept, or accept the briefing doc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingActions(options?: any) {
            return StaffApiFp(configuration).getPendingActions(options)(axios, basePath);
        },
        /**
         * 
         * @summary Remove a user on WaitingAccept from the entry entirely. Uses the user's auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAssignment(id: number, options?: any) {
            return StaffApiFp(configuration).rejectAssignment(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Resend notifications for a specific user for a specific calendar entry
         * @param {ResendUserNotificationsRequest} [resendUserNotificationsRequest] Information about the user and calendar entry to send notifications for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserNotifications(resendUserNotificationsRequest?: ResendUserNotificationsRequest, options?: any) {
            return StaffApiFp(configuration).resendUserNotifications(resendUserNotificationsRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Set one or more staff as having attended the event
         * @param {MarkAttendedRequest} [markAttendedRequest] Staff that attended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaffAttended(markAttendedRequest?: MarkAttendedRequest, options?: any) {
            return StaffApiFp(configuration).setStaffAttended(markAttendedRequest, options)(axios, basePath);
        },
    };
};

/**
 * StaffApi - object-oriented interface
 * @export
 * @class StaffApi
 * @extends {BaseAPI}
 */
export class StaffApi extends BaseAPI {
    /**
     * 
     * @summary Change the user's assignment state for WaitingAccept to Accepted. Uses the user's auth token to know which assignment.
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public acceptAssignment(id: number, options?: any) {
        return StaffApiFp(this.configuration).acceptAssignment(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Change the user's assignment state for Accepted to BriefingAccepted. Uses the user's auth token to know which assignment.
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public acceptBriefing(id: number, options?: any) {
        return StaffApiFp(this.configuration).acceptBriefing(id, options)(this.axios, this.basePath);
    }

    /**
     * If the state is 'WaitingAccept' the next step is to accept/reject the assignment. If the state is 'Accepted' the next step is to accept the briefing document.
     * @summary Get all of the entries the user has to either accept, or accept the briefing doc.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public getPendingActions(options?: any) {
        return StaffApiFp(this.configuration).getPendingActions(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Remove a user on WaitingAccept from the entry entirely. Uses the user's auth token to know which assignment.
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public rejectAssignment(id: number, options?: any) {
        return StaffApiFp(this.configuration).rejectAssignment(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Resend notifications for a specific user for a specific calendar entry
     * @param {ResendUserNotificationsRequest} [resendUserNotificationsRequest] Information about the user and calendar entry to send notifications for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public resendUserNotifications(resendUserNotificationsRequest?: ResendUserNotificationsRequest, options?: any) {
        return StaffApiFp(this.configuration).resendUserNotifications(resendUserNotificationsRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Set one or more staff as having attended the event
     * @param {MarkAttendedRequest} [markAttendedRequest] Staff that attended
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public setStaffAttended(markAttendedRequest?: MarkAttendedRequest, options?: any) {
        return StaffApiFp(this.configuration).setStaffAttended(markAttendedRequest, options)(this.axios, this.basePath);
    }

}

/**
 * VenueApi - axios parameter creator
 * @export
 */
export const VenueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVenue(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteVenue.');
            }
            const localVarPath = `/venues/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of ALL venues in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVenues(options: any = {}): RequestArgs {
            const localVarPath = `/venues/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueById(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVenueById.');
            }
            const localVarPath = `/venues/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of ALL venues assigned to the provided calendar ID
         * @param {number} id Unique ID for the calendar ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenuesForCalendarId(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getVenuesForCalendarId.');
            }
            const localVarPath = `/venues/forcalendar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a venue to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
         * @param {Venue} venue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVenue(venue: Venue, options: any = {}): RequestArgs {
            // verify required parameter 'venue' is not null or undefined
            if (venue === null || venue === undefined) {
                throw new RequiredError('venue','Required parameter venue was null or undefined when calling saveVenue.');
            }
            const localVarPath = `/venues`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Venue" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(venue || {}) : (venue || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VenueApi - functional programming interface
 * @export
 */
export const VenueApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVenue(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = VenueApiAxiosParamCreator(configuration).deleteVenue(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a list of ALL venues in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVenues(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Venue>> {
            const localVarAxiosArgs = VenueApiAxiosParamCreator(configuration).getAllVenues(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Venue> {
            const localVarAxiosArgs = VenueApiAxiosParamCreator(configuration).getVenueById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a list of ALL venues assigned to the provided calendar ID
         * @param {number} id Unique ID for the calendar ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenuesForCalendarId(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Venue>> {
            const localVarAxiosArgs = VenueApiAxiosParamCreator(configuration).getVenuesForCalendarId(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a venue to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
         * @param {Venue} venue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVenue(venue: Venue, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Venue> {
            const localVarAxiosArgs = VenueApiAxiosParamCreator(configuration).saveVenue(venue, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * VenueApi - factory interface
 * @export
 */
export const VenueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVenue(id: number, options?: any) {
            return VenueApiFp(configuration).deleteVenue(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of ALL venues in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVenues(options?: any) {
            return VenueApiFp(configuration).getAllVenues(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueById(id: number, options?: any) {
            return VenueApiFp(configuration).getVenueById(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a list of ALL venues assigned to the provided calendar ID
         * @param {number} id Unique ID for the calendar ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenuesForCalendarId(id: number, options?: any) {
            return VenueApiFp(configuration).getVenuesForCalendarId(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a venue to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
         * @param {Venue} venue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVenue(venue: Venue, options?: any) {
            return VenueApiFp(configuration).saveVenue(venue, options)(axios, basePath);
        },
    };
};

/**
 * VenueApi - object-oriented interface
 * @export
 * @class VenueApi
 * @extends {BaseAPI}
 */
export class VenueApi extends BaseAPI {
    /**
     * 
     * @summary Delete venue with the specified ID
     * @param {number} id Unique ID for the venue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public deleteVenue(id: number, options?: any) {
        return VenueApiFp(this.configuration).deleteVenue(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of ALL venues in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public getAllVenues(options?: any) {
        return VenueApiFp(this.configuration).getAllVenues(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a venue with the specified ID
     * @param {number} id Unique ID for the venue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public getVenueById(id: number, options?: any) {
        return VenueApiFp(this.configuration).getVenueById(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a list of ALL venues assigned to the provided calendar ID
     * @param {number} id Unique ID for the calendar ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public getVenuesForCalendarId(id: number, options?: any) {
        return VenueApiFp(this.configuration).getVenuesForCalendarId(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a venue to the DB. If the ID is 0 it will be added, if it is greater than 0 it'll be updated
     * @param {Venue} venue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public saveVenue(venue: Venue, options?: any) {
        return VenueApiFp(this.configuration).saveVenue(venue, options)(this.axios, this.basePath);
    }

}

