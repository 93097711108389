import {
    StoreServiceData,
    ServiceActionTypes,
    createReducer,
    createDefaultStoreState
} from "store-fetch-wrappers/dist";
import {TOTP_STORE_STATE, TotpWithQrCode} from "../actions/TotpActionTypes";

const defaultState = createDefaultStoreState<TotpWithQrCode>(null);

const totpReducer = (
    state: StoreServiceData<TotpWithQrCode> = defaultState,
    action: ServiceActionTypes<TotpWithQrCode>
): StoreServiceData<TotpWithQrCode> => createReducer(state, action, TOTP_STORE_STATE);

export default totpReducer;
