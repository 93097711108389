import {DefaultApi as DrugApi} from "../../api/mm";

export default class MedicineManagementApiModel {
    private static instance: MedicineManagementApiModel;
    private static drugApi: DrugApi;

    public static getDrugApi(): DrugApi {
        if (!MedicineManagementApiModel.drugApi) {
            MedicineManagementApiModel.drugApi = new DrugApi({
                basePath: process.env.REACT_APP_MM_API
            });
        }

        return MedicineManagementApiModel.drugApi;
    }
}
