import React, {useEffect} from "react";

import {useUserData} from "../../../../Hooks/useUserData";
import SectionContainer from "../../../../Containers/SectionContainer";
import {buildInitialCalendarEntryListRequest} from "./Helpers/staffCalendarHelpers";
import {useDispatch, useSelector} from "react-redux";
import {getEntriesFromService} from "../../../../../store/grs/calendarEntryList/actions/CalendarEntryListActions";
import MiniCalendar from "./Components/MiniCalendar";
import {WithServiceState} from "store-fetch-wrappers";
import {RootStore} from "../../../../../store/Store";

const ServiceWrapper = WithServiceState(MiniCalendar);

const StaffCalendar = () => {
    const user = useUserData();
    const dispatch = useDispatch();

    const entriesStore = useSelector((state: RootStore) => state.calendarEntryList);

    useEffect(() => {
        if (user.username.length === 0) return;

        const listRequest = buildInitialCalendarEntryListRequest(user.username);
        dispatch(getEntriesFromService(listRequest));
    }, [user]);

    return (
        <React.Fragment>
            <SectionContainer>
                <div className="row pt-3 ml-0 mr-0">
                    <h6>
                        <b className="container-info-title header-font">Calendar</b>
                    </h6>
                </div>
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"normal"}
                    placeholderBlockCount={1}
                    loaderWheelType={"three-ring"}
                    {...entriesStore}
                />
            </SectionContainer>
        </React.Fragment>
    );
};

export default StaffCalendar;
