import {AuditTypeStats, PrfStatsBase} from "../../../../api/prf";
import {getOverallCompliancePercentage, StatisticsItem} from "../../helpers/statisticsHelpers";
import {
    getAngleForHalfPieChartFromPercentage,
    getFlooredPercentage
} from "../../../../utils/mathUtils";

/** Generate the overall compliance of the system */
export function generateOverallAuditComplianceStats(auditStats: AuditTypeStats[]): StatisticsItem {
    const prfStatBase = getBlankPrfStatsItem();

    for (const stat of auditStats) {
        prfStatBase.totalNumberOfAudits += stat.totalNumberOfAudits;
        prfStatBase.numberOfAudits100PercentCompliant += stat.numberOfAudits100PercentCompliant;
        prfStatBase.numberOfAudits90to99PercentCompliant +=
            stat.numberOfAudits90to99PercentCompliant;
        prfStatBase.numberOfAuditsBelow90PercentCompliant +=
            stat.numberOfAuditsBelow90PercentCompliant;
    }

    return getStatisticsItemFromPrfStats(prfStatBase);
}

export function getStatisticsItemFromPrfStats(stats: PrfStatsBase): StatisticsItem {
    const {totalNumberOfAudits} = stats;
    if (totalNumberOfAudits === 0) {
        return defaultPrfStats;
    }
    const percentage = getOverallCompliancePercentFromPrfStats(stats);
    return {
        totalValue: totalNumberOfAudits,
        tooltipText: getPrfStatsTooltipText(percentage),
        needleAngle: getAngleForHalfPieChartFromPercentage(percentage),
        outerLabel: "PRF",
        compliancePercentage: percentage
    };
}

function getOverallCompliancePercentFromPrfStats({
    numberOfAudits90to99PercentCompliant,
    numberOfAuditsBelow90PercentCompliant,
    totalNumberOfAudits
}: PrfStatsBase): number {
    const amberPercent = getFlooredPercentage(
        numberOfAudits90to99PercentCompliant,
        totalNumberOfAudits
    );
    const redPercent = getFlooredPercentage(
        numberOfAuditsBelow90PercentCompliant,
        totalNumberOfAudits
    );
    return getOverallCompliancePercentage(amberPercent, redPercent);
}

function getPrfStatsTooltipText(percentage: number): string {
    return `Overall PRF Audit Compliance: ${percentage}%`;
}

function getBlankPrfStatsItem(): PrfStatsBase {
    return {
        totalNumberOfAudits: 0,
        numberOfAudits100PercentCompliant: 0,
        numberOfAudits90to99PercentCompliant: 0,
        numberOfAuditsBelow90PercentCompliant: 0
    };
}

const defaultPrfStats: StatisticsItem = {
    needleAngle: 0,
    compliancePercentage: 0,
    totalValue: 0,
    outerLabel: "PRF",
    tooltipText: "Overall PRF Audit Compliance: 0%"
};
