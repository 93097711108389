import {DrugBatch} from "../../../../api/mm";
import moment, {Moment} from "moment";
import {StatisticsItem} from "../../helpers/statisticsHelpers";
import {
    getAngleForHalfPieChartFromPercentage,
    getFlooredPercentage
} from "../../../../utils/mathUtils";

/** Generate the overall compliance of the system */
export function generateActiveDrugBatchStats(
    drugBatches: DrugBatch[],
    now?: Moment
): StatisticsItem {
    const expiringDrugs = getDrugsExpiringInTheNextMonth(drugBatches, now);
    return getStatisticsItemFromMMStats(expiringDrugs, drugBatches);
}

function getDrugsExpiringInTheNextMonth(drugBatches: DrugBatch[], fixedNow?: Moment): DrugBatch[] {
    const expiringInMonth: DrugBatch[] = [];
    const now = fixedNow ? fixedNow : moment();
    const oneMonthInFuture = now.clone().add(1, "month");

    //Separates the drugs expiring in a week and a month from the active drug batches.
    for (const drug of drugBatches) {
        if (drug.expiryDate > now.unix() && drug.expiryDate < oneMonthInFuture.unix()) {
            expiringInMonth.push(drug);
        }
    }

    return expiringInMonth;
}
export function getStatisticsItemFromMMStats(
    expiringDrugs: DrugBatch[],
    activeDrugs: DrugBatch[]
): StatisticsItem {
    const compliancePercentage = getMMCompliancePercentage(expiringDrugs, activeDrugs);
    return {
        totalValue: activeDrugs.length,
        tooltipText: getMMStatsTooltipText(
            compliancePercentage,
            expiringDrugs.length,
            activeDrugs.length
        ),
        needleAngle: getAngleForHalfPieChartFromPercentage(compliancePercentage),
        outerLabel: "Medicine Management",
        compliancePercentage
    };
}

function getMMStatsTooltipText(
    percentage: number,
    numberOfExpiringDrugs: number,
    numberOfActiveDrugs: number
): string {
    const drugStat = `(${numberOfExpiringDrugs} / ${numberOfActiveDrugs})`;
    // return `Overall Policy Suite Compliance: ${percentage}%`;
    return `Number of drugs expiring in the next 30 days ${drugStat}`;
}

// We want the opposite to happen here as the less drugs that are expiring, the more compliant the stat is
function getMMCompliancePercentage(expiringDrugs: DrugBatch[], activeDrugs: DrugBatch[]) {
    return 100 - getFlooredPercentage(expiringDrugs.length, activeDrugs.length);
}
