import {StoreServiceData, ServiceActionTypes, createDefaultStoreState} from "store-fetch-wrappers";
import {VIEW_STATS_STORE_STATE} from "../actions/DocumentViewStatsActionTypes";
import {StatisticsItem} from "../../../helpers/statisticsHelpers";
import {showErrorToast} from "../../../../../utils/toastUtils";
import {createReducer} from "store-fetch-wrappers/dist";

const defaultState = createDefaultStoreState<StatisticsItem>({
    needleAngle: 0,
    totalValue: 0,
    outerLabel: "Policy suite",
    compliancePercentage: 0,
    tooltipText: "Overall Policy Suite Compliance: 0%"
});

const documentViewStatsReducer = (
    state: StoreServiceData<StatisticsItem> = defaultState,
    action: ServiceActionTypes<StatisticsItem>
): StoreServiceData<StatisticsItem> =>
    createReducer(state, action, VIEW_STATS_STORE_STATE, () => showErrorToast(action.error));

export default documentViewStatsReducer;
