import GrsApiModel from "../../../apiModel/GrsApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {Dispatch} from "redux";
import {
    STAFF_WAGE_REPORT_STORE_STATE,
    StaffWageReportDispatchTypes
} from "./StaffWageReportActionTypes";
import {StaffWageReportRequest} from "../../../../api/grs";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

export const nullifyStaffWageReportStore = () => {
    return async (dispatch: Dispatch<StaffWageReportDispatchTypes>) => {
        dispatch({
            type: STAFF_WAGE_REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const getStaffWageReportFromService = (request: StaffWageReportRequest) => {
    return async (dispatch: Dispatch<StaffWageReportDispatchTypes>) => {
        try {
            await postDataToServiceWithRedux(
                STAFF_WAGE_REPORT_STORE_STATE,
                dispatch,
                () => GrsApiModel.getReportsApi().getStaffWageReport(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: STAFF_WAGE_REPORT_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
