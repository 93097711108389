import React from "react";
import useWindowSize from "../../Hooks/useWindowResize";
import {showSuccessToast} from "../../../utils/toastUtils";
import {FaRegCopy} from "react-icons/fa";

function SecretCodeStep1({secretCode}: Props) {
    const {width} = useWindowSize();

    function getLocationText() {
        if (width > 768) {
            return "When reaching the screen similar to the image on the right,";
        }
        return "When reaching the screen similar to the image above,";
    }
    return (
        <React.Fragment>
            <p className="mt-4">
                <b className="header-font">Step 1</b>
            </p>
            <p>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                In your authenticator app, select the <b>"Enter a setup key".</b>{" "}
                {getLocationText()} enter the following and click add:
            </p>
            <ul className="body-font">
                <li className="mb-2">
                    <b>Account Name:</b> Medicare EMS
                </li>
                <li
                    className="mb-2"
                    onClick={() => {
                        navigator.clipboard.writeText(secretCode).then(() => {
                            showSuccessToast("Copied secret code to clipboard");
                        });
                    }}
                >
                    <b>Your key:</b>
                    <span className="secret-key">
                        {" "}
                        {secretCode} <FaRegCopy className={"ml-2"} />
                    </span>
                </li>
                <li className="mb-2">
                    <b>Type of key:</b> Time based
                </li>
            </ul>
        </React.Fragment>
    );
}

export default SecretCodeStep1;

interface Props {
    secretCode: string;
}
