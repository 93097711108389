import {ReportPageRequest, ReportPageResponse, ReportState} from "../../../api/im";
import ImApiModel from "../../apiModel/ImApiModel";
import moment from "moment";
import {StaffPendingAction} from "../../../api/grs";
import GrsApiModel from "../../apiModel/GrsApiModel";
import PrfApiModel from "../../apiModel/PrfApiModel";
import {AuditListRequest, AuditListResponse} from "../../../api/prf";
import {DocumentViewRecordWithDocument} from "../../../api/ppb";
import PpbApiModel from "../../apiModel/PpbApiModel";
import {DrugAuditPagedRequest, DrugAuditPagedResponse} from "../../../api/mm";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {showErrorToast} from "../../../utils/toastUtils";
import {getUiFriendlyText} from "../../../utils/textUtils";

export async function fetchReportListing(
    reportState: ReportState,
    username?: string
): Promise<ReportPageResponse | null | undefined> {
    const reportPageRequest = generateReportPageRequest(reportState, username);
    try {
        const request = await ImApiModel.getReportsApi().getReportsPage(reportPageRequest);
        return request.data;
    } catch (e: any) {
        showErrorToast(`Could not get ${getUiFriendlyText(reportState)} reports notifications.`);
        return null;
    }
}

export async function getPendingNotifications(): Promise<StaffPendingAction[]> {
    try {
        const request = await GrsApiModel.getStaffApi().getPendingActions();
        return request.data;
    } catch (e: any) {
        showErrorToast(`Could not get pending notifications.`);
        return [];
    }
}

export async function getPagedAudits(
    username: string
): Promise<AuditListResponse | null | undefined> {
    const pagedAuditRequest = generatePagedAuditRequest(username);
    try {
        const request = await PrfApiModel.getPrfApi().getAuditsListPaged(pagedAuditRequest);
        return request.data;
    } catch (e: any) {
        showErrorToast(`Could not get completed PRF audits notifications.`);
        return null;
    }
}

export async function fetchDocumentsForStaffMember(
    username: string
): Promise<DocumentViewRecordWithDocument[]> {
    try {
        const request = await PpbApiModel.getDocsApi().getViewsForStaffMember(username);
        return request.data;
    } catch (e: any) {
        showErrorToast(`Could not get updated and new documents notifications.`);
        return [];
    }
}
export async function fetchDrugAuditsForCurrentMonth(): Promise<
    DrugAuditPagedResponse | null | undefined
> {
    try {
        const request = await MedicineManagementApiModel.getDrugApi().getDrugAuditsPaged(
            generateAuditPagedRequest()
        );
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return request.data;
    } catch (e: any) {
        //Fail silently
        return null;
    }
}

function generateAuditPagedRequest(): DrugAuditPagedRequest {
    const start = moment();
    return {
        createdDate: {
            startDate: start.clone().startOf("month").unix(),
            endDate: start.clone().endOf("month").unix()
        },
        pageNum: 0,
        numPerPage: 50
    };
}

function generateReportPageRequest(reportState: ReportState, staffId?: string): ReportPageRequest {
    const now = moment().endOf("day").unix();
    const fourWeeksPrior = moment.unix(now).subtract(28, "days").startOf("day").unix();
    return {
        pageNum: 0,
        numPerPage: 1000,
        staffId,
        dateCreatedStartInclusive: fourWeeksPrior,
        dateCreatedEndExclusive: now,
        reportState
    };
}

function generatePagedAuditRequest(clinicianId: string): AuditListRequest {
    const now = moment().endOf("day").unix();
    const fourWeeksPrior = moment.unix(now).subtract(28, "days").startOf("day").unix();
    return {
        pageNum: 0,
        numPerPage: 500,
        clinicianId,
        auditTypes: [],
        creationDate: {
            startDate: fourWeeksPrior,
            endDate: now
        }
    };
}
