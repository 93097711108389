import React from "react";
import {useTotpContainer} from "./Hooks/useTotpContainer";
import TotpSetup from "../../Totp/TotpSetup";
import {WithServiceState} from "store-fetch-wrappers";
import TotpError from "../../Totp/TotpError";

const ServiceWrapper = WithServiceState(TotpSetup);

function Totp() {
    const {store} = useTotpContainer();
    return (
        <div className="page-container pt-5">
            {store.error && <TotpError error={store.error} />}
            <ServiceWrapper
                {...store}
                showLoadingText={true}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
            />
        </div>
    );
}

export default Totp;
