import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {ABSENCE_STATS_STORE, AbsenceStatsBlock} from "../actions/AbsenceStatsActionTypes";
import {showErrorToast} from "../../../../../utils/toastUtils";

const defaultState = createDefaultStoreState<AbsenceStatsBlock>(generateBlankAbsenceStatsBlock());

const absenceStatsReducer = (
    state: StoreServiceData<AbsenceStatsBlock> = defaultState,
    action: ServiceActionTypes<AbsenceStatsBlock>
) => createReducer(state, action, ABSENCE_STATS_STORE, () => showErrorToast(action.error));

export default absenceStatsReducer;

export function generateBlankAbsenceStatsBlock(): AbsenceStatsBlock {
    return {
        start: 0,
        end: 0,
        monthName: "",
        statItems: [
            {
                needleAngle: 180,
                compliancePercentage: 100,
                totalValue: 0,
                outerLabel: "StaffHoliday",
                tooltipText: `There are no Staff Holidays for this month.`
            },
            {
                needleAngle: 180,
                compliancePercentage: 100,
                totalValue: 0,
                outerLabel: "StaffSickness",
                tooltipText: `There are no Staff Sickness's for this month.`
            },
            {
                needleAngle: 180,
                compliancePercentage: 100,
                totalValue: 0,
                outerLabel: "UnauthorisedLeave",
                tooltipText: `There are no Unauthorised Leave absences for this month.`
            }
        ]
    };
}
