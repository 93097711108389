import {useUserData} from "../../../Hooks/useUserData";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {getNavItemsForUser} from "../../Dashboard/Helpers/dashboardHelpers";
import {getTotp} from "../../../../store/totp/actions/TotpActions";
import {RootStore} from "../../../../store/Store";
import useMcConfig from "../../../Hooks/useMcConfig";

export function useTotpContainer() {
    const dispatch = useDispatch();
    const user = useUserData();
    const {config} = useMcConfig();

    const store = useSelector((state: RootStore) => state.totp);

    useEffect(() => {
        dispatch(getTotp());
    }, []);

    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;

        dispatch(setNavigationItems(getNavItemsForUser(user, config)));
    }, [user, config]);

    return {
        store
    };
}
