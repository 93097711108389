import React from "react";
import {WageCalculationCostingsRow} from "../Helpers/wageCalculationHelpers";

const WageCalculationTableRow = (props: WageCalculationCostingsRow) => {
    return (
        <tr className="mc-table-row">
            <td className="mc-table-row-item p-3">
                <p>{props.label}</p>
            </td>
            <td align="right" className="mc-table-row-item p-3">
                {props.value}
            </td>
        </tr>
    );
};

export default WageCalculationTableRow;
