import React from "react";
import Card from "../../Cards/Card";
import {FaChevronDown} from "react-icons/fa";

function SecretCodeCard() {
    return (
        <div className="totp-secret-code-card-container">
            <Card
                className="totp-secret-code-card"
                wrapperClassName="totp-secret-code-card-wrapper"
            >
                <div>
                    <h6>Account Details</h6>
                    <div className="mock-input mt-4">Account</div>
                    <div className="mock-input mt-4">Key</div>
                    <div className="key-type-row mt-4">
                        <div className="mock-select">
                            Time Based{" "}
                            <span>
                                <FaChevronDown />
                            </span>
                        </div>
                        <div className="mock-button">Add</div>
                    </div>
                </div>
                <div className="mock-navbar-container">
                    <div className="mock-navbar" />
                </div>
            </Card>
        </div>
    );
}

export default SecretCodeCard;
