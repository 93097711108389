import {StaffAccessLevel, UserData} from "../api/staff";
import {McConfig} from "../McConfigPlugin";

export function getUserFlags(flags: string[] | undefined) {
    //Undefined flags
    if (!flags) return "";

    //If flags exist but the array is empty.
    if (flags.length < 1) {
        return "";
    }

    //If one flag exists and it is more than 0 characters.
    if (flags.length === 1 && flags[0].length > 0) {
        return `(${flags[0]})`;
    }

    //More than 1 flag.
    return `(${flags.join(", ")})`;
}

/** Added in to account for custom ACLs users might have per system.
 * Gets users access level based on module. If there is no data, take
 * default access level and use that*/
export function getUserAccessLevel(user: UserData, config: McConfig) {
    const {moduleName} = config.systemDetails;

    return user.accessDetail![moduleName] || user.accessLevel;
}

// Checks if the user has admin access to the list of Medicare Systems
export function doesUserHaveAdminAccess(system: AccessDetailType, user: UserData) {
    if (user.superAdmin) return true;
    if (user.accessLevel === StaffAccessLevel.SystemAdministrator) return true;
    if (!user.accessDetail) return false;

    return user.accessDetail[system] === StaffAccessLevel.SystemAdministrator;
}

// eslint-disable-next-line no-shadow
export enum AccessDetailType {
    staffmanagement = "staffmanagement",
    grs = "grs",
    dashboard = "dashboard",
    xd2 = "xd2",
    fms = "fms",
    oplog = "oplog",
    prf = "prf",
    cwa = "cwa",
    mm = "mm",
    ppb = "ppb",
    im = "im",
    rm = "rm"
}
