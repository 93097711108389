import React from "react";

function Tick() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <path
                d="M24.78916,49.999999l-7.478017,9.451823L38.874932,81.015611Q97.191818,19.980263,98.551049,21.339494t-6.998027-6.998027L38.874932,59.451822L24.78916,49.999999Z"
                transform="matrix(1.230922 0 0 1.441084-21.308661-18.70878)"
                strokeWidth="0.5"
                fill="currentColor"
            />
        </svg>
    );
}

export default Tick;
