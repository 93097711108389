import React from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {NavigationItem} from "../../../../../../store/navigation/actions/NavigationActionTypes";
import SubSystemHeaderItem from "./SubSystemHeaderItem";
import useMcConfig from "../../../../../Hooks/useMcConfig";
import {useUserData} from "../../../../../Hooks/useUserData";
import {StaffAccessLevel} from "../../../../../../api/staff";
import {Loading} from "store-fetch-wrappers";

const SubSystemHeader = () => {
    const navItems = useSelector((state: RootStore) => state.navigation.data) || [];
    const {config, loading} = useMcConfig();
    const user = useUserData();
    return (
        <div className="sub-system-bar desktop" id="sub-system-header">
            <div className="nav-container h-100">
                <div className="row ml-0 h-100 mr-0">
                    <div className="col-sm-4 pl-0 d-flex align-items-center">
                        {loading ? (
                            <Loading type={"three-ring"} showLoadingText={false} />
                        ) : (
                            <React.Fragment>
                                <p className="mb-0 nav-text">
                                    {user.accessLevel === StaffAccessLevel.SystemAdministrator ? (
                                        <span>{config.systemDetails.elongatedAdminName}</span>
                                    ) : (
                                        <span>{config.systemDetails.elongatedStaffName}</span>
                                    )}
                                </p>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="col ml-auto pl-0 pr-0">
                        <div className="row sub-system-links">
                            <ul className="link-container nav-links-right mb-0">
                                {navItems.map((item: NavigationItem, index: number) => {
                                    return <SubSystemHeaderItem {...item} key={index} />;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubSystemHeader;
