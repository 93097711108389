import {DrugBatch} from "../../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DRUG_BATCH_LIST_STORE = createStoreState("drug_batch_list");

interface DrugBatchListLoading extends StoreServiceData<DrugBatch[]> {
    type: typeof DRUG_BATCH_LIST_STORE.LOADING;
}
interface DrugBatchListError extends StoreServiceData<DrugBatch[]> {
    type: typeof DRUG_BATCH_LIST_STORE.ERROR;
}
interface DrugBatchListSuccess extends StoreServiceData<DrugBatch[]> {
    type: typeof DRUG_BATCH_LIST_STORE.SUCCESS;
}

export type DrugBatchListDispatchTypes =
    | DrugBatchListSuccess
    | DrugBatchListError
    | DrugBatchListLoading;
