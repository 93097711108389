import {DefaultApi as PrfApi} from "../../api/prf";

export default class PrfApiModel {
    private static instance: PrfApiModel;
    private static prfApi: PrfApi;

    public static getInstance(): PrfApiModel {
        if (!PrfApiModel.instance) {
            PrfApiModel.instance = new PrfApiModel();
        }

        return PrfApiModel.instance;
    }

    public static getPrfApi(): PrfApi {
        if (!PrfApiModel.prfApi) {
            PrfApiModel.prfApi = new PrfApi(undefined, process.env.REACT_APP_PRF_API);
        }
        return PrfApiModel.prfApi;
    }
}
