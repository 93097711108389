import React, {useCallback, useEffect, useState} from "react";
import {Modal, useModal} from "pulse-modal";
import GenericTypeDropdown from "../../../../../Dropdown/GenericTypeDropdown";
import {
    SubSystem,
    SubSystemForStaff
} from "../../../../../../store/notificationsHub/actions/NotificationsHubActionTypes";
import {useUserData} from "../../../../../Hooks/useUserData";
import {StaffAccessLevel} from "../../../../../../api/staff";
import {useHistory} from "react-router-dom";
import {OptionsFromType} from "../../../../../Dropdown/Helpers/dropdownUtils";
import Icon, {IconType} from "../../../../../Icon/Icon";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import Tooltip from "../../../../../Tooltip/Tooltip";
import useMcConfig from "../../../../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../../../../utils/userDataUtils";

/** Component which contains filter to show notifications. Adds ability to filter */
const LiveIncidentTrackerOptions = (props: LiveIncidentTrackerOptionsProps) => {
    const [subsystem, setSubsystem] = useState<string>(props.subSystemQuery);
    const {toggle, isShown} = useModal();
    const user = useUserData();
    const history = useHistory();
    const {config} = useMcConfig();

    useEffect(() => {
        setSubsystem(props.subSystemQuery);
    }, [props]);

    /** Updates selected subsystem filter*/
    const onSubsystemChanged = useCallback(
        (id: string | number) => {
            setSubsystem(id.toString());
        },
        [subsystem]
    );

    /** When the apply button is pressed, filter will be applied and panel will be updated */
    const applyFilter = async () => {
        history.push({search: `?LIT=${subsystem}`});
        toggle();
    };

    return (
        <React.Fragment>
            <Tooltip
                tooltipText={"Live Reporting Tracker Filters"}
                wrapperClassName="cursor-pointer"
                size={"md"}
                place={"left"}
                theme={"dark"}
            >
                <Icon
                    className={"tracker-icon-button"}
                    rootElement={"Span"}
                    icon={IconType.BurgerDots}
                    size={"Medium"}
                    onClick={toggle}
                />
            </Tooltip>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Live Tracker Options"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <div className="col pl-0 pr-0">
                                <p>Filter by module</p>
                            </div>
                        </div>
                        <div className="row ml-0 mr-0" id="modal-dropdown-anchor">
                            {user.username.length &&
                                config.auth &&
                                (getUserAccessLevel(user, config) ===
                                StaffAccessLevel.SystemAdministrator ? (
                                    <GenericTypeDropdown
                                        clearable={false}
                                        getOptionsFrom={OptionsFromType.Value}
                                        searchable={false}
                                        searchWithDecapitalisedFirstLetter={false}
                                        enumOptions={SubSystem}
                                        splitByCapitalLetter={false}
                                        changeOption={onSubsystemChanged}
                                        id={subsystem}
                                        disabled={false}
                                        appendToBody={false}
                                    />
                                ) : (
                                    <GenericTypeDropdown
                                        clearable={false}
                                        getOptionsFrom={OptionsFromType.Value}
                                        searchable={false}
                                        searchWithDecapitalisedFirstLetter={false}
                                        enumOptions={SubSystemForStaff}
                                        splitByCapitalLetter={false}
                                        changeOption={onSubsystemChanged}
                                        id={subsystem}
                                        disabled={false}
                                        appendToBody={false}
                                    />
                                ))}
                        </div>
                        <div className="row ml-0 mr-0 mt-4">
                            <MCButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Apply"}
                                onClick={applyFilter}
                                colour={ButtonColourOptions.Yellow}
                            />
                            <MCButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Cancel"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default LiveIncidentTrackerOptions;

interface LiveIncidentTrackerOptionsProps {
    subSystemQuery: string;
}
