import {useEffect, useRef} from "react";
import {minutesToMilliseconds} from "../../utils/mathUtils";

export function useInterval(callback: any, delay: number) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        const id = setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            savedCallback.current();
        }, minutesToMilliseconds(delay));
        return () => clearInterval(id);
    }, [delay]);
}
