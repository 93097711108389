import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {useUserData} from "../../../../Hooks/useUserData";
import {
    clearNotificationsHubStore,
    fetchNotificationsForUser
} from "../../../../../store/notificationsHub/actions/NotificationsHubActions";
import {useInterval} from "../../../../Hooks/useInterval";
import {WithServiceState} from "store-fetch-wrappers";
import NotificationListItemWrapper from "./Components/NotificationListItemWrapper";
import {useQuery} from "../../../../Hooks/useQuery";
import {
    NotificationItem,
    SubSystem
} from "../../../../../store/notificationsHub/actions/NotificationsHubActionTypes";
import {useHistory} from "react-router-dom";
import SectionContainer from "../../../../Containers/SectionContainer";
import {UserData} from "../../../../../api/staff";
import Icon, {IconType} from "../../../../Icon/Icon";
import LiveIncidentTrackerOptions from "./Components/LiveIncidentTrackerOptions";
import Tooltip from "../../../../Tooltip/Tooltip";
import useMcConfig from "../../../../Hooks/useMcConfig";
import {McConfig} from "../../../../../McConfigPlugin";

const WrappedNotifications = WithServiceState(NotificationListItemWrapper);

function numberForSubsystem(
    items: NotificationItem[] | null | undefined,
    system: SubSystem
): number {
    if (!items) return 0;

    let count = 0;
    for (const item of items) {
        if (item.subSystem === system) {
            count++;
        }
    }

    return count;
}

/** Incident tracker to track all issues within the MC modules */
const LiveIncidentTracker = () => {
    const notificationsHubStore = useSelector((state: RootStore) => state.notificationsHub);
    const user = useUserData();
    const dispatch = useDispatch();
    const query = useQuery();
    const [subSystem, setSubsystem] = useState<string>(SubSystem.ALL);
    const history = useHistory();
    const {config} = useMcConfig();

    /** Refreshes data for notifications every 5 minutes */
    useInterval(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        getLiveIncidents(user, config);
    }, 5); // 5-Minute Delay. Hook has conversion within it.

    useEffect(() => {
        const lit = query.get("LIT");
        setSubsystem(lit ? lit : SubSystem.ALL);
        //Push search parameter to url if upon mounting component
        history.push({search: `?LIT=${lit ? lit : SubSystem.ALL}`});
        return function () {
            dispatch(clearNotificationsHubStore());
        };
    }, []);

    /** Anytime the search parameters change, update the filters in the Live incident tracker. */
    useEffect(() => {
        const lit = query.get("LIT");
        setSubsystem(lit ? lit : SubSystem.ALL);
    }, [window.location.search]);

    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        if (!subSystem) return;

        getLiveIncidents(user, config, subSystem);
    }, [user, subSystem, config]);

    /** Gets the live incidents for the incident tracker */
    const getLiveIncidents = (currentUser: UserData, mcConfig: McConfig, filter?: string) => {
        dispatch(fetchNotificationsForUser(currentUser, mcConfig, filter));
    };
    return (
        <React.Fragment>
            <SectionContainer>
                <div className="row ml-0 mr-0">
                    <div className="live-tracker-header-item mr-auto">
                        <h6>
                            Incidents pending review:{" "}
                            <span>
                                {numberForSubsystem(notificationsHubStore.data, SubSystem.IM)}
                            </span>
                            <br />
                            Medicines:{" "}
                            <span>
                                {numberForSubsystem(notificationsHubStore.data, SubSystem.MM)}
                            </span>
                        </h6>
                    </div>
                    <div className="live-tracker-header-item">
                        <Tooltip
                            tooltipText={"Refresh Live Reporting Tracker"}
                            wrapperClassName="cursor-pointer"
                            size={"lg"}
                            place={"left"}
                            theme={"dark"}
                        >
                            <Icon
                                className="mr-3 tracker-icon-button"
                                rootElement={"Span"}
                                icon={IconType.Refresh_Square}
                                size={"Medium"}
                                onClick={() => getLiveIncidents(user, config, subSystem)}
                            />
                        </Tooltip>
                        <LiveIncidentTrackerOptions subSystemQuery={subSystem} />
                    </div>
                </div>
                <WrappedNotifications
                    {...notificationsHubStore}
                    showLoadingText={true}
                    loaderType={"normal"}
                    placeholderBlockCount={1}
                    loaderWheelType={"three-ring"}
                />
            </SectionContainer>
        </React.Fragment>
    );
};

export default LiveIncidentTracker;
