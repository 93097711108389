import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {TotpTokenAssociate} from "../../../api/staff";
export const TOTP_STORE_STATE = createStoreState("totp_store");

export interface TotpLoading extends StoreServiceData<TotpWithQrCode> {
    type: typeof TOTP_STORE_STATE.LOADING;
}

export interface TotpError extends StoreServiceData<TotpWithQrCode> {
    type: typeof TOTP_STORE_STATE.ERROR;
}

export interface TotpSuccess extends StoreServiceData<TotpWithQrCode> {
    type: typeof TOTP_STORE_STATE.SUCCESS;
}

export type TotpDispatchTypes = TotpSuccess | TotpError | TotpLoading;

export interface TotpWithQrCode extends TotpTokenAssociate {
    base64QrCode: string;
}
