import {DocumentViewRecordWithDocument} from "../../../api/ppb";
import {NotificationItem, SubSystem} from "../actions/NotificationsHubActionTypes";
import {formatUnixToDDMMYYYY} from "../../../utils/momentUtils";
import React from "react";
import Icon, {IconType} from "../../../components/Icon/Icon";

/** Sorts the raw data from PPB to a notification item */
export function convertPPBDocumentsToNotificationItems(payload: DocumentViewRecordWithDocument[]) {
    const notificationList: NotificationItem[] = [];

    for (const doc of payload) {
        if (doc.date) {
            //Document has been updated. (Orange)
            if (doc.document.date >= doc.date) {
                notificationList.push({
                    subSystem: SubSystem.PPB,
                    icon: getPpbNotificationItemIcon(),
                    id: doc.document.id,
                    title: "My Documents - Updated Document",
                    actionText: getPpbActionText(doc),
                    link: getPpbLinkToNewDocument(doc)
                });
            }
        }
    }

    return notificationList;
}

/** Gets link to prf audit */
function getPpbLinkToNewDocument(doc: DocumentViewRecordWithDocument): string {
    return `${process.env.REACT_APP_PPB}documents/user-documents/category/${doc.document.categoryId}`;
}

function getPpbActionText(doc: DocumentViewRecordWithDocument): JSX.Element {
    const docDate = formatUnixToDDMMYYYY(doc.document.date);
    return (
        <p className="notification-item-message">
            <b className="notification-bold-text">{doc.document.name}</b> was updated on{" "}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            the <b className="notification-bold-text">{docDate}</b> Click the "View" button to view
            the updated document.
        </p>
    );
}

export function getPpbNotificationItemIcon(): JSX.Element {
    return (
        <React.Fragment>
            <Icon
                icon={IconType.Documents}
                size={"Large"}
                rootElement={"Span"}
                className="dark-blue-icon"
            />
        </React.Fragment>
    );
}
