import {CategoryViewStat} from "../../../../api/ppb";
import {
    getAngleForHalfPieChartFromPercentage,
    getFlooredPercentage
} from "../../../../utils/mathUtils";
import {getOverallCompliancePercentage, StatisticsItem} from "../../helpers/statisticsHelpers";

/** Generates a list of stats objects from a list of venue audit stats. */

/** Generate the overall compliance of the system */
export function generatePpbCategoryViewStats(viewStats: CategoryViewStat[]): StatisticsItem {
    if (viewStats.length === 0) {
        return defaultPolicySuiteStatsState;
    }
    const ppbStatsItem = getAllViewStats(viewStats);

    return getStatisticsItemFromPrfStats(ppbStatsItem);
}

function getAllViewStats(viewStats: CategoryViewStat[]): PpbStatsItem {
    const ppbStatItem = getBlankPpbStatsItem();
    for (const viewStat of viewStats) {
        ppbStatItem.unreadViews += viewStat.possibleViews - (viewStat.oldViews + viewStat.views);
        ppbStatItem.readViews += viewStat.views;
        ppbStatItem.updatedViews += viewStat.oldViews;
        ppbStatItem.possibleViews += viewStat.possibleViews;
    }

    return ppbStatItem;
}

export function getStatisticsItemFromPrfStats(stats: PpbStatsItem): StatisticsItem {
    const compliancePercentage = getOverallCompliancePercentFromPpbStats(stats);
    return {
        totalValue: stats.possibleViews,
        tooltipText: getPpbStatsTooltipText(compliancePercentage),
        needleAngle: getAngleForHalfPieChartFromPercentage(compliancePercentage),
        outerLabel: "Policy suite",
        compliancePercentage
    };
}

function getOverallCompliancePercentFromPpbStats({
    updatedViews,
    possibleViews,
    unreadViews
}: PpbStatsItem): number {
    const amberPercent = getFlooredPercentage(updatedViews, possibleViews);
    const redPercent = getFlooredPercentage(unreadViews, possibleViews);
    return getOverallCompliancePercentage(amberPercent, redPercent);
}

function getPpbStatsTooltipText(percentage: number): string {
    return `Overall Policy Suite Compliance: ${percentage}%`;
}

function getBlankPpbStatsItem(): PpbStatsItem {
    return {
        readViews: 0,
        updatedViews: 0,
        unreadViews: 0,
        possibleViews: 0
    };
}

interface PpbStatsItem {
    readViews: number;
    updatedViews: number;
    unreadViews: number;
    possibleViews: number;
}

const defaultPolicySuiteStatsState = {
    needleAngle: 0,
    totalValue: 0,
    outerLabel: "Policy suite",
    compliancePercentage: 0,
    tooltipText: "Overall Policy Suite Compliance: 0%"
};
