import {StoreServiceData} from "store-fetch-wrappers";
import {StatisticsItem} from "../../../helpers/statisticsHelpers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const DRUG_BATCH_LIST_STATS_STORE = createStoreState("drug_batch_list_stats");

interface DrugBatchListStatsLoading extends StoreServiceData<StatisticsItem> {
    type: typeof DRUG_BATCH_LIST_STATS_STORE.LOADING;
}
interface DrugBatchListStatsError extends StoreServiceData<StatisticsItem> {
    type: typeof DRUG_BATCH_LIST_STATS_STORE.ERROR;
}
interface DrugBatchListStatsSuccess extends StoreServiceData<StatisticsItem> {
    type: typeof DRUG_BATCH_LIST_STATS_STORE.SUCCESS;
}

export type DrugBatchListDispatchTypes =
    | DrugBatchListStatsLoading
    | DrugBatchListStatsError
    | DrugBatchListStatsSuccess;
