import React from "react";
import Card from "../Cards/Card";
import Error from "../Icon/Error";

function TotpError({error}: {error: string}) {
    return (
        <div className="totp-container justify-content-center fadeIn">
            <Card className="totp-error-card-container">
                <div className="totp-success-container">
                    <div className="totp-error">
                        <div className="dot error bottom-left" />
                        <div className="dot error top-right" />
                        <Error />
                    </div>
                </div>

                <div className="totp-error-card-text-container">
                    <p className="mt-4 text-center">
                        <b className="header-font totp-qr-code-cta-text">ERROR</b>
                    </p>
                    <p className="mb-4 text-center">{error}</p>
                </div>
            </Card>
        </div>
    );
}

export default TotpError;
