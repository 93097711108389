export const routeNames = {
    dashboard: {
        path: "/dashboard",
        name: "Dashboard"
    },
    wageCalculation: {
        path: "/wage-calculation",
        name: "Wage Calculation"
    },
    totp: {
        path: "/totp",
        name: "TOTP"
    },
    //Auth pages
    login: {
        path: "/login",
        name: "",
        icon: ""
    },
    loggedin: {
        path: "/loggedin",
        name: "",
        icon: ""
    },
    logout: {
        path: "/logout",
        name: "",
        icon: ""
    },
    inActive: {
        path: "/inactive",
        name: "",
        icon: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: "",
        icon: ""
    },
    notFound: {
        path: "/not-found",
        name: "",
        icon: ""
    }
};
