import {Dispatch} from "redux";
import {TOTP_STORE_STATE, TotpDispatchTypes} from "./TotpActionTypes";
import {getDataFromServiceWithData, getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {TotpTokenAssociate, TotpTokenVerify, TotpVerifyResponse} from "../../../api/staff";
import {showErrorToast} from "../../../utils/toastUtils";
import QRCode from "qrcode";

export const getTotp = () => {
    return async (dispatch: Dispatch<TotpDispatchTypes>) => {
        try {
            const data = await getDataFromServiceWithData(
                TOTP_STORE_STATE,
                dispatch,
                () => StaffApiModel.getUsersApi().associateTotp(),
                statusCodeCallback
            );

            if (!data) return;

            //Generate the QR Code image here instead of the component.
            const base64QrCode = await QRCode.toDataURL(data.qrCode, {
                color: {light: "#ffffff00", dark: "#1a2a35ff"},
                scale: 10
            });

            dispatch({
                type: TOTP_STORE_STATE.SUCCESS,
                error: null,
                loading: false,
                data: {
                    ...data,
                    base64QrCode
                }
            });
        } catch (e: any) {
            dispatch({
                type: TOTP_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const postTotp = (entity: TotpTokenAssociate) => {
    return async (dispatch: Dispatch<TotpDispatchTypes>) => {
        try {
            await getDataFromServiceWithRedux(
                TOTP_STORE_STATE,
                dispatch,
                () => StaffApiModel.getUsersApi().totpQrImage(entity),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: TOTP_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export async function verifyTotpConnection(
    entity: TotpTokenVerify
): Promise<TotpVerifyResponse | undefined | null> {
    try {
        const response = await StaffApiModel.getUsersApi().verifyTotp(entity);

        if (response.status === 200 && response.data) {
            return response.data;
        }

        return null;
    } catch (e: any) {
        showErrorToast(e);
        showErrorToast("Could not verify TOTP. Please try again with a different code");
    }
}
