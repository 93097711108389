import {AuditListResponse, AuditListResult} from "../../../api/prf";
import {NotificationItem, SubSystem} from "../actions/NotificationsHubActionTypes";
import React from "react";
import {getUiFriendlyText} from "../../../utils/textUtils";
import {formatUnixToDDMMYYYY} from "../../../utils/momentUtils";
import Icon, {IconType} from "../../../components/Icon/Icon";

/** Converts the audit list to notification list */
export function convertAuditListToNotificationItemList(
    payload: AuditListResponse
): NotificationItem[] {
    const notificationList: NotificationItem[] = [];
    for (const audit of payload.results) {
        notificationList.push({
            subSystem: SubSystem.PRF,
            icon: getPrfNotificationItemIcon(),
            id: audit.auditId,
            title: `PRF - Completed Audit`,
            date: audit.creationDate,
            actionText: getPrfActionText(audit),
            link: getPrfLinkToAudit(audit)
        });
    }
    return notificationList;
}

/** Gets link to prf audit */
function getPrfLinkToAudit(audit: AuditListResult): string {
    return `${process.env.REACT_APP_PRF}audits/review/${audit.auditId}`;
}

/** Gets action text for PRF */
function getPrfActionText(audit: AuditListResult): JSX.Element {
    return (
        <React.Fragment>
            <p className="notification-item-message">
                <span>A </span>
                <b className="notification-bold-text">{getUiFriendlyText(audit.auditType)}</b>
                <span> audit was completed on </span>
                <b className="notification-bold-text">
                    {formatUnixToDDMMYYYY(audit.creationDate)}.
                </b>{" "}
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Click the "View" button to review the audit.
            </p>
        </React.Fragment>
    );
}

//get Prf Icon
function getPrfNotificationItemIcon(): JSX.Element {
    return (
        <React.Fragment>
            <Icon
                icon={IconType.Checklist}
                size={"Large"}
                rootElement={"Span"}
                className="dark-blue-icon"
            />
        </React.Fragment>
    );
}
